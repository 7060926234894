import * as BABYLON from '@babylonjs/core';

export const loadScene = async (sceneUrl: string, engine: BABYLON.Engine, onSceneReady) => {
  const scene = await BABYLON.SceneLoader.LoadAsync(sceneUrl, '', engine, undefined, '.glb');
  if (scene.isReady()) {
    onSceneReady(scene);
  } else {
    scene.onReadyObservable.addOnce(() => onSceneReady(scene));
  }

  return scene;
};
