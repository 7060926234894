import { VALIDATION_RULES } from 'lib/utils';
import { Form, FORM_FIELD_INPUT_TYPES } from 'mod-styleguide';
import React from 'react';
import { validateObject } from '@codeparticle/formal';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'lib/types/redux';
import { translate } from 'lib/utils/intl-utils';
import './sign-up-form.scss';
import { signUpFormState } from 'redux/modules/forms';
import { onAddressSelected, onSignUpFormChange, setSignUpFormValidation } from 'redux/modules/forms/signUp/signUpSlice';
import { signUp } from 'redux/thunks/auth';
import { FORM_ROW_HALF_WIDTH } from 'lib/constants';
import { StartModdingCta } from 'components/start-modding-cta';

const validateForm = validateObject({
  address: [VALIDATION_RULES.REQUIRED_STRING],
  passwordConfirmation: [VALIDATION_RULES.PASSWORD],
  email: [VALIDATION_RULES.EMAIL],
  firstName: [VALIDATION_RULES.REQUIRED_STRING],
  lastName: [VALIDATION_RULES.REQUIRED_STRING],
  password: [VALIDATION_RULES.PASSWORD],
  phone: [VALIDATION_RULES.REQUIRED_STRING],
});

interface SignUpFormProps {
}


export const SignUpForm: React.FC<SignUpFormProps> = () => {
  const dispatch = useAppDispatch();
  const formState = useSelector(signUpFormState);

  const onInputChange = (e) => {
    dispatch(onSignUpFormChange(e));
  };

  const onAddressSelect = (address) => {
    dispatch(onAddressSelected(address));
  };

  return (
    <div className="sign-up-form-rct-component">
      <Form
        submitLabel={translate('common.sign-up')}
        formState={formState}
        autoComplete="on"
        onSubmit={() => dispatch(signUp(formState))}
        validateForm={validateForm}
        setFormValidation={(v) => dispatch(setSignUpFormValidation(v))}
        onInputChange={onInputChange}
        formFieldDefs={[
          {
            name: 'firstName',
            autoComplete: 'given-name',
            label: translate('common.first-name'),
            placeholder: translate('form.first-name-placeholder'),
            width: FORM_ROW_HALF_WIDTH,
          },
          {
            name: 'lastName',
            autoComplete: 'family-name',
            label: translate('common.last-name'),
            placeholder: translate('form.last-name-placeholder'),
            width: FORM_ROW_HALF_WIDTH,
          },
          {
            name: 'email',
            autoComplete: 'email',
            label: translate('common.email-address'),
            placeholder: translate('form.email-placeholder'),
            width: FORM_ROW_HALF_WIDTH,
          },
          {
            name: 'phone',
            autoComplete: 'tel',
            label: translate('common.phone-number'),
            placeholder: translate('form.phone-number-placeholder'),
            width: FORM_ROW_HALF_WIDTH,
          },
          {
            name: 'address',
            label: translate('common.address'),
            placeholder: translate('form.address-placeholder'),
            inputType: FORM_FIELD_INPUT_TYPES.ADDRESS,
            onSelect: onAddressSelect,
          },
          {
            name: 'password',
            autoComplete: 'new-password',
            label: translate('common.password'),
            placeholder: translate('form.password-placeholder'),
          },
          {
            name: 'passwordConfirmation',
            type: 'password',
            autoComplete: 'new-password',
            label: translate('common.confirm-password'),
            placeholder: translate('form.password-placeholder'),
          },
        ]}
      />
      <StartModdingCta inline />
    </div>
  );
};

