import * as BABYLON from '@babylonjs/core';

export interface OutdoorLights {
  envDirectionalLight: BABYLON.DirectionalLight;
  carDirectionalLight: BABYLON.DirectionalLight;
  hemisphericLight: BABYLON.HemisphericLight;
}

export const setupOutdoorLights = (scene: BABYLON.Scene): OutdoorLights => {
  const position = new BABYLON.Vector3(0.08, 2, 0.08);
  const diffuseColor = new BABYLON.Color3(1, 0.96, 0.76);
  const specularColor = new BABYLON.Color3(1, 1, 1);
  const carDirectionalLightDirection = new BABYLON.Vector3(-0.77, -0.58, -0.27);
  const envDirectionalLightDirection = new BABYLON.Vector3(-1.3, -0.83, -1.070);
  const hemiLightDirection = new BABYLON.Vector3(0.77, 0.58, 0.27);

  const carDirectionalLight = new BABYLON.DirectionalLight('carDirectionalLight', carDirectionalLightDirection, scene);
  carDirectionalLight.intensity = 5;
  carDirectionalLight.radius = 0.57;
  carDirectionalLight.position = position;
  carDirectionalLight.diffuse = diffuseColor;
  carDirectionalLight.specular = specularColor;
  carDirectionalLight.range = 0;

  const envDirectionalLight = new BABYLON.DirectionalLight('envDirectionalLight', envDirectionalLightDirection, scene);
  envDirectionalLight.intensity = 10;
  envDirectionalLight.radius = 0.5;
  envDirectionalLight.position = position;
  envDirectionalLight.diffuse = diffuseColor;
  envDirectionalLight.specular = specularColor;

  const hemisphericLight = new BABYLON.HemisphericLight('HemiLight', hemiLightDirection, scene);
  hemisphericLight.intensity = 1;
  hemisphericLight.diffuse = diffuseColor;
  hemisphericLight.specular = specularColor;

  scene.meshes.forEach(mesh => {
    if (!mesh.id.includes('ground')) {
      // Only the ground and vehicle meshes should be lit by the carDirectionalLight
      // Exclude all other meshes from the carDirectionalLight
      // (vehicle hasn't been added yet at this point so no need to worry about it getting excluded)
      carDirectionalLight.excludedMeshes.push(mesh);
    }

    if (mesh.id.toLowerCase().includes('leaves')) {
      // Make the leaves translucent
      const meshMaterial = mesh.material as BABYLON.PBRMaterial;
      meshMaterial.separateCullingPass = true;
    }
  });

  return {
    envDirectionalLight,
    carDirectionalLight,
    hemisphericLight,
  };
};
