import { createSlice } from '@reduxjs/toolkit';
import { APP_DOMAINS } from 'lib/constants';
import { USER_ROLES } from 'lib/types';
import { getAppSubdomain } from 'lib/utils';

const getUserRoleFromAppSubdomain = (appSubdomain) => {
  switch (appSubdomain) {
    case APP_DOMAINS.APP:
      return USER_ROLES.CUSTOMER;
    case APP_DOMAINS.DEALER:
      return USER_ROLES.DEALER;
    case APP_DOMAINS.INSTALLER:
      return USER_ROLES.INSTALLER;
    case APP_DOMAINS.VIEWER:
      return USER_ROLES.VIEWER;
    default:
      return null;
  }
};

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    subdomain: '',
    userRole: '',
    initialized: false,
    viewerLink: '',
  },
  reducers: {
    initializeApp: (state) => {
      state.subdomain = getAppSubdomain();
      state.userRole = getUserRoleFromAppSubdomain(state.subdomain);
      state.viewerLink = window.location.origin.replace(state.subdomain, APP_DOMAINS.VIEWER);
      state.initialized = true;
    },
  },
});

export const { initializeApp } = appSlice.actions;
export const selectAppState = state => state.app;
export const selectAppSubdomain = state => state.app.subdomain;
export const selectAppUserRole = state => state.app.userRole;
export const selectViewerLink = state => state.app.viewerLink;
export default appSlice.reducer;
