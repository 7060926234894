interface NameData {
  firstName?: string
  lastName?: string
  name?: string
}

export const mountName = (data: NameData) => {
  if (!data) {
    return '';
  }
  const { firstName, lastName, name } = data;
  const fullName = name || `${firstName} ${lastName}`;
  return fullName;
};
