import { translate } from 'lib/utils/intl-utils';
import { Button, Form, Modal, SvgSuccess, SvgClose } from 'mod-styleguide';
import React, { useCallback, useEffect } from 'react';
import { selectConfiguratorVehicle } from 'redux/modules/carConfigurator/carConfiguratorSlice';
import { useAppDispatch, useAppSelector } from 'lib/types/redux';
import { vehiclePickerFormState } from 'redux/modules/forms';
import { onVehiclePickerFormChange, updateSelectedVehicleOptions } from 'redux/modules/forms/vehiclePickerSlice/vehiclePickerSlice';
import { getFormFieldDefs } from './config';
import { useVehiclePickerData } from './hooks';
import './vehicle-picker-modal.scss';

interface VehiclePickerModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  disableAutoInit?: boolean;
  disableUnsupportedBodyParts?: boolean;
}

export const VehiclePickerModal: React.FC<VehiclePickerModalProps> = ({
  isOpen,
  onClose,
  disableAutoInit = false,
  disableUnsupportedBodyParts = false,
}) => {
  const formState = useAppSelector(vehiclePickerFormState);
  const pickerData = useVehiclePickerData(formState, disableAutoInit, disableUnsupportedBodyParts);
  const dispatch = useAppDispatch();

  const onInputChange = useCallback((e) => {
    dispatch(onVehiclePickerFormChange({
      field: e.field,
      value: e.value,
      rawValue: e.rawValue,
      availableTrims: pickerData.trims,
      availableColors: pickerData.colors,
    }));
  }, [dispatch, pickerData]);

  useEffect(() => {
    if (isOpen) {
      dispatch(updateSelectedVehicleOptions({
        trims: pickerData.trims,
        colors: pickerData.colors,
      }));
    }
  }, [isOpen, dispatch, pickerData.trims, pickerData.colors]);


  const onSubmit = useCallback(() => {
    dispatch(selectConfiguratorVehicle({
      vehicleModel: pickerData.selectedVehicleModel,
      selectedOptions: formState,
    }));
    onClose?.();
  }, [dispatch, pickerData, formState, onClose]);

  const customFormButtons = (
    <div className='form-buttons-container'>
      <Button
        label={translate('car-configurator.configure-build')}
        primary
        customIcon={<SvgSuccess />}
        onClick={onSubmit}
      />
      <Button
        label={translate('common.cancel')}
        customIcon={<SvgClose />}
        onClick={onClose}
      />
    </div>
  );

  return (
    <div className="vehicle-picker-modal-rct-component">
      <Modal
        id='vehicle-picker-modal'
        isOpen={isOpen}
        onClose={onClose}
        title={translate('car-configurator.choose-your-vehicle')}
      >
        <Form
          formState={formState}
          submitLabel={translate('car-configurator.configure-build')}
          onInputChange={onInputChange}
          formFieldDefs={getFormFieldDefs(pickerData)}
          customButtonElement={customFormButtons}
        />
      </Modal>
    </div>
  );
};

