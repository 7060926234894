import { APP_DOMAINS } from 'lib/constants';

const APP_SUBDOMAINS = Object.values(APP_DOMAINS);

export const getAppSubdomain = () => {
  const { hostname } = window.location;
  const appSubdomain = APP_SUBDOMAINS.find((subdomain) => hostname.includes(subdomain));

  return appSubdomain;
};
