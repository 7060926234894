import { Avatar, TabNavigator } from 'mod-styleguide';
import { translate } from 'lib/utils/intl-utils';
import React from 'react';
import { selectUserProfile } from 'redux/modules/auth/authSlice';
import { useAppSelector } from 'lib/types/redux';
import { UserInfo } from './user-info';

import './user-profile.scss';

interface UserProfileProps {}

export const UserProfile: React.FC<UserProfileProps> = () => {
  const profileData = useAppSelector(selectUserProfile);

  return (
    <div className="user-profile-rct-component">
      <div className='avatar-section'>
        <h1>{translate('common.profile')}</h1>
        <Avatar profileData={profileData} />
      </div>
      <TabNavigator
        tabs={[
          {
            id: 'tab1',
            label: translate('common.overview'),
            element: <UserInfo />,
          },
        ]}
      />
    </div>
  );
};

