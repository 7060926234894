import { UserProfile } from 'mod-styleguide';
import React from 'react';

import './avatar.scss';

interface AvatarProps {
  profileData?: Partial<UserProfile>;
}

export const Avatar: React.FC<AvatarProps> = ({
  profileData,
}) => {
  const userNameInitial = profileData?.name?.charAt(0) ?? profileData?.firstName?.charAt(0);
  return (
    <div className="avatar-rct-component">
      <div className='avatar-rct-component__initials'>
        {userNameInitial}
      </div>
      <div className='avatar-rct-component__circle' />
      <div className='avatar-rct-component__circle-blur' />
    </div>
  );
};

