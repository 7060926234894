import classNames from 'classnames';
import React, { useState } from 'react';
import { Visible } from '@codeparticle/react-visible';
import {
  MESSAGE_TYPES,
  MESSAGE_STATUSES,
  FlashMessageItem,
  SvgClose,
  SvgSuccess,
} from 'mod-styleguide';
import './flash-message.scss';

interface FlashMessageProps {
  item: FlashMessageItem
  onClose?: (item) => void;
}
type AnimationStatus = 'hovering' | 'opened' | 'opening' | 'close-clicked' | 'close-immediate';

export const FlashMessage: React.FC<FlashMessageProps> = ({
  item,
  onClose,
}) => {
  const {
    message,
    type,
  } = item || {};
  const [status, setStatus] = useState<AnimationStatus>('opening');

  const className = classNames(
    'flash-message-rct-component',
    status === MESSAGE_STATUSES.OPENING && 'open-anim',
    status === MESSAGE_STATUSES.HOVERING && 'stay-open',
    status === MESSAGE_STATUSES.OPENED && 'close-after-wait',
    status === MESSAGE_STATUSES.CLOSE_CLICKED && 'close-immediate',
    type === MESSAGE_TYPES.ERROR && 'error',
    type === MESSAGE_TYPES.SUCCESS && 'success',
  );

  const onAnimationEnd = (animName) => {
    if (animName === 'close') onClose(item);
    else if (animName === 'open') {
      if (status === MESSAGE_STATUSES.HOVERING) setStatus('hovering');
      else setStatus('opened');
    }
  };

  return (
    <div
      className={className}
      onMouseEnter={() => setStatus('hovering')}
      onMouseLeave={() => setStatus('opened')}
      role="presentation"
      onAnimationEnd={({ nativeEvent }) => onAnimationEnd(nativeEvent.animationName)}
    >
      <div className='indicator' />
      <Visible when={type === MESSAGE_TYPES.SUCCESS}>
        <SvgSuccess color="white" />
      </Visible>
      <span>{message}</span>
      <SvgClose color="white" onClick={() => setStatus('close-clicked')} />
    </div>
  );
};

