import { createSlice } from '@reduxjs/toolkit';
import { setDefaultOptions } from 'date-fns';
import localeData from 'translations/locales';

const DEFAULT_LANG = 'en';
const DEFAULT_MESSAGES = localeData[DEFAULT_LANG];

export const localeSlice = createSlice({
  name: 'locale',
  initialState: {
    lang: DEFAULT_LANG,
    messages: DEFAULT_MESSAGES,
  },
  reducers: {
    setLocale: (state, action) => {
      const lang = localeData[action.payload] ? action.payload : DEFAULT_LANG;
      const mergedMessages = { ...DEFAULT_MESSAGES, ...localeData[action.payload] || {} };
      setDefaultOptions({
        locale: action.payload,
      });

      state.lang = lang;
      state.messages = mergedMessages;
    },
  },
});

export const { setLocale } = localeSlice.actions;
export const selectLang = state => state.locale.lang;
export const selectMessages = state => state.locale.messages;
export default localeSlice.reducer;
