import * as BABYLON from '@babylonjs/core';

export const setupOutdoorPostProcessing = (defaultPipeline: BABYLON.DefaultRenderingPipeline) => {
  defaultPipeline.bloomKernel = 20;
  defaultPipeline.bloomScale = 0.5;
  defaultPipeline.bloomThreshold = 0.04;

  defaultPipeline.sharpen.edgeAmount = 0.25;

  defaultPipeline.depthOfField.focalLength = 40;
  defaultPipeline.depthOfField.fStop = 3;

  defaultPipeline.imageProcessing.contrast = 1.2;
  defaultPipeline.imageProcessing.exposure = 0.8;

  return defaultPipeline;
};

const setupIndoorPostProcessing = (defaultPipeline: BABYLON.DefaultRenderingPipeline) => {
  defaultPipeline.bloomKernel = 60;
  defaultPipeline.bloomScale = 0.25;

  defaultPipeline.depthOfField.focalLength = 50;

  defaultPipeline.imageProcessing.contrast = 0.9;
  defaultPipeline.imageProcessing.exposure = 1.2;


  return defaultPipeline;
};

export const setupPostProcessing = (scene: BABYLON.Scene, isOutdoorScene?: boolean) => {
  const defaultPipeline = new BABYLON.DefaultRenderingPipeline(
    'defaultPipeline',
    true,
    scene,
    [scene.activeCamera],
  );
  defaultPipeline.fxaaEnabled = true;
  defaultPipeline.samples = 5;

  defaultPipeline.bloomEnabled = true;
  defaultPipeline.bloomWeight = 0.1;
  defaultPipeline.bloomThreshold = 2;

  defaultPipeline.depthOfFieldEnabled = true;
  defaultPipeline.depthOfField.fStop = 10;
  defaultPipeline.depthOfField.focusDistance = 1000;
  defaultPipeline.depthOfField.lensSize = 40;

  defaultPipeline.sharpenEnabled = true;
  defaultPipeline.sharpen.edgeAmount = 0.2;
  defaultPipeline.sharpen.colorAmount = 1;

  defaultPipeline.grainEnabled = true;
  defaultPipeline.grain.intensity = 10;

  defaultPipeline.imageProcessing.toneMappingEnabled = true;
  defaultPipeline.imageProcessing.ditheringEnabled = true;
  defaultPipeline.imageProcessing.ditheringIntensity = 0.02;
  defaultPipeline.imageProcessing.toneMappingType = BABYLON.ImageProcessingConfiguration.TONEMAPPING_ACES;

  if (isOutdoorScene) {
    setupOutdoorPostProcessing(defaultPipeline);
  } else {
    setupIndoorPostProcessing(defaultPipeline);
  }

  return [
    defaultPipeline,
  ].filter(Boolean);
};
