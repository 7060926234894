import { SelectInputOptionType, mapSelectInputOptionFromSingleValue } from 'mod-styleguide';

export type BaseStrapiArrayResponse = {
  data: [
    attributes: any,
  ];
};

export const mapStrapiArrayResponseToOptionsFromSingleValueKey = (
  response: BaseStrapiArrayResponse,
  valueKey: string,
): SelectInputOptionType[] => {
  const uniqueValues = [...new Set(response.data.map(({ attributes }) => attributes[valueKey]))];
  const options = uniqueValues.map(mapSelectInputOptionFromSingleValue);
  return options;
};
