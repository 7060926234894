import { createSlice } from '@reduxjs/toolkit';
import { resetPassword } from 'redux/thunks/auth';

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState: {
    code: '',
    password: '',
    passwordConfirmation: '',
    touched: false,
    loading: false,
    error: '',
    validation: null,
  },
  reducers: {
    onResetPasswordFormChange: (state, action) => {
      state.touched = true;
      state[action.payload.field] = action.payload.value;
    },
    setResetPasswordFormValidation: (state, action) => {
      state.validation = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetPassword.pending, (state) => {
      state.error = null;
    });

    builder.addCase(resetPassword.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as any;
    });
  },
});

export const { onResetPasswordFormChange, setResetPasswordFormValidation } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
