import React from 'react';
import { ModLoaderAnimation } from 'assets';
import Lottie from 'lottie-react';
import './mod-loader.scss';

export const ModLoader = () => {
  const options = {
    animationData: ModLoaderAnimation,
  };
  return (
    <div id="mod-loader">
      <Lottie {...options} />
    </div>
  );
};
