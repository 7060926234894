/**
 * This is another way for us to initialize react-intl
 * the reason we do it this way is that this is universal
 * This object can be used in tests
 */
import {
  createIntl, createIntlCache,
} from 'react-intl';
import locales from 'translations/locales';

const cache = createIntlCache();

const intl = createIntl({
  defaultLocale: 'en',
  locale: navigator.language,
  messages: (locales as Record<string, Record<string, string>>)[navigator.language] || locales.en,
}, cache);

export {
  intl,
};
