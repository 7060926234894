import classNames from 'classnames';
import React, { useEffect } from 'react';
import type { SelectInputOptionType, ModConfig } from 'mod-styleguide';
import { SelectMenu } from 'mod-styleguide';
import { Popover } from 'react-tiny-popover';
import { useBoolean } from 'ahooks';

interface Props {
  texture: SelectInputOptionType
  isSelected?: boolean;
  onClick?: (texture: SelectInputOptionType) => void;
  bodyParts?: SelectInputOptionType[];
  headerTitle: string;
  activeModConfigs: Array<ModConfig>,
  onApplyTexture?: (texture: SelectInputOptionType, selectedBodyParts: SelectInputOptionType[]) => void;
  selectAllLabel?: string;
}

export const TextureOption: React.FC<Props> = ({
  texture,
  isSelected,
  onClick,
  bodyParts,
  headerTitle,
  onApplyTexture,
  activeModConfigs,
  selectAllLabel,
}) => {
  const [isPopoverOpen, {
    setTrue: openPopoverMenu,
    setFalse: closePopoverMenu,
  }] = useBoolean();
  const [wrappedBodyParts, setWrappedBodyParts] = React.useState<SelectInputOptionType[]>([]);
  const hasBodyPartsMenu = bodyParts?.length > 0;

  useEffect(() => {
    // get current texture active wraps from active mods
    const textureActiveWraps = activeModConfigs?.filter((modConfig) => modConfig.mod === texture.id);

    if (!textureActiveWraps || !bodyParts) {
      return;
    }

    const isBodyPartSelected = (textureMod) => (bodyPart) => textureMod.bodyPart === bodyPart.id;
    const getSelectedBodyPartFromTextureMod = (textureActiveWrap) => bodyParts.find(isBodyPartSelected(textureActiveWrap));

    const activeBodyParts = textureActiveWraps.map(getSelectedBodyPartFromTextureMod);
    setWrappedBodyParts(activeBodyParts);
  }, [activeModConfigs, bodyParts, texture.id]);

  const onWrapBodyPartSelection = (selectedBodyPart: any) => {
    const selectedBodyPartsArray = Array.isArray(selectedBodyPart) ? selectedBodyPart : [selectedBodyPart];
    onApplyTexture(texture, selectedBodyPartsArray);
  };

  const selectMenuHeader = (
    <div className="select-menu-rct-component__header__title">
      {headerTitle}
    </div>
  );

  const textureElement = (
    <div
      className={
          classNames(
            'wrap-picker-rct-component__texture-picker__texture',
            isSelected && 'wrap-picker-rct-component__texture-picker__texture--selected',
          )
        }
      onClick={() => {
        onClick(texture);
        if (!hasBodyPartsMenu) {
          // if texture option has no body parts to choose from,
          // apply texture with empty array so it can use externally selected body parts
          onApplyTexture(texture, []);
          return;
        }
        openPopoverMenu();
      }}
    >
      <div className='texture-selection-border' />
      <img
        src={texture.value}
        alt={texture.label}
      />
    </div>
  );

  return hasBodyPartsMenu ? (
    <Popover
      content={
        <SelectMenu
          options={bodyParts}
          onChange={onWrapBodyPartSelection}
          header={selectMenuHeader}
          isMulti
          selectedOptions={wrappedBodyParts}
          selectAllLabel={selectAllLabel}
        />
      }
      isOpen={isPopoverOpen}
      onClickOutside={closePopoverMenu}
      positions={['bottom', 'top']}
      containerClassName='texture-option-rct-component__popover'
    >
      {textureElement}
    </Popover>
  ) : textureElement;
};
