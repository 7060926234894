import React from 'react';
import { Tab, AdditionalTabRoute } from 'mod-styleguide';
import { Tabs, TabRoutes } from './components';
import './tab-navigator.scss';

interface Props {
  tabs: Tab[],
  defaultTabId?: string | number,
  basePath?: string,
  // use `additionalRoutes` props to add additional routes that should not generate new tabs
  // that is useful for routes that are not part of the tab navigator but should be rendered, like sub routes of a given tab route
  additionalRoutes?: Array<AdditionalTabRoute>,
  // This will hide the tabs (not the content) when the route does not match any of the defined tabs
  // useful for the additionalRoutes case
  hideTabsWhenNoMatch?: boolean,
}

const getDefaultTab = (tabs, defaultTabId) => {
  const defaultTab = tabs.find(tab => tab.id === defaultTabId);
  const urlTab = tabs.find(tab => window.location.pathname.includes(tab.path));

  return urlTab || defaultTab || tabs[0];
};

const TabNavigator:React.FC<Props> = ({
  tabs,
  defaultTabId,
  basePath,
  additionalRoutes,
  hideTabsWhenNoMatch,
}) => {
  const defaultTab = getDefaultTab(tabs, defaultTabId);

  return (
    <div className="tab-navigator-rct-component">
      <Tabs
        tabs={tabs}
        defaultTab={defaultTab}
        basePath={basePath}
        hideTabsWhenNoMatch={hideTabsWhenNoMatch}
      />
      <TabRoutes
        tabs={tabs}
        defaultTab={defaultTab}
        additionalRoutes={additionalRoutes}
      />
    </div>
  );
};

export { TabNavigator };
