import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { type SelectInputOptionType, SelectMenu, SvgChevronDown, Table, useFocusAway } from 'mod-styleguide';
import { useCreateOrderMutation, useDeleteOrderMutation, useLazyGetOrdersQuery, useUpdateOrderMutation } from 'redux/modules/api';
import './manage-jobs-table.scss';
import { translate } from 'lib/utils/intl-utils';
import { useBoolean } from 'ahooks';
import { useIntl } from 'react-intl';
import { selectAppUserRole } from 'redux/modules/app/appSlice';
import { CancelOrderModal } from 'components/cancel-order-modal';
import { CreateOrderModal } from 'components/create-order-modal';
import { OrderDetailsPanel } from 'components/order-details-panel';
import { useAppSelector } from 'lib/types/redux';
import { USER_ROLES } from 'lib/types';
import { Order } from '@stripe/stripe-js';

const columns = [
  {
    Header: translate('common.id'),
    accessor: 'id',
    width: 60,
  },
  {
    Header: translate('common.customer'),
    accessor: 'customer.name',
  },
  {
    Header: translate('manage-jobs.vehicle-info'),
    accessor: 'vehicleInfo',
  },
  {
    Header: translate('common.project-details'),
    accessor: 'details',
  },
  {
    Header: translate('common.status'),
    accessor: 'statusDropdown',
  },
];

interface ManageJobsTableProps {
}


const STATUS_DROPDOWN_OPTIONS = [
  // Due to budget constraints, we are keeping it hardcoded for now.
  // TODO - Get from API
  { label: 'Created', value: 'created', id: 'created' },
  { label: 'Assigned', value: 'assigned', id: 'assigned' },
  { label: 'Ready', value: 'ready', id: 'ready ' },
  { label: 'Installing', value: 'installing', id: 'installing' },
];

const useOrderTableDataWithElements = (ordersData, setActiveOrder, openStatusDropdown, setStatusDropdownAnchorId, setStatusDropdownPosition, setStatusDropdownWidth) => {
  const userRole = useAppSelector(selectAppUserRole);
  const isDealer = userRole === USER_ROLES.DEALER;

  const mappedOrderData = useMemo(() => (ordersData?.map((order) => {
    const anchorId = `status-dropdown-${order.id}`;
    const statusDropdown = isDealer ? (
      <div
        className='table-status-dropdown'
        id={anchorId}
        onClick={(e) => {
          const { left, right, bottom, width } = e.currentTarget.parentElement.getBoundingClientRect();

          e.stopPropagation();
          setActiveOrder(order);
          openStatusDropdown();
          setStatusDropdownAnchorId(anchorId);
          setStatusDropdownPosition({ x: (left + right) / 2, y: bottom });
          setStatusDropdownWidth(width - 32);
        }}
      >
        <span>
          {order.status}
        </span>
        <SvgChevronDown />
      </div>
    )
      : (
        <span className='table-status-dropdown'>
          {order.status}
        </span>
      );
    return {
      ...order,
      statusDropdown,
    };
  })),
  [isDealer, openStatusDropdown, ordersData, setActiveOrder, setStatusDropdownAnchorId, setStatusDropdownPosition, setStatusDropdownWidth]);


  return {
    mappedOrderData,
  };
};


export const ManageJobsTable: React.FC<ManageJobsTableProps> = () => {
  const [activeOrder, setActiveOrder] = useState<Order>(null);
  const [statusDropdownAnchorId, setStatusDropdownAnchorId] = useState<string>(null);
  const [statusDropdownPosition, setStatusDropdownPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
  const [statusDropdownWidth, setStatusDropdownWidth] = useState<number>(0);
  const [deleteOrder, deleteOrderResult] = useDeleteOrderMutation();
  const [updateOrder, updateOrderResult] = useUpdateOrderMutation();
  const [fetchOrders, { data: ordersData }] = useLazyGetOrdersQuery();
  const [createOrder, createOrderResult] = useCreateOrderMutation();
  const userRole = useAppSelector(selectAppUserRole);
  const isDealer = userRole === USER_ROLES.DEALER;

  useEffect(() => {
    fetchOrders(null);
  }, [fetchOrders]);

  const [
    isCreateOrderModalOpen,
    {
      setTrue: openCreateOrderModal,
      setFalse: closeCreateOrderModal,
    },
  ] = useBoolean();
  const [
    isCancelOrderModalOpen,
    {
      setTrue: openCancelOrderModal,
      setFalse: closeCancelOrderModal,
    },
  ] = useBoolean();
  const [
    isOrderDetailsPanelOpen,
    {
      setTrue: openOrderDetailsPanel,
      setFalse: closeOrderDetailsPanel,
    },
  ] = useBoolean();
  const [
    isStatusDropdownOpen,
    {
      setTrue: openStatusDropdown,
      setFalse: closeStatusDropdown,
    },
  ] = useBoolean();
  const {
    mappedOrderData: ordersDataWithElements,
  } = useOrderTableDataWithElements(ordersData, setActiveOrder, openStatusDropdown, setStatusDropdownAnchorId, setStatusDropdownPosition, setStatusDropdownWidth);
  const statusDropdownMenuRef = React.useRef<HTMLDivElement>(null);

  useFocusAway(closeStatusDropdown, statusDropdownMenuRef.current);

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (deleteOrderResult?.data?.id || createOrderResult?.data?.id || updateOrderResult?.data?.id) {
      fetchOrders(null);
    }
  }, [deleteOrderResult?.data?.id, fetchOrders, createOrderResult?.data?.id, updateOrderResult?.data?.id]);

  const cancelOrder = useCallback(() => {
    deleteOrder({
      id: activeOrder.id,
    });
    closeCancelOrderModal();
  }, [activeOrder, deleteOrder, closeCancelOrderModal]);

  const showOrderOnDetailsPanel = useCallback((order) => {
    setActiveOrder(order);
    openOrderDetailsPanel();
  }, [openOrderDetailsPanel]);

  if (!ordersData) {
    return null;
  }

  return (
    <div className="manage-jobs-table-rct-component">
      <Table
        title={translate('manage-jobs.title')}
        data={ordersDataWithElements}
        columns={columns}
        onRowClick={showOrderOnDetailsPanel}
        rowActions={[
          {
            label: formatMessage({
              id: 'manage-jobs.view-configurations',
            }),
            onClick: showOrderOnDetailsPanel,
          },
          isDealer && {
            label: formatMessage({
              id: 'manage-jobs.cancel-order',
            }),
            onClick: (order) => {
              setActiveOrder(order);
              openCancelOrderModal();
            },
          },
        ].filter(Boolean)}
        onAdd={isDealer && openCreateOrderModal}
      />
      <CreateOrderModal
        isOpen={isCreateOrderModalOpen}
        onClose={closeCreateOrderModal}
        onCreate={createOrder}
      />
      <CancelOrderModal
        isOpen={isCancelOrderModalOpen}
        onClose={closeCancelOrderModal}
        onCancelOrder={cancelOrder}
      />
      <OrderDetailsPanel
        isOpen={isOrderDetailsPanelOpen}
        data={activeOrder}
        onClose={closeOrderDetailsPanel}
      />
      <div ref={statusDropdownMenuRef}>
        {isStatusDropdownOpen
        && (
        <Tooltip
          className='table-rct-component__status-dropdown-tooltip'
          isOpen={isStatusDropdownOpen}
          anchorId={statusDropdownAnchorId}
          position={statusDropdownPosition}
          events={['click']}
          clickable
          style={{
            position: 'absolute',
            width: statusDropdownWidth,
          }}
          offset={0}
          place='bottom'
        >
          <SelectMenu
            options={STATUS_DROPDOWN_OPTIONS}
            onChange={(value: SelectInputOptionType) => {
              updateOrder({
                id: activeOrder?.id,
                data: {
                  status: value?.label,
                },
              });
              closeStatusDropdown();
            }}
          />
        </Tooltip>
        )}
      </div>
    </div>
  );
};

