import { VehicleConfig, VehicleModel } from 'lib/types';
import { ModConfig } from 'mod-styleguide';
import { mapVehicleModel } from './vehicle-models';

type VehicleConfigResponseAttributes = VehicleConfig & {
  modConfigs: {
    data: {
      id: string,
      attributes: ModConfig,
    }[];
  },
  vehicleModel: {
    data: {
      attributes: VehicleModel,
    };
  }
};

interface VehicleConfigResponse {
  data: {
    attributes: VehicleConfigResponseAttributes;
  }
}

export const mapVehicleModelSelectedOptionsFromOptionsId = ({
  vehicleModel,
  options,
}: {
  vehicleModel: VehicleModel,
  options: VehicleConfig['options']
}) => {
  return {
    ...vehicleModel.selectedOptions,
    trim: vehicleModel.options.trims.find(option => option.id === options.trim) || vehicleModel.selectedOptions.trim,
    color: vehicleModel.options.colors.find(option => option.id === options.color) || vehicleModel.selectedOptions.color,
  };
};

export const mapVehicleConfigResponseToViewerData = (vehicleConfig: VehicleConfigResponse) => {
  const { data } = vehicleConfig;
  const modConfigsData = data.attributes.modConfigs?.data;

  const vehicleModel = mapVehicleModel(data.attributes.vehicleModel?.data.attributes);
  return {
    ...data.attributes,
    vehicleModel: {
      ...vehicleModel,
      selectedOptions: mapVehicleModelSelectedOptionsFromOptionsId({
        vehicleModel,
        options: data.attributes.options,
      }),
    },
    modConfigs: modConfigsData.map((item: any) => ({
      ...item.attributes,
      id: item.id,
      mod: item.attributes.mod?.data.attributes.slug,
      bodyPart: item.attributes.bodyPart?.data.attributes.slug,
    })),
  };
};
