import React, { useCallback, useEffect, useState } from 'react';
import CarConfiguratorScene from '3d/car-configurator-scene';
import { useGetScenesQuery, useGetVehicleModelsQuery } from 'redux/modules/api';
import { ASSET_TYPES } from 'lib/utils';
import { ModLoader } from '3d/mod-loader';
import { useSetState } from 'ahooks';
import { carConfiguratorSelector } from 'redux/modules/carConfigurator/carConfiguratorSlice';
import { useAppSelector } from 'lib/types/redux';
import { preloadFile } from 'lib/utils/preload-file';

export const useVehicleScene = (disableScenePicker = false) => {
  const [currentScene, setCurrentScene] = useState(null);
  const scenesResult = useGetScenesQuery(null);
  const [preloadedScenes, setPreloadedScenes] = useSetState({});
  const [preloadedVehicles, setPreloadedVehicles] = useSetState({});
  const vehicleModelsResult = useGetVehicleModelsQuery(null);
  const { vehicleConfig } = useAppSelector(carConfiguratorSelector);
  const currentVehicleSlug = vehicleConfig?.vehicleModel;
  const preloadedSceneUrl = preloadedScenes[currentScene?.slug];
  const preloadedVehicleUrl = preloadedVehicles[currentVehicleSlug];
  const isSceneLoaded = preloadedSceneUrl;
  const [loadingMap, setLoadingMap] = useSetState({});


  const preloadScene = useCallback(async (slug: string) => {
    if (loadingMap[slug] || preloadedScenes[slug]) return;

    setLoadingMap({ [slug]: true });
    const sceneUrl = await preloadFile(slug, 'glb', ASSET_TYPES.SCENE);
    setPreloadedScenes({ [slug]: sceneUrl });
  }, [loadingMap, preloadedScenes, setLoadingMap, setPreloadedScenes]);

  const preloadVehicle = useCallback(async (slug: string) => {
    if (loadingMap[slug] || preloadedVehicles[slug]) return;

    setLoadingMap({ [slug]: true });
    const sceneUrl = await preloadFile(slug, 'glb', ASSET_TYPES.VEHICLE_3D_MODEL);
    setPreloadedVehicles({ [slug]: sceneUrl });
  }, [loadingMap, preloadedVehicles, setLoadingMap, setPreloadedVehicles]);

  const preloadRemainingScenes = useCallback(async (remainingScenes) => {
    for (let i = 0; i < remainingScenes.length; i++) {
      // eslint-disable-next-line
      await preloadScene(remainingScenes[i].slug);
    }
  }, [preloadScene]);

  useEffect(() => {
    if (scenesResult.data && !currentScene) {
      // If no scene is selected, select the default scene and preload it
      const defaultScene = scenesResult.data.find((s) => s.slug.includes('default'));
      setCurrentScene(defaultScene);
    }
  }, [currentScene, preloadScene, scenesResult]);

  useEffect(() => {
    if (currentScene && !preloadedSceneUrl) {
      // If the current scene is not preloaded, preload it
      preloadScene(currentScene.slug);
    }
  }, [currentScene, preloadScene, preloadedSceneUrl]);

  useEffect(() => {
    if (isSceneLoaded && currentVehicleSlug && !preloadedVehicleUrl) {
      // If the current scene is not preloaded, preload it
      preloadVehicle(currentVehicleSlug);
    }
  }, [currentVehicleSlug, isSceneLoaded, preloadVehicle, preloadedVehicleUrl]);

  useEffect(() => {
    if (preloadedSceneUrl && preloadedVehicleUrl) {
      // If current scene and vehicle has been preloaded, load remaing scenes and vehicles
      const remainingScenes = scenesResult.data.filter((s) => !preloadedScenes[s.slug]);
      if (!remainingScenes.length) {
        return;
      }

      // small timeout before preloading remaining scenes
      // reason is to try to avoid bandwidth concurrency with the main thread that should be fetching only small files at this point
      const TIMEOUT_TO_PRELOAD = 3000;
      setTimeout(() => {
        if (remainingScenes.length) {
          preloadRemainingScenes(remainingScenes);
        }
      }, TIMEOUT_TO_PRELOAD);
    }
  }, [preloadedSceneUrl, preloadedVehicleUrl, preloadRemainingScenes, preloadedScenes, preloadedVehicles, scenesResult, vehicleModelsResult]);

  if (!isSceneLoaded) {
    return <ModLoader />;
  }

  const scene = (
    <CarConfiguratorScene
      key={currentScene.slug} // force remount on scene change
      sceneData={currentScene}
      onSceneChange={setCurrentScene}
      scenes={scenesResult.data}
      preloadedSceneUrl={preloadedSceneUrl}
      preloadedVehicleUrl={preloadedVehicleUrl}
      disableScenePicker={disableScenePicker}
    />
  );

  return scene;
};
