import { useBoolean } from 'ahooks';
import { SelectedVehicleInfoField } from 'components/car-configurator/components/selected-vehicle-info-field';
import { VehiclePickerModal } from 'components/vehicle-picker-modal';
import { useAppDispatch } from 'lib/types/redux';
import { SvgEdit } from 'mod-styleguide';
import React from 'react';
import { useSelector } from 'react-redux';
import { carConfiguratorSelector } from 'redux/modules/carConfigurator/carConfiguratorSlice';
import { hydrateVehiclePickerForm } from 'redux/modules/forms/vehiclePickerSlice/vehiclePickerSlice';

import './selected-vehicle-info.scss';

interface SelectedVehicleInfoProps {
  disableAutoInit?: boolean;
  disableUnsupportedBodyParts?: boolean;
}

export const SelectedVehicleInfo: React.FC<SelectedVehicleInfoProps> = ({
  disableAutoInit,
  disableUnsupportedBodyParts,
}) => {
  const [isVehicleModalOpen, {
    setFalse: closeVehicleModal,
    setTrue: openVehicleModal,
  }] = useBoolean(false);
  const { vehicleModelData, displayInfo } = useSelector(carConfiguratorSelector);
  const dispatch = useAppDispatch();

  const onEditClick = () => {
    window.gtag('event', 'view_vehicle_options', {
      interact_type: 'open',
    });
    openVehicleModal();
    dispatch(hydrateVehiclePickerForm(vehicleModelData));
  };

  return (
    <div className="selected-vehicle-info-rct-component">
      <div className='selected-vehicle-info'>
        {displayInfo && Object.entries(displayInfo).map(([key, value]) => (
          <SelectedVehicleInfoField
            key={key}
            value={value}
          />
        ))}
      </div>
      <div onClick={onEditClick} className='edit-selected-vehicle'>
        <SvgEdit color="#BEE5FF" />
      </div>
      <VehiclePickerModal
        isOpen={isVehicleModalOpen}
        onClose={closeVehicleModal}
        disableAutoInit={disableAutoInit}
        disableUnsupportedBodyParts={disableUnsupportedBodyParts}
      />
    </div>
  );
};

