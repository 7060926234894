const ONE_WEEK_MILLISECONDS = 1000 * 60 * 60 * 24 * 7;

type LocalStorageKey = 'userToken'; // | 'otherKey' | 'anotherKey';

export const storeInLocalStorage = (key: LocalStorageKey, value: any) => {
  if (typeof window !== 'undefined') {
    const item = {
      value,
      expiresIn: Date.now() + ONE_WEEK_MILLISECONDS,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
};

const parseJson = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.log('error parsing json', { e });
    return null;
  }
};

export const retrieveFromLocalStorage = (key: LocalStorageKey, remove = true) => {
  if (typeof window !== 'undefined') {
    const item = localStorage.getItem(key);
    if (item) {
      const parsedItem = parseJson(item);

      if (Date.now() < parsedItem?.expiresIn) {
        return parsedItem.value;
      }

      if (remove) {
        localStorage.removeItem(key);
      }
    }
  }
  return null;
};
