import React from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { TRANSLATION_KEYS } from 'translations/keys';
import './text-area.scss';

const {
  FORM: {
    ERROR_DEBATE_TOPIC,
    ERROR_MAX_CHARACTERS,
  },
} = TRANSLATION_KEYS;

const TextArea = ({
  required,
  placeholder,
  id,
  resizable,
  useLabel,
  label,
  value,
  width,
  className,
  maxLength,
  onChange,
}) => {
  const { formatMessage } = useIntl();

  const inputId = `textarea-${uuidv1()}`;
  const config = {
    getError: (isRequired, textareaValue) => {
      if (isRequired && textareaValue.length < 1) {
        return formatMessage(ERROR_DEBATE_TOPIC);
      }
      if (textareaValue.length > maxLength) {
        return formatMessage(ERROR_MAX_CHARACTERS, { max: maxLength });
      }

      return '';
    },
  };

  const getError = (textareaValue) => config.getError(required, textareaValue) || '';

  const sendChangeEvent = (textareaValue) => {
    if (textareaValue.length > maxLength) {
      return;
    }

    const error = getError(textareaValue);
    onChange({
      value: textareaValue,
      error,
    });
  };

  const onValueChange = (event) => {
    sendChangeEvent(event.target.value);
  };

  const combinedClassNames = classNames(
    'text-area__container',
    id,
  );

  return (
    <div
      id={inputId}
      className={combinedClassNames}
    >
      {useLabel && <div className="text-area__label">{label}</div>}
      <textarea
        id={id}
        className={className}
        required={required}
        placeholder={placeholder}
        onChange={onValueChange}
        style={{
          resize: `${resizable ? 'true' : 'none'}`,
          width,
        }}
        value={value}
      />
    </div>
  );
};

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  resizable: PropTypes.bool,
  required: PropTypes.bool,
  useLabel: PropTypes.bool.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
  maxLength: PropTypes.number,
};

TextArea.defaultProps = {
  resizable: true,
  required: false,
  label: 'Test Label',
  value: '',
  width: '100%',
  maxLength: 300,
};


export {
  TextArea,
};
