import React, { useCallback, useMemo } from 'react';
import { TableInstance, TableRowProps, useFlexLayout, useGlobalFilter, UseGlobalFiltersInstanceProps, UseGlobalFiltersState, usePagination, UsePaginationInstanceProps, UsePaginationState, useTable, UseTableOptions } from 'react-table';
import { Button, SelectInputOptionType, SelectMenu, useFocusAway } from 'mod-styleguide';
import { useBoolean } from 'ahooks';
import { Tooltip } from 'react-tooltip';
import { mapSelectInputOptionFromSingleValue } from '../../lib';
import { SvgAdd, SvgNavArrowRight, SvgSettingsDots } from '../../assets';
import { SelectInput } from '../select-input';
import { TextInput } from '../text-input';
import { TableHeader, TableRows } from './components';

import './table.scss';

export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
UsePaginationInstanceProps<T> &
UseGlobalFiltersInstanceProps<T> & {
  state: UsePaginationState<T> & UseGlobalFiltersState<T>;
};

export interface RowAction {
  label?: string;
  onClick?: (row: any) => void;
  width?: number;
  minWidth?: number;
}

interface TableProps {
  data: UseTableOptions<any>['data'];
  columns: UseTableOptions<any>['columns'];
  rowActions?: RowAction[];
  labels?: {
    footerLabels: {
      itemsPerPage?: string;
    };
    addNewLabel?: string;
  }
  onAdd?: () => void;
  title?: string;
  onRowClick?: (row: TableRowProps) => void;
}

const pageSizesAVailable = [10, 20, 30, 40, 50];
const pageSizeOptions = pageSizesAVailable.map((size) => mapSelectInputOptionFromSingleValue(size.toString()));

const ACTION_COLUMN_DEF = {
  Header: '',
  accessor: 'actions',
  width: 64,
};

export const Table: React.FC<TableProps> = ({
  title,
  columns,
  data,
  labels: {
    footerLabels: {
      itemsPerPage = 'Items per page:',
    } = {},
    addNewLabel = 'Create order',
  } = {},
  rowActions = [],
  onAdd,
  onRowClick,
}) => {
  const [isActionMenuOpen, {
    setFalse: closeActionMenu,
    setTrue: openActionMenu,
  }] = useBoolean(false);
  const [actionMenuAnchorId, setActionMenuAnchorId] = React.useState<string>('');
  const [actionMenuPosition, setActionMenuPosition] = React.useState<{ x: number, y: number }>({ x: 0, y: 0 });
  const [actionMenuRowData, setActionMenuRowData] = React.useState<any>(null);
  const actionMenuRef = React.useRef<HTMLDivElement>(null);

  useFocusAway(() => {
    if (isActionMenuOpen) { closeActionMenu(); }
  }, actionMenuRef.current);

  const mappedTableData = useMemo(() => {
    if (!rowActions.length) {
      return {
        columns,
        data,
      };
    }
    const columnsData = [...columns, ACTION_COLUMN_DEF];
    const rowsData = [...data];
    const newRowsData = rowsData.map((row: any) => {
      const anchorId = `action-row${row.id}`;
      return {
        ...row,
        actions: (
          <span
            id={anchorId}
            onClick={(event) => {
              event.stopPropagation();
              openActionMenu();
              setActionMenuAnchorId(anchorId);
              setActionMenuRowData(row);
              const { left, bottom } = event.currentTarget.parentElement.getBoundingClientRect();

              setActionMenuPosition({ x: left, y: bottom });
            }}
          >
            <SvgSettingsDots />
          </span>
        ),
      };
    }).reverse();

    return {
      columns: columnsData,
      data: newRowsData,
    };

  }, [rowActions, columns, data, openActionMenu]);

  const actionMenuOptions = useMemo(() => rowActions.map((action) => ({
    id: action.label,
    label: action.label,
    value: action.label,
    onClick: action.onClick,
  })), [rowActions]);

  const actionMenuOnClick = useCallback((option: SelectInputOptionType) => {
    closeActionMenu();
    option?.onClick?.(actionMenuRowData);
  }, [actionMenuRowData, closeActionMenu]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    nextPage,
    previousPage,
    page,
    pageOptions,
    state: { pageSize, pageIndex, globalFilter = '' },
    setPageSize,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns: mappedTableData.columns as any,
      data: mappedTableData.data,
    },
    useGlobalFilter,
    usePagination,
    useFlexLayout,
  ) as TableInstanceWithHooks<any>;

  const displayedItemsCount = `${pageIndex * pageSize + 1} - ${pageIndex * pageSize + page.length} of ${rows.length} items`;
  const pagesCount = `${pageIndex + 1} of ${pageOptions.length}`;

  const onPageSizeChange = ({ value }: SelectInputOptionType) => {
    setPageSize(Number(value));
  };

  const footerRowDivider = (
    <div className='footer-row-container__divider'>
      <span />
    </div>
  );

  return (
    <div className='table-rct-component'>
      <div className='table-rct-component__header'>
        {title}
      </div>
      <div
        className='table-rct-component__table-top-elements'
      >
        <TextInput
          type='search'
          value={globalFilter}
          onChange={({ value }: any) => setGlobalFilter(value)}
        />
        {onAdd
        && <Button
          className='table-rct-component__table-top-elements__add-button'
          customIcon={<SvgAdd />}
          label={addNewLabel}
          primary
          onClick={onAdd}
        />}
      </div>
      <table {...getTableProps()}>
        <TableHeader headerGroups={headerGroups} />
        <tbody {...getTableBodyProps()}>
          <TableRows
            rows={page}
            prepareRow={prepareRow}
            onRowClick={onRowClick}
          />
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={'100%' as any}>
              <div className='footer-row-container'>
                <div className='footer-row-container__left-side'>
                  <span>{itemsPerPage}</span>
                  <SelectInput
                    options={pageSizeOptions}
                    value={pageSize.toString()}
                    placeholder={pageSize}
                    onChange={onPageSizeChange}
                  />
                  {footerRowDivider}
                  <span>{displayedItemsCount}</span>
                </div>
                <div className='footer-row-container__right-side'>
                  {footerRowDivider}
                  <span>{pagesCount}</span>
                  {footerRowDivider}
                  <SvgNavArrowRight
                    className={pageIndex < 1 ? 'disabled' : ''}
                    onClick={previousPage}
                  />
                  {footerRowDivider}
                  <SvgNavArrowRight
                    className={pageIndex >= pageOptions.length - 1 ? 'disabled' : ''}
                    onClick={nextPage}
                  />
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
      <div ref={actionMenuRef}>
        {isActionMenuOpen
        && (
        <Tooltip
          className='table-rct-component__action-menu'
          isOpen={isActionMenuOpen}
          anchorId={actionMenuAnchorId}
          events={['click']}
          clickable
          place='bottom'
          position={actionMenuPosition}
          offset={0}
          style={{
            position: 'absolute',
          }}
        >
          <SelectMenu
            options={actionMenuOptions}
            onChange={actionMenuOnClick}

          />
        </Tooltip>
        )}
      </div>
    </div>
  );
};

