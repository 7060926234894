import React, { useCallback, useEffect } from 'react';
import { translate } from 'lib/utils/intl-utils';
import { Button, InfoFields, SvgRightArrow } from 'mod-styleguide';
import { profileFormState } from 'redux/modules/forms';
import { hydrateProfileForm } from 'redux/modules/forms/profile/profileSlice';
import { useAppDispatch, useAppSelector } from 'lib/types/redux';
import { logout } from 'redux/thunks/auth/logout';
import './user-info.scss';
import { selectAppUserRole } from 'redux/modules/app/appSlice';
import { useUpdateProfileMutation } from 'redux/modules/api';
import { getFieldDefs, getSecondColumnFieldDefs } from './config';

export const UserInfo = () => {
  const formState = useAppSelector(profileFormState);
  const userRole = useAppSelector(selectAppUserRole);
  const [updateProfile, updateProfileResult] = useUpdateProfileMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (updateProfileResult.data) {
      dispatch(hydrateProfileForm({ ...updateProfileResult.data }));
    }
  }, [dispatch, updateProfileResult]);

  const footer = (
    <Button
      primary
      className='logout-button'
      label={translate('common.logout')}
      customIcon={<SvgRightArrow />}
      width="100%"
      onClick={() => dispatch(logout())}
    />
  );

  const onSaveFields = useCallback((fields) => {
    const fieldsToUpdate = fields.reduce((acc, field) => {
      acc[field.name] = field.value;
      return acc;
    }, {});

    updateProfile({
      data: {
        ...fieldsToUpdate,
      },
    });
  }, [updateProfile]);

  const secondColumnFields = getSecondColumnFieldDefs(userRole);
  return (
    <div className="user-info-rct-component">
      <div className='user-info-rct-component__fields'>
        <InfoFields
          fields={getFieldDefs(userRole)}
          data={formState}
          onSaveFields={onSaveFields}
        />
        {secondColumnFields && (
          <InfoFields
            fields={getSecondColumnFieldDefs(userRole)}
            data={formState}
            onSaveFields={onSaveFields}
          />
        )}
      </div>
      {footer}
    </div>
  );
};
