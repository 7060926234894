import { ModConfig } from 'lib/types';
import { mountCfAssetPath } from 'lib/utils';
import { SelectedWrapData, SelectInputOptionType, SidebarSubItem } from 'mod-styleguide';

const DEFAULT_BRAND_LABEL = '3M';
const DEFAULT_MATERIAL_LABEL = 'Gloss';

const getAllTextures = (materialsGroupedOptions, textures) => {
  const allTextures = [];

  materialsGroupedOptions.forEach((material) => {
    material.options.forEach((materialOption) => {
      allTextures.push(...textures[material.label][materialOption.label]);
    });
  });

  return allTextures;
};


const getDefaultOptions = (brands, materials) => {
  const brand = brands?.find(currentBrand => currentBrand.label === DEFAULT_BRAND_LABEL);
  const material = materials?.[DEFAULT_BRAND_LABEL]?.find(
    currentMaterial => currentMaterial.label === DEFAULT_MATERIAL_LABEL,
  );

  return {
    brand,
    material,
  };
};

export const mapVehicleModsToPickerData = (vehicleMods) => {
  const brands = [];
  const materials = {};
  const textures = {};

  const addBrand = (currentBrand) => {
    if (!brands.some(brand => brand.value === currentBrand)) {
      brands.push({
        label: currentBrand,
        value: currentBrand,
      });
    }
  };

  const addBrandMaterial = (currentBrand, currentBrandMaterial) => {
    if (!materials[currentBrand]) {
      materials[currentBrand] = [];
    }

    if (!materials[currentBrand].some(material => material.value === currentBrandMaterial)) {
      materials[currentBrand].push({
        label: currentBrandMaterial,
        value: currentBrandMaterial,
      });
    }
  };

  const addBrandMaterialTexture = (currentBrand, currentBrandMaterial, currentBrandMaterialTexture, textureSlug) => {
    if (!textures[currentBrand]) {
      textures[currentBrand] = {};
    }

    if (!textures[currentBrand][currentBrandMaterial]) {
      textures[currentBrand][currentBrandMaterial] = [];
    }

    if (!textures[currentBrand][currentBrandMaterial].some(texture => texture.value === currentBrandMaterialTexture)) {
      const textureIconSrc = mountCfAssetPath(`${textureSlug}.png`, 'wrap');

      textures[currentBrand][currentBrandMaterial].push({
        label: currentBrandMaterialTexture,
        value: textureIconSrc,
        id: textureSlug,
      });
    }
  };

  vehicleMods.forEach(({ attributes }) => {
    addBrand(attributes.brand);
    addBrandMaterial(attributes.brand, attributes.model);
    addBrandMaterialTexture(attributes.brand, attributes.model, attributes.variant, attributes.slug);
  });

  const defaultOptions = getDefaultOptions(brands, materials);
  const materialsGroupedOptions = Object.entries(materials).map(([key, value]) => ({ label: key, options: value, id: key })) as SelectInputOptionType[];

  return {
    brands,
    materials,
    materialsGroupedOptions,
    textures,
    defaultBrand: defaultOptions.brand,
    defaultMaterial: defaultOptions.material,
    allMaterialsTextures: getAllTextures(materialsGroupedOptions, textures),
  };
};

export const mapActiveWrapsToModConfigs = (activeWraps: SidebarSubItem[]): ModConfig[] => activeWraps.map(wrap => ({
  mod: wrap.value.mod,
  bodyPart: wrap.value.bodyPart,
}));

export const mountActiveWrapId = (modConfig: ModConfig): string => `${modConfig.bodyPart}_${modConfig.mod}`;
export const getBodyPartFromActiveWrapId = (activeWrapId: string): string => activeWrapId.split('_')[0];

export const mapModConfigsToWrapSidebarSubItems = (modConfigs: ModConfig[], bodyPartsData, texturesData): SidebarSubItem[] => (
  modConfigs.map((modConfig) => ({
    id: mountActiveWrapId(modConfig),
    title: bodyPartsData.find(bodyPart => modConfig.bodyPart === bodyPart.id)?.label,
    description: texturesData.find(texture => modConfig.mod === texture.id)?.label,
    value: {
      mod: modConfig.mod,
      bodyPart: modConfig.bodyPart,
    },
  }))
);

export const mapModConfigsFromSelectedWrapData = (selectedWrapData: SelectedWrapData): ModConfig[] => {
  return selectedWrapData.selectedBodyParts.map((bodyPart) => ({
    bodyPart: bodyPart.id,
    mod: selectedWrapData.texture.id,
  }));
};
