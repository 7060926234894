import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Tab, AdditionalTabRoute } from 'mod-styleguide';

interface Props {
  tabs: Tab[],
  defaultTab: Tab,
  additionalRoutes: Array<AdditionalTabRoute>,
}

export const TabRoutes: React.FC<Props> = ({
  tabs,
  defaultTab,
  additionalRoutes,
}) => {
  const location = useLocation();

  const routes = tabs.map((tab) => {
    return (
      <Route
        key={`${tab.id}-tab-container`}
        path={tab.path}
        element={tab.element}
      />
    );
  });

  const renderedAdditionalRoutes = additionalRoutes?.map((routeDef) => {
    return (
      <Route
        key={`${routeDef.id}-tab-container`}
        path={routeDef.path}
        element={routeDef.element}
      />
    );
  });

  const defaultRoute = (
    <Route
      key={`${defaultTab.id}-default-tab-container`}
      index
      element={defaultTab.element}
    />
  );

  return (
    <div className="routes-container">
      <Routes location={location}>
        {defaultRoute}
        {routes}
        {renderedAdditionalRoutes}
      </Routes>
    </div>
  );
};
