import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {
  ConnectedIntlProvider,
  RootHelmet,
  RootRouter,
} from 'containers';
import './app.scss';
import { MediaProvider } from 'components';
import { persistor, store } from '../redux/store';

// IMPORTANT: persistgate will save the state into localStorage
// and load it from there; to use a new initialState, go to
// console -> Application Tab -> Local Storage
// remove "persist:root" and reload the app
const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedIntlProvider>
        <MediaProvider>
          <RootHelmet />
          <RootRouter />
        </MediaProvider>
      </ConnectedIntlProvider>
    </PersistGate>
  </Provider>
);

export { App };
