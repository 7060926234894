import { createSlice } from '@reduxjs/toolkit';
import { VehicleModel } from 'lib/types';
import { BaseFormState, SelectInputOptionType } from 'mod-styleguide';

export type VehiclePickerFormState = BaseFormState & {
  make: SelectInputOptionType;
  model: SelectInputOptionType;
  year: SelectInputOptionType;
  trim: SelectInputOptionType;
  color: SelectInputOptionType;
};

const INITIAL_STATE = {
  touched: false,
  loading: false,
  error: '',
  validation: null,
  year: null,
  make: null,
  model: null,
  trim: null,
  color: null,
} as VehiclePickerFormState;

export const vehiclePickerSlice = createSlice({
  name: 'vehiclePicker',
  initialState: INITIAL_STATE,
  reducers: {
    hydrateVehiclePickerForm: (state, action: { payload: VehicleModel }) => {
      const {
        selectedOptions,
      } = action.payload;

      return {
        ...state,
        ...selectedOptions,
      };
    },
    updateSelectedVehicleOptions: (state, action: { payload: VehicleModel['options'] }) => {
      const {
        trims,
        colors,
      } = action.payload;

      if (!trims.find(trim => trim.id === state.trim.id)) {
        // if the current trim is not available, replace it with the first available trim
        [state.trim] = trims;
      } else if (!colors.find(color => color.id === state.color.id)) {
        // if the current color is not available, replace it with the first available color
        [state.color] = colors;
      }
    },
    onVehiclePickerFormChange: (state, action) => {
      state.touched = true;
      const { field } = action.payload;
      state[field] = action.payload.rawValue;
    },
    setVehiclePickerFormValidation: (state, action) => {
      state.validation = action.payload;
    },
  },
});

export const { hydrateVehiclePickerForm, onVehiclePickerFormChange, setVehiclePickerFormValidation, updateSelectedVehicleOptions } = vehiclePickerSlice.actions;
export default vehiclePickerSlice.reducer;
