import { defineMessages } from 'react-intl';

export const MANAGE_JOBS = defineMessages({
  TITLE: {
    id: 'manage-jobs.title',
    defaultMessage: 'Manage Jobs',
  },
  VEHICLE_INFO: {
    id: 'manage-jobs.vehicle-info',
    defaultMessage: 'Vehicle Info',
  },
  CUSTOMER_FIRST_NAME: {
    id: 'manage-jobs.customer-first-name',
    defaultMessage: 'Customer First Name',
  },
  CUSTOMER_LAST_NAME: {
    id: 'manage-jobs.customer-last-name',
    defaultMessage: 'Customer Last Name',
  },
  STOCK_NUMBER: {
    id: 'manage-jobs.stock-number',
    defaultMessage: 'Stock Number',
  },
  VIN: {
    id: 'manage-jobs.vin',
    defaultMessage: 'VIN',
  },
  VIEW_CONFIGURATIONS: {
    id: 'manage-jobs.view-configurations',
    defaultMessage: 'View Configurations',
  },
  CANCEL_ORDER: {
    id: 'manage-jobs.cancel-order',
    defaultMessage: 'Cancel Job',
  },
  CANCEL_ORDER_CONFIRMATION: {
    id: 'manage-jobs.cancel-order-confirmation',
    defaultMessage: 'Are you sure you want to cancel this job? It cannot be undone and a new order will need to be created.',
  },
  PROJECT_DETAILS_WITH_JOB_ID: {
    id: 'manage-jobs.project-details-with-job-id',
    defaultMessage: 'Project Details / Job ID: {jobId}',
  },
  CONFIGURATION_BUILD: {
    id: 'manage-jobs.configuration-build',
    defaultMessage: 'Configuration Build',
  },
  ORDER_CREATED: {
    id: 'manage-jobs.order-created',
    defaultMessage: 'Order Created',
  },
});
