import { defineMessages } from 'react-intl';
import { CAR_CONFIGURATOR } from './car-configurator';
import { COMMON } from './common';
import { FORM } from './form';
import { HELMET } from './helmet';
import { MANAGE_JOBS } from './manage-jobs';
import { ORDER_FORM } from './order-form';

export const parseIntlMessage = (intl, message) => {
  if (typeof message === 'object' && message.id) {
    return intl.formatMessage(message);
  }

  return message;
};

const APP_MESSAGES = defineMessages({
  APP_NAME: {
    id: 'app-name',
    description: 'App name',
    defaultMessage: 'MOD',
  },
  LABEL: {
    id: 'label',
    description: 'Label of the locale',
    defaultMessage: 'English',
  },
  PAGE_NOT_FOUND: {
    id: 'page-not-found',
    description: 'Page not found message',
    defaultMessage: 'The Page you requested was not found!',
  },
});

export const TRANSLATION_KEYS = {
  COMMON,
  FORM,
  HELMET,
  CAR_CONFIGURATOR,
  MANAGE_JOBS,
  ORDER_FORM,
  ...APP_MESSAGES,
};
