import { useQuery } from 'lib/hooks';
import { useAppDispatch } from 'lib/types/redux';
import { useEffect } from 'react';
import { useGetVehicleModelBySlugQuery } from 'redux/modules/api';
import { selectConfiguratorVehicle } from 'redux/modules/carConfigurator/carConfiguratorSlice';
import { mapVehicleModelSelectedOptionsFromOptionsId } from 'lib/data-mappers';
import { useVehicleFromVehicleConfigId } from './use-vehicle-from-vehicle-config';

export const useVehicleFromQueryParams = () => {
  const queryParams = useQuery();
  const vehicleConfigIdParam = queryParams.get('vehicleConfigId');
  const vehicleModelSlugParam = queryParams.get('vehicleModel');
  const vehicleColorParam = queryParams.get('vehicleColor');
  const vehicleTrimParam = queryParams.get('vehicleTrim');
  const { data: vehicleModel } = useGetVehicleModelBySlugQuery({
    slug: vehicleModelSlugParam,
  }, {
    skip: !!vehicleConfigIdParam || !vehicleModelSlugParam,
  });
  useVehicleFromVehicleConfigId(vehicleConfigIdParam);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (vehicleModel) {
      const selectedOptions = mapVehicleModelSelectedOptionsFromOptionsId({
        vehicleModel,
        options: {
          trim: vehicleTrimParam,
          color: vehicleColorParam,
        },
      });


      dispatch(selectConfiguratorVehicle({
        vehicleModel,
        selectedOptions,
      }));
    }
  }, [dispatch, vehicleColorParam, vehicleModel, vehicleTrimParam]);

  return {
    hasEnoughVehicleDataFromParams: !!vehicleModelSlugParam || !!vehicleConfigIdParam,
  };
};
