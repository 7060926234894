import * as BABYLON from '@babylonjs/core';
import { setupBaseScene } from '../common';
import { setupOutdoorLights } from './setup-outdoor-lights';
import { setupOutdoorShadowGenerators } from './setup-outdoor-shadow-generators';
import { setupOutdoorSkybox } from './setup-outdoor-skybox';

export const initializeOutdoorScene = async (scene: BABYLON.Scene, sceneData) => {
  setupBaseScene(scene, sceneData);
  const skybox = setupOutdoorSkybox(scene, sceneData);
  const lights = setupOutdoorLights(scene);
  const shadowGenerators = setupOutdoorShadowGenerators(scene, lights);
  scene.autoClear = false; // Color buffer
  scene.autoClearDepthAndStencil = false;

  return {
    skybox,
    lights,
    shadowGenerators,
  };
};
