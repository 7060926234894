import React from 'react';
import './selected-vehicle-info-field.scss';

export const SelectedVehicleInfoField = ({
  value,
}: {
  value: string;
}) => {

  return (
    <div className='selected-vehicle-info-field'>
      <span className='selected-vehicle-info-field__value'>{value}</span>
    </div>
  );
};
