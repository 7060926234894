import { validateObject } from '@codeparticle/formal';
import { useMount } from 'ahooks';
import { ROUTE_PATHS } from 'lib/constants';
import { useAppDispatch, useAppSelector } from 'lib/types/redux';
import { VALIDATION_RULES } from 'lib/utils';
import { translate } from 'lib/utils/intl-utils';
import { BackButton, Form } from 'mod-styleguide';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetPasswordFormState } from 'redux/modules/forms';
import { onResetPasswordFormChange, setResetPasswordFormValidation } from 'redux/modules/forms/resetPassword/resetPasswordSlice';
import { resetPassword } from 'redux/thunks';

import './reset-password-form.scss';


const validateForm = validateObject({
  password: [VALIDATION_RULES.PASSWORD],
  passwordConfirmation: [VALIDATION_RULES.PASSWORD],
});

interface ResetPasswordFormProps {
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = () => {
  const formState = useAppSelector(resetPasswordFormState);
  const dispatch = useAppDispatch();
  const queryParams = useSearchParams();
  const resetPasswordCode = queryParams[0].get('code');
  const navigate = useNavigate();

  useMount(() => {
    if (!resetPasswordCode) {
      navigate(ROUTE_PATHS.LOGIN, {
        replace: false,
      });
    }
  });

  const onInputChange = (e) => {
    dispatch(onResetPasswordFormChange(e));
  };

  const onSubmit = () => {
    dispatch(resetPassword({
      ...formState,
      code: resetPasswordCode,
    }));
  };

  return (
    <div className="reset-password-form-rct-component">
      <BackButton
        label={translate('forgot-password.back-to-login')?.toUpperCase()}
        to={ROUTE_PATHS.LOGIN}
      />
      <h1>{translate('forgot-password.reset-password')}</h1>
      <span className='description'>{translate('forgot-password.enter-a-new-password')}</span>
      <Form
        submitLabel={translate('forgot-password.reset-password')}
        formState={formState}
        autoComplete="on"
        onSubmit={onSubmit}
        validateForm={validateForm}
        setFormValidation={(v) => dispatch(setResetPasswordFormValidation(v))}
        onInputChange={onInputChange}
        formFieldDefs={[
          {
            name: 'password',
            autoComplete: 'new-password',
            label: translate('common.password'),
            placeholder: translate('form.password-placeholder'),
          },
          {
            name: 'passwordConfirmation',
            type: 'password',
            autoComplete: 'new-password',
            label: translate('common.confirm-password'),
            placeholder: translate('form.password-placeholder'),
          },
        ]}
      />
    </div>
  );
};

