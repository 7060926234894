import React, { ReactElement } from 'react';
import { SelectInputOptionType, SvgSuccess } from 'mod-styleguide';
import { Visible } from '@codeparticle/react-visible';

export interface MenuOptionProps {
  className: string;
  option: SelectInputOptionType;
  onChange: (selectedOption: SelectInputOptionType | SelectInputOptionType[]) => void;
  selected: boolean;
  subOptions?: ReactElement[];
}

export const MenuOption:React.FC<MenuOptionProps> = ({
  className,
  option,
  onChange,
  selected,
  subOptions,
}) => {
  const hasSubOptions = Boolean(subOptions) && subOptions.length > 0;

  const onOptionClick = () => {
    if (hasSubOptions) {
      // If it has sub options we don't want to do anything to the parent option that serves as a option group header
      return;
    }

    onChange(option);
  };

  return (
    <div className={className}>
      <div
        className="option"
        onClick={onOptionClick}
      >
        {option?.label}
        <Visible when={selected && !hasSubOptions}>
          <SvgSuccess />
        </Visible>
      </div>
      <div
        className='sub-options'
      >
        {subOptions}
      </div>
    </div>
  );
};
