import * as BABYLON from '@babylonjs/core';
import { setupShadowGenerators } from './setup-shadow-generators';
import { setupBaseScene } from '../common';

export const initializeDefaultScene = (scene: BABYLON.Scene, sceneData) => {
  // Add glow for reflections
  const gl = new BABYLON.GlowLayer('glow', scene);
  gl.intensity = 1;
  gl.blurKernelSize = 16;
  setupBaseScene(scene, sceneData);
  setupShadowGenerators(scene);
};
