import React from 'react';
import { components, SingleValueProps } from 'react-select';
import { Visible } from '@codeparticle/react-visible';
import type { SelectInputOptionType } from 'mod-styleguide';

export const CustomSingleValue: React.FC<SingleValueProps> = (props) => {
  const { labelPrefix } = props.selectProps as any || {};
  const { icon } = props.getValue()[0] as SelectInputOptionType || {};

  return (
    <components.SingleValue className='single-value' {...props}>
      {labelPrefix}
      <span className='placeholder-label'>
        <Visible when={icon}> {icon} </Visible> {props.children}
      </span>
    </components.SingleValue>
  );
};
