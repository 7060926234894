import * as BABYLON from '@babylonjs/core';
import { MAX_CAMERA_DISTANCE } from 'lib/constants';

export const createCamera = (scene: BABYLON.Scene) => {
  const camera = new BABYLON.ArcRotateCamera('Camera', -Math.PI / 2, Math.PI / 2, 5, BABYLON.Vector3.Zero(), scene);
  const canvas: HTMLCanvasElement = scene.getEngine().getRenderingCanvas() as HTMLCanvasElement;

  // This attaches the camera to the canvas
  camera.attachControl(canvas, true);
  camera.upperBetaLimit = 1.55;
  camera.lowerBetaLimit = 0.5;
  camera.upperRadiusLimit = MAX_CAMERA_DISTANCE;

  camera.minZ = 0.1;
  camera.fov = 0.8;
  camera.inertia = 0.9;
  camera.wheelPrecision = 80;
  camera.pinchPrecision = 80;
  camera.angularSensibilityX = 1000;
  camera.angularSensibilityY = 1000;

  camera.setPosition(new BABYLON.Vector3(0, 0, -MAX_CAMERA_DISTANCE));
  camera.checkCollisions = true;
  camera.panningSensibility = 0;

  return camera;
};
