import { mountName } from 'lib/utils/mount-name';

export const mapOrdersData = (data) => {
  if (!data) return [];

  return data.map((order) => {
    const {
      customerVehicle,
      dealerProfile,
      installer,
      status,
      eta,
    } = order.attributes || {};
    const customerVehicleData = customerVehicle?.data?.attributes;
    const vinNumber = customerVehicleData?.vinNumber;
    const stockNumber = customerVehicleData?.stockNumber;
    const installerData = installer?.data?.attributes;
    const customerData = customerVehicleData?.customer?.data?.attributes;
    const vehicleConfigData = customerVehicleData?.vehicleConfig.data.attributes;
    const vehicleConfigId = customerVehicleData?.vehicleConfig.data.id;
    const formattedVehicleConfigId = vehicleConfigId?.toString().length === 1 ? `0${vehicleConfigId}` : vehicleConfigId;
    const vehicleModelData = vehicleConfigData?.vehicleModel.data.attributes;
    const modConfigs = vehicleConfigData?.modConfigs?.data;
    const modConfigsData = vehicleConfigData?.modConfigs?.data?.[0].attributes;
    const dealerProfileData = dealerProfile?.data?.attributes;

    const vehicleModelTrimOption = vehicleModelData?.options?.trims.find((trimOption) => trimOption.slug === vehicleConfigData.options.trim)?.name || '';

    const detailsSubRow = `${modConfigsData?.bodyPart.data.attributes.name}: ${modConfigsData?.mod.data.attributes.variant}`;
    return ({
      id: order.id,
      customer: {
        name: mountName(customerData),
        email: customerData?.email,
      },
      installer: {
        name: mountName(installerData),
        email: installerData?.email,
      },
      configuration: {
        body: modConfigs?.find((modConfig) => modConfig?.attributes.bodyPart.data.attributes.slug === 'body-part-body')?.attributes.mod.data.attributes.variant || 'None',
        hood: modConfigs?.find((modConfig) => modConfig?.attributes.bodyPart.data.attributes.slug === 'body-part-hood')?.attributes.mod.data.attributes.variant || 'None',
        tailgate: modConfigs?.find((modConfig) => modConfig?.attributes.bodyPart.data.attributes.slug === 'body-part-tailgate')?.attributes.mod.data.attributes.variant || 'None',
      },
      vehicleConfigId,
      dealer: mountName(dealerProfileData),
      vehicleInfo: vehicleConfigId ? `Vehicle ID: ${formattedVehicleConfigId}` : '',
      details: `${vehicleModelData?.make} ${vehicleModelData?.model} ${vehicleModelTrimOption}`,
      status,
      eta,
      totalCost: customerVehicleData?.price ? `$ ${customerVehicleData.price}` : null,
      subRows: [
        {
          details: detailsSubRow,
          vehicleInfo: vinNumber ? `VIN: ${vinNumber}` : '',
        },
      ],
      stockNumber,
      vinNumber,
    });
  });
};
