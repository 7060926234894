import React, { useCallback, useEffect, useState } from 'react';
import { SelectInputOptionType, SelectInput, WrapPickerProps } from 'mod-styleguide';
import './wrap-picker.scss';
import classNames from 'classnames';
import { Visible } from '@codeparticle/react-visible';
import { isEqual } from 'lodash';
import { TexturePicker } from './components/texture-picker';

const BRAND_ABBREVIATION = {
  'Avery Dennison': 'AD',
  '3M': '3M',
};

export const WrapPicker: React.FC<WrapPickerProps> = ({
  materialsGroupedOptions,
  textures,
  bodyParts,
  defaultBrand,
  defaultMaterial,
  defaultTexture,
  setDefaultPickerData,
  labels: {
    materialLabel = 'Material',
    headerTitle = 'Vehicle area',
    textureLabel,
    bodyPartLabel,
    selectAllBodyPartsLabel,
  } = {},
  onApply,
  activeModConfigs,
  carouselSlidesToScroll,
  isMobile,
  allMaterialsTextures,
  onClearWraps,
  onMaterialListOpen,
  onMaterialChange,
  onTextureChange,
}) => {
  const [selectedBrand, setSelectedBrand] = useState<SelectInputOptionType>(defaultBrand);
  const [selectedMaterial, setSelectedMaterial] = useState<SelectInputOptionType>(defaultMaterial);
  const [selectedTexture, setSelectedTexture] = useState<SelectInputOptionType>(defaultTexture);
  const [selectedBodyParts, setSelectedBodyParts] = useState<SelectInputOptionType[]>(bodyParts);
  const [bodyPartInputDisplayValue, setBodyPartInputDisplayValue] = useState<string>(selectAllBodyPartsLabel);

  const handleTextureClick = useCallback((texture: SelectInputOptionType) => {
    setSelectedTexture(texture);
  }, [selectedTexture, setSelectedTexture]);

  useEffect(() => {
    setDefaultPickerData?.({
      defaultBrand: selectedBrand,
      defaultMaterial: selectedMaterial,
      defaultTexture: selectedTexture,
    });
  }, [selectedBrand, selectedMaterial, selectedTexture, setDefaultPickerData]);

  // if not using all at once, filter textures based on selected brand and material
  const currentlyAvailableTextures = allMaterialsTextures ?? textures?.[selectedBrand?.label]?.[selectedMaterial?.label];

  const onApplyTexture = (texture, newBodyParts) => {
    const textureBodyParts = newBodyParts?.length ? newBodyParts : selectedBodyParts;
    if (!textureBodyParts.length) {
      return;
    }
    onApply({ texture, selectedBodyParts: textureBodyParts });
    onTextureChange?.(selectedMaterial, texture);
  };

  if (!defaultBrand || !defaultMaterial) {
    return null;
  }

  const onMaterialInputChange = (selectedOptions: SelectInputOptionType[]) => {
    const newSelectedBrand = selectedOptions[0];
    const newSelectedMaterial = selectedOptions[1];
    setSelectedTexture(null);
    setSelectedBrand(newSelectedBrand);
    setSelectedMaterial(newSelectedMaterial);
    onMaterialChange?.(newSelectedBrand, newSelectedMaterial);
  };

  const onBodyPartInputChange = (selectedOptions: SelectInputOptionType[]) => {
    const bodyPartsArray = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
    setSelectedBodyParts(bodyPartsArray);

    const bodyPartsInputValue = isEqual(bodyParts, bodyPartsArray) ? selectAllBodyPartsLabel : bodyPartsArray?.[0]?.label;
    setBodyPartInputDisplayValue(bodyPartsInputValue);

    if (selectedTexture) {
      onApplyTexture(selectedTexture, bodyPartsArray);
    }
  };

  const useCarousel = (carouselSlidesToScroll && currentlyAvailableTextures?.length > carouselSlidesToScroll);

  const visibleBodyParts = bodyParts?.filter((bodyPart) => !bodyPart.hidden);
  return (
    <div
      className={classNames(
        'wrap-picker-rct-component',
        { 'wrap-picker-rct-component--texture-selected': selectedTexture },
      )}
    >
      <div
        className='wrap-picker-rct-component__options'
      >
        <Visible when={visibleBodyParts.length}>
          <Visible
            fallback={
              <SelectInput
                options={materialsGroupedOptions}
                innerLabelPrefix={materialLabel}
                value={selectedMaterial?.label}
                onOpen={onMaterialListOpen}
                onChange={onMaterialInputChange}
                getOptionLabel={(option) => `${BRAND_ABBREVIATION[selectedBrand?.label] || selectedBrand.label} ${option.label}`}
                secondary
              />
          }
            when={allMaterialsTextures}
          >
            <SelectInput
              options={visibleBodyParts}
              value={bodyPartInputDisplayValue}
              onChange={onBodyPartInputChange}
              innerLabelPrefix={bodyPartLabel}
              secondary
              selectAllLabel={selectAllBodyPartsLabel}
            />
          </Visible>
        </Visible>
        <Visible when={selectedTexture && textureLabel}>
          <div className='selected-texture'>
            <Visible when={textureLabel}>
              <span className='selected-texture-label'>
                {textureLabel}
              </span>
            </Visible>
            <span className='selected-texture-value'>
              {selectedTexture?.label}
            </span>
          </div>
        </Visible>
      </div>
      <TexturePicker
        textures={currentlyAvailableTextures}
        selectedTexture={selectedTexture}
        setSelectedTexture={handleTextureClick}
        onApplyTexture={onApplyTexture}
        headerTitle={headerTitle}
        // When using all materials textures at once, body parts are selected from a separate input
        bodyParts={allMaterialsTextures ? [] : visibleBodyParts}
        activeModConfigs={activeModConfigs}
        slidesToScroll={carouselSlidesToScroll}
        isMobile={isMobile}
        selectAllLabel={selectAllBodyPartsLabel}
        onClear={onClearWraps}
        useCarousel={useCarousel}
      />
    </div>
  );
};

