/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
import {
  Scene,
  Vector3,
  SceneLoader,
  Engine,
  ISceneLoaderAsyncResult,
  EngineOptions,
  NodeMaterial,
  ShadowGenerator,
  ArcRotateCamera,
  Mesh,
  PostProcess,
  DefaultRenderingPipeline,
  PBRMetallicRoughnessBlock,
} from '@babylonjs/core';
import '@babylonjs/loaders';
import '@babylonjs/inspector';
import { CAMERA_TARGET_Y_OFFSET } from 'lib/constants';
import type { ModConfig, SceneData, VehicleModelOptions } from 'lib/types';
import { ASSET_TYPES, mountCfAssetPath } from 'lib/utils';
import { debounce } from 'lodash';
import {
  applyVehicleBaseColor,
  getMeshesFromBodyPartSlug,
  getMeshFromBodyPartSlug,
  getVehicleColorMaterial,
  initializeVehicleMeshes,
  initializeScene,
  setupPostProcessing,
  createCamera,
  loadScene,
  getActiveShadowGenerators,
  optimizeMesh,
} from './helpers/index';
import { CustomLoadingScreen } from './loading-screen';

const sendRotateAnalyticsEvent = debounce(() => {
  window.gtag('event', 'model_interact', {
    interact_type: 'rotate',
  });
}, 500);

const sendZoomAnalyticsEvent = debounce(() => {
  window.gtag('event', 'model_interact', {
    interact_type: 'zoom',
  });
}, 500);

export class SceneManager {
  engine:Engine = null;

  canvas:HTMLCanvasElement = null;

  error = null;

  scene: Scene = null;

  currentlyRenderedVehicle: ISceneLoaderAsyncResult = null;

  currentlyActiveTrimMeshes: Mesh[] = [];

  currentlyActiveBaseColorSlug: string = null;

  pendingWraps: ModConfig[] = [];

  pendingWrapsToRemove: ModConfig[] = [];

  shadowGenerators: ShadowGenerator[] = [];

  isMobile = false;

  fpsSpan: HTMLElement = null;

  configIdSpan: HTMLElement = null;

  debugModeInitialized = false;

  vehicleToRenderUrl: string = null;

  vehicleOptions: VehicleModelOptions = null;

  activeVehicleOptions: VehicleModelOptions = null;

  vehicleInitialized = false;

  vehicleConfigId: string = null;

  wrapInProgress = false;

  camera: ArcRotateCamera = null;

  postProcesses: (PostProcess | DefaultRenderingPipeline)[];

  waitingWrapForFirstRender = false;

  sceneData = {
    name: '',
    slug: '',
    isOutdoor: false,
  };

  lastAlpha = 0;

  lastBeta = 0;

  lastRadius = 0;

  constructor(
    preloadedSceneUrl: string,
    sceneData: SceneData,
    canvas: HTMLCanvasElement,
    engineOptions: EngineOptions,
    adaptToDeviceRatio: boolean,
    onSceneReady?: (scene) => void,
  ) {
    this.canvas = canvas;
    try {
      this.engine = new Engine(
        canvas,
        true,
        engineOptions,
        adaptToDeviceRatio,
      );
      this.engine.loadingScreen = new CustomLoadingScreen();
    } catch (err) {
      console.error(err);
      this.error = true;
    }

    this.sceneData = sceneData;

    loadScene(preloadedSceneUrl, this.engine, (scene: Scene) => {
      this.onSceneReady(scene);
      if (onSceneReady) {
        onSceneReady(scene);
      }
    });
  }

  setIsMobile = (isMobile: boolean) => {
    this.isMobile = isMobile;
  };

  setConfigId = (configId: string) => {
    this.vehicleConfigId = configId;
  };

  startDebugMode() {
    const debugModeContainer = document.getElementById('debug-mode-container');
    debugModeContainer.style.display = 'flex';
    this.fpsSpan = document.getElementById('fps-span');
    this.configIdSpan = document.getElementById('config-id-span');
    this.debugModeInitialized = true;
    this.scene.debugLayer.show({
      embedMode: true,
    });
  }

  stopDebugMode() {
    const debugModeContainer = document.getElementById('debug-mode-container');
    debugModeContainer.style.display = 'none';
    this.debugModeInitialized = false;
    this.scene.debugLayer.hide();
  }

  setVehicleToRender = (vehicle3dModelUrl: string) => {
    this.disposeOfRenderedVehicle();
    this.vehicleToRenderUrl = vehicle3dModelUrl;
  };

  setVehicleOptions = (vehicleOptions: VehicleModelOptions) => {
    this.vehicleOptions = vehicleOptions;
  };

  handleDebugMode = () => {
    // @ts-ignore
    if (window.CAR_CONFIGURATOR_DEBUG_MODE || process.env.REACT_APP_DEBUG_MODE) {
      if (this.debugModeInitialized) {
        this.fpsSpan.innerHTML = `FPS: ${this.engine.getFps().toFixed()}`;
        this.configIdSpan.innerHTML = `CONFIG_ID: ${this.vehicleConfigId || 'N/A'}`;
      } else if (!this.debugModeInitialized) {
        this.startDebugMode();
      }
    } else if (this.debugModeInitialized) {
      this.stopDebugMode();
    }
  };

  trackCameraEvents = () => {
    if (this.lastAlpha !== this.camera.alpha || this.lastBeta !== this.camera.beta) {
      this.lastAlpha = this.camera.alpha;
      this.lastBeta = this.camera.beta;
      sendRotateAnalyticsEvent();
    } else if (this.lastRadius !== this.camera.radius) {
      this.lastRadius = this.camera.radius;
      sendZoomAnalyticsEvent();
    }
  };

  onRenderLoop = () => {
    this.trackCameraEvents();

    if (!this.currentlyRenderedVehicle && !this.vehicleToRenderUrl) {
      this.engine.displayLoadingUI();
    } else {
      this.engine.hideLoadingUI();
    }

    if (this.vehicleToRenderUrl && this.vehicleOptions) {
      // Render new vehicle and set vehicle options
      this.renderVehicle(this.vehicleToRenderUrl, this.vehicleOptions);
      this.vehicleToRenderUrl = null;
      this.vehicleOptions = null;
    } else if (this.currentlyRenderedVehicle && this.vehicleOptions) {
      // Update vehicle options of already rendered vehicle
      this.updateVehicleOptions(this.vehicleOptions);
      this.vehicleOptions = null;
    }

    if (!this.pendingWraps.length && !this.wrapInProgress && this.vehicleInitialized && this.waitingWrapForFirstRender) {
      this.makeVehicleVisible();
      this.waitingWrapForFirstRender = false;
    }
    if (this.pendingWraps.length && this.vehicleInitialized) {
      this.applyVehicleWrap([this.pendingWraps.reverse().pop()]);
    } else if (this.pendingWrapsToRemove.length && !this.wrapInProgress) {
      this.removeVehicleWrap(this.pendingWrapsToRemove);
      this.pendingWrapsToRemove = [];
    }

    this.camera.target.y = !this.isMobile ? CAMERA_TARGET_Y_OFFSET : 0;
    this.handleDebugMode();
  };

  makeVehicleVisible = () => {
    if (this.currentlyRenderedVehicle) {
      for (const mesh of this.currentlyRenderedVehicle.meshes) {
        if (mesh.isEnabled() && !mesh.id.includes('collision')) {
          mesh.isVisible = true;
        }
      }
    }
  };

  removeVehicleWrap = (removedModConfigs: ModConfig[]) => {
    if (!this.currentlyRenderedVehicle || removedModConfigs.length === 0 || this.wrapInProgress) {
      if (removedModConfigs.length) {
        this.pendingWrapsToRemove = removedModConfigs;
      }
      return;
    }

    try {
      removedModConfigs.forEach((modConfig) => {
        const { bodyPart } = modConfig;

        const mesh = getMeshFromBodyPartSlug(bodyPart, this.currentlyRenderedVehicle.meshes);
        if (!mesh) {
          return;
        }
        mesh.material = mesh.initialMaterial;
      });
    } catch (e) {
      console.error(e);
    }

  };

  // eslint-disable-next-line
  applyWallConfigMaterial = (_selectedTextureName) => {
    // disabled for now
    // applyWallWrapMaterial(this.scene, selectedTextureName);
  };

  applyVehicleWrap = async (modConfigs: ModConfig[]) => {
    if (!this.currentlyRenderedVehicle || modConfigs.length === 0 || this.wrapInProgress) {
      if (modConfigs.length) {
        // if we have mod configs for the vehicle that hasn't been loaded yet, mark them as pending so render loop can apply them once the vehicle is loaded
        const modConfigsToAdd = modConfigs.filter((modConfig) => !this.pendingWraps.some((pendingModConfig) => pendingModConfig.bodyPart === modConfig.bodyPart && pendingModConfig.mod === modConfig.mod));
        this.pendingWraps.push(...modConfigsToAdd);
      }
      return;
    }
    this.wrapInProgress = true;

    try {
      for (const modConfig of modConfigs) {
        const {
          mod,
          bodyPart,
        } = modConfig;

        const meshes = getMeshesFromBodyPartSlug(bodyPart, this.currentlyRenderedVehicle.meshes);
        this.applyWallConfigMaterial(mod);
        for (const mesh of meshes) {
          mesh.initialMaterial = mesh.initialMaterial ?? mesh.material;
          const wrapMaterialUrl = mountCfAssetPath(`${mod}.json`, ASSET_TYPES.VEHICLE_WRAP);
          const newMaterial = await NodeMaterial.ParseFromFileAsync(`${bodyPart}Material`, wrapMaterialUrl, this.scene);
          newMaterial.backFaceCulling = false;
          await newMaterial.forceCompilationAsync(mesh);
          if (this.sceneData.isOutdoor) {
            const pbrBlock = newMaterial.getBlockByName('PBRMetallicRoughness') as PBRMetallicRoughnessBlock;
            pbrBlock.directIntensity = 0.5;
          }
          newMaterial.freeze();
          mesh.material = newMaterial;
        }
      }
      this.wrapInProgress = false;

    } catch (e) {
      console.error(e);
    }
  };

  updateVehicleOptions = async (vehicleOptions: VehicleModelOptions) => {
    if (!this.currentlyRenderedVehicle) {
      return;
    }

    const {
      trim,
      color,
    } = vehicleOptions;
    const {
      meshes,
    } = this.currentlyRenderedVehicle;

    if (color !== this.currentlyActiveBaseColorSlug) {
      const vehicleColorMaterial = await getVehicleColorMaterial(color, this.scene);
      await applyVehicleBaseColor(meshes as Mesh[], vehicleColorMaterial, this.sceneData);
      this.currentlyActiveBaseColorSlug = color;
    }

    // Disable active trim meshes and enable new trim meshes
    this.currentlyActiveTrimMeshes.forEach(mesh => {
      mesh.setEnabled(false);
    });

    const trimMeshes = meshes.filter(mesh => mesh.id.includes(`${trim}_`));
    trimMeshes.forEach(mesh => {
      mesh.setEnabled(true);
      mesh.isVisible = true;
    });
    this.currentlyActiveTrimMeshes = trimMeshes as Mesh[];
    this.activeVehicleOptions = vehicleOptions;
  };

  disposeOfRenderedVehicle = () => {
    if (this.currentlyRenderedVehicle) {
      this.currentlyRenderedVehicle.meshes.forEach(mesh => mesh.dispose());
      this.currentlyRenderedVehicle = null;
    }
  };

  renderVehicle = async (vehicle3dModelUrl: string, options: VehicleModelOptions = this.activeVehicleOptions): Promise<ISceneLoaderAsyncResult> => {
    if (!vehicle3dModelUrl || !options.trim || !options.color) {
      return null;
    }
    this.disposeOfRenderedVehicle();

    this.engine.displayLoadingUI();

    this.currentlyRenderedVehicle = await SceneLoader.ImportMeshAsync('', vehicle3dModelUrl, '', this.scene, undefined, '.glb');

    const {
      meshes,
    } = this.currentlyRenderedVehicle;

    meshes.forEach(mesh => {
      mesh.isVisible = false;
    });
    await initializeVehicleMeshes(meshes as Mesh[], this.shadowGenerators);
    this.currentlyActiveBaseColorSlug = null;
    await this.updateVehicleOptions(options);
    this.vehicleInitialized = true;
    this.engine.hideLoadingUI();

    this.waitingWrapForFirstRender = this.pendingWraps.length > 0;

    if (!this.waitingWrapForFirstRender) {
      // If we don't have any starting wrap applied, show all meshes after base vehicle meshes and options have been initialized
      this.makeVehicleVisible();
    }
    this.camera.setTarget(new Vector3(
      meshes[0].position._x,
      !this.isMobile ? CAMERA_TARGET_Y_OFFSET : 0,
      meshes[0].position._z,
    ));

    return this.currentlyRenderedVehicle;
  };

  onSceneReady = (scene) => {
    this.scene = scene;
    initializeScene(scene, this.sceneData);
    this.camera = createCamera(scene);
    this.shadowGenerators = getActiveShadowGenerators(scene);
    this.postProcesses = setupPostProcessing(scene, this.sceneData.isOutdoor);

    this.engine.runRenderLoop(() => {
      this.onRenderLoop();
      scene.render();
    });
    scene.meshes.forEach((mesh) => {
      optimizeMesh(mesh);
    });

  };

  // Clean up and dispose of everything, a new manager instance needs to be created in order to restart the scene
  dispose = () => {
    this.postProcesses?.forEach(process => process.dispose());
    this.scene?.dispose();
    this.engine?.dispose();
  };
}
