import React, { useState, useEffect, useRef, useCallback } from 'react';
import { selectActiveModConfigs, selectActiveVehicleConfigId, selectConfiguratorVehicleOptions } from 'redux/modules/carConfigurator/carConfiguratorSlice';
import { useAppSelector } from 'lib/types/redux';
import { usePrevious } from 'ahooks';
import { ModConfig, SceneData, VehicleModelOptions } from 'lib/types';
import { isEqual } from 'lodash';
import { useMedia } from 'lib/hooks';
import { SvgSceneIconIndoor, SvgSceneIconOutdoor } from 'mod-styleguide';
import type { BabylonjsProps } from './types';
import { SceneManager } from './scene-manager';
import './car-configurator-scene.scss';
import { ModLoader } from './mod-loader';

export default ({
  engineOptions,
  adaptToDeviceRatio,
  sceneData,
  onSceneChange,
  scenes,
  preloadedSceneUrl,
  preloadedVehicleUrl,
  disableScenePicker = false,
}: BabylonjsProps & {
  sceneData?: {
    name: string;
    slug: string;
    isOutdoor: boolean;
  };
  onSceneChange?: (sceneData: SceneData) => void;
  scenes?: SceneData[];
  preloadedSceneUrl?: string;
  preloadedVehicleUrl?: string;
  disableScenePicker?: boolean;
}) => {
  const reactCanvas = useRef<HTMLCanvasElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const sceneManagerRef = useRef<SceneManager>(null);
  const vehicleOptions = useAppSelector<VehicleModelOptions>(selectConfiguratorVehicleOptions);
  const vehicleModConfigs = useAppSelector<ModConfig[]>(selectActiveModConfigs);
  const vehicleConfigId = useAppSelector<string>(selectActiveVehicleConfigId);
  const prevModConfigs = usePrevious<ModConfig[]>(vehicleModConfigs);
  const { isMobile } = useMedia();
  const [isReady, setIsSceneReady] = useState(false);

  useEffect(() => {
    if (sceneManagerRef.current || !reactCanvas.current) {
      return;
    }
    sceneManagerRef.current = new SceneManager(
      preloadedSceneUrl,
      sceneData,
      reactCanvas.current,
      engineOptions,
      adaptToDeviceRatio,
      setIsSceneReady,
    );

    return () => {
      sceneManagerRef.current.dispose();
      delete sceneManagerRef.current;
    };
  }, [adaptToDeviceRatio, engineOptions, preloadedSceneUrl, sceneData]);

  useEffect(() => {
    sceneManagerRef.current?.setIsMobile(isMobile);
  }, [isMobile]);

  useEffect(() => {
    sceneManagerRef.current?.setVehicleToRender(preloadedVehicleUrl);
  }, [preloadedVehicleUrl]);

  useEffect(() => {
    if (vehicleOptions) {
      sceneManagerRef.current?.setVehicleOptions(vehicleOptions);
    }
  }, [vehicleOptions]);

  useEffect(() => {
    if (vehicleModConfigs.length) {
      sceneManagerRef.current?.setConfigId(vehicleConfigId);
    } else {
      sceneManagerRef.current?.setConfigId(null);
    }
  }, [vehicleConfigId, vehicleModConfigs]);

  useEffect(() => {
    if (isEqual(prevModConfigs, vehicleModConfigs)) {
      return;
    }

    if (prevModConfigs?.length) {
      const removedConfigs = prevModConfigs.filter(prevModConfigsItem => !vehicleModConfigs.some(config => prevModConfigsItem.bodyPart === config.bodyPart));
      sceneManagerRef.current?.removeVehicleWrap(removedConfigs);
    }

    const addedConfigs = vehicleModConfigs.filter(config => !prevModConfigs?.some(prevModConfig => isEqual(config, prevModConfig)) || !prevModConfigs.length);
    sceneManagerRef.current?.applyVehicleWrap(addedConfigs);
  }, [prevModConfigs, vehicleModConfigs]);

  const resize = useCallback(() => {
    if (!reactCanvas.current) {
      return;
    }
    const { parentElement } = reactCanvas.current;

    if (parentElement) {
      const { offsetHeight, offsetWidth } = parentElement;

      setWidth(offsetWidth);
      setHeight(offsetHeight);
    }

    sceneManagerRef.current.engine.resize();
  }, []);


  useEffect(() => {
    if (!sceneManagerRef.current) {
      return;
    }
    // initialize scene size and add listeners to resize window event
    resize();

    if (window) {
      window.addEventListener('resize', resize);
    }

    return () => {
      if (window) {
        window.removeEventListener('resize', resize);
      }
    };
  }, [resize]);

  useEffect(() => {
    if (!sceneData.isOutdoor && !vehicleModConfigs.length) {
      sceneManagerRef.current?.applyWallConfigMaterial(vehicleModConfigs[vehicleModConfigs.length - 1]?.mod);
    }

  }, [sceneData?.isOutdoor, vehicleModConfigs]);

  return (
    <>
      <canvas
        ref={reactCanvas}
        width={width}
        height={height}
      />
      <ModLoader />
      {
        !disableScenePicker && isReady
        && (
          <div className='scene-picker'>
            <div>
              <SvgSceneIconIndoor onClick={() => onSceneChange(scenes.find(scene => scene.slug.includes('default')))} />
              <SvgSceneIconOutdoor onClick={() => onSceneChange(scenes.find(scene => scene.slug.includes('outdoor')))} />
            </div>
          </div>
        )
      }
    </>
  );
};
