import React, { useEffect, useState } from 'react';
import './flash-messages.scss';
import { useSelector } from 'react-redux';
import { selectFlashMessageEvent } from 'redux/modules/flashMessages/flashMessagesSlice';
import { FlashMessage } from 'mod-styleguide';

const FlashMessages = () => {
  const [items, setItems] = useState([]);
  const latestMessage = useSelector(selectFlashMessageEvent);

  const addItem = (item) => {
    const newItems = items.concat(item);
    setItems(newItems);
  };

  const removeItem = (item) => {
    const index = items.findIndex((i) => i.id === item.id);
    if (index >= 0) {
      const newItems = items.slice(0);
      newItems.splice(index, 1);
      setItems(newItems);
    }
  };

  useEffect(() => {
    if (latestMessage.id) {
      addItem(latestMessage);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestMessage]);

  const renderItems = () => {
    return items.map((item) => {
      return (
        <FlashMessage
          key={item.id}
          item={item}
          onClose={removeItem}
        />
      );
    });
  };

  if (items.length < 1) return null;

  return (
    <div
      className="flash-messages-rct-component"
    >
      {renderItems()}
    </div>
  );
};

export {
  FlashMessages,
};
