import React, { useCallback } from 'react';
import { MenuProps } from 'react-select';
import { SelectInputOptionType, SelectMenu } from 'mod-styleguide';

export const CustomMenu:React.FC<MenuProps> = ({ innerProps, options, selectProps, setValue }) => {
  const { isMulti, value, selectAllLabel } = selectProps as any || {};

  const handleMenuOverflow = useCallback((ref: HTMLDivElement) => {
    if (!ref) {
      return;
    }
    const fullScreenHeight = window.innerHeight;
    const menuRef = ref.firstChild as HTMLElement;
    const boundingClientRect = menuRef.getBoundingClientRect();

    const {
      top,
      height,
    } = boundingClientRect;

    const verticalSpaceLeft = fullScreenHeight - top;
    const isTooBigToFitSpaceBelow = height > verticalSpaceLeft;
    if (isTooBigToFitSpaceBelow) {
      // flip the menu up
      menuRef.style.transform = 'translateY(calc(-100% - 28px))';
    }
  }, []);

  return (
    <div
      className='custom-menu'
      {...innerProps}
      ref={handleMenuOverflow}
    >
      <SelectMenu
        options={options as SelectInputOptionType[]}
        isMulti={isMulti}
        onChange={(selectedOption: SelectInputOptionType | SelectInputOptionType[]) => {
          setValue(selectedOption, 'select-option');
        }}
        selectedOptions={Array.isArray(value) ? value : [value]}
        selectAllLabel={selectAllLabel}
      />
    </div>
  );
};
