import { ROUTE_PATHS } from 'lib/constants';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserToken } from 'redux/modules/auth/authSlice';

interface AuthRouteProps {
  element: JSX.Element;
}

export const AuthRoute: React.FC<AuthRouteProps> = ({
  element,
}) => {
  const userToken = useSelector(selectUserToken);

  const navigate = useNavigate();

  useEffect(() => {
    if (!userToken) {
      navigate(ROUTE_PATHS.AUTHENTICATION);
    }
  }, [navigate, userToken]);

  if (!userToken) {
    return null;
  }
  return element;
};

