import { useAppDispatch } from 'lib/types/redux';
import { useEffect } from 'react';
import { useGetVehicleConfigQuery } from 'redux/modules/api';
import { selectConfiguratorVehicle } from 'redux/modules/carConfigurator/carConfiguratorSlice';

export const useVehicleFromVehicleConfigId = (vehicleConfigId: string) => {
  const dispatch = useAppDispatch();
  const vehicleConfig = useGetVehicleConfigQuery({
    id: vehicleConfigId,
  }, {
    skip: !vehicleConfigId,
  });

  useEffect(() => {
    const { data: vehicleConfigData } = vehicleConfig;
    if (!vehicleConfigData) {
      return;
    }
    dispatch(selectConfiguratorVehicle({
      vehicleModel: vehicleConfigData.vehicleModel,
      selectedOptions: vehicleConfigData.vehicleModel.selectedOptions,
      modConfigs: vehicleConfigData.modConfigs,
    }));
  }, [dispatch, vehicleConfig, vehicleConfigId]);
};
