import React from 'react';
import { Visible } from '@codeparticle/react-visible';
import './form.scss';
import {
  BaseFormState,
  ValidateFormType,
  ValidationType,
  FormFieldDef,
} from 'mod-styleguide';
import { Button } from '../button';
import { FormFieldFromDef } from './components';

// Form fields can either be passed down as children or as formFieldDefs
interface FormProps {
  autoComplete?: string;
  children?: React.ReactNode;
  formFieldDefs?: FormFieldDef[];
  formState: BaseFormState;
  onInputChange?: ({ field, value }: { field: string, value: string }) => void;
  onSubmit?: () => void;
  setFormValidation?: (validation: ValidationType) => void;
  submitLabel?: string;
  validateForm?: ValidateFormType;
  customButtonElement?: React.ReactNode;
  submitIcon?: React.ReactNode;
}

export const Form: React.FC<FormProps> = ({
  submitLabel,
  onSubmit,
  autoComplete,
  formState,
  children,
  validateForm,
  setFormValidation,
  formFieldDefs,
  onInputChange,
  customButtonElement,
  submitIcon,
}) => {
  const isDisabled = !formState.touched;
  const submitForm = (e) => {
    e.preventDefault();

    if (isDisabled) {
      return;
    }

    const validation = validateForm?.(formState);
    setFormValidation?.(validation);

    if (onSubmit && !validation?.hasErrors) {
      onSubmit();
    }
  };


  if (!formFieldDefs && !children) {
    return null;
  }

  const renderedFieldsFromDefs = formFieldDefs?.map((fieldDef) => (
    <FormFieldFromDef
      key={fieldDef.name}
      formState={formState}
      fieldDef={fieldDef}
      onInputChange={onInputChange}
    />
  ));

  return (
    <form
      className='form-rct-component'
      onSubmit={submitForm}
      autoComplete={autoComplete}
    >
      <Visible when={formState.error}>
        <div className='form-error'>{formState.error}</div>
      </Visible>
      {renderedFieldsFromDefs}
      {children}
      <Visible
        fallback={customButtonElement}
        when={!customButtonElement}
      >
        <Button
          primary
          type='submit'
          label={submitLabel}
          disabled={isDisabled}
          showDefaultIcon
          customIcon={submitIcon}
        />
      </Visible>
    </form>
  );
};

