import * as BABYLON from '@babylonjs/core';
import { PBRMetallicRoughnessBlock } from '@babylonjs/core';
import { WRAPPABLE_MESHES } from 'lib/constants';
import { SceneData } from 'lib/types';

export const applyVehicleBaseColor = async (meshes: BABYLON.Mesh[], colorMaterial: BABYLON.NodeMaterial, isOutdoorScene: SceneData) => {
  for (const mesh of meshes) {
    const coloredMesh = Object.values(WRAPPABLE_MESHES).some(wmName => mesh.name.includes(wmName));
    if (coloredMesh && colorMaterial && mesh.isEnabled) {
      // eslint-disable-next-line no-await-in-loop
      await colorMaterial.forceCompilationAsync(mesh);
      const pbrBlock = colorMaterial.getBlockByName('PBRMetallicRoughness') as PBRMetallicRoughnessBlock;
      if (isOutdoorScene) {
        pbrBlock.directIntensity = 0.5;
      }
      mesh.material = colorMaterial;
      mesh.material.freeze();
    }
  }
};
