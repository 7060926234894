import { parseFloatString } from 'mod-styleguide';

export const getConfig = (type) => {
  switch (type) {
    case 'zip':
      return ({
        type: 'number',
        maxLength: 5,
      });
    case 'text':
      return ({
        className: '',
        placeholder: '',
        type: 'text',
      });
    case 'email':
      return ({
        className: '',
        placeholder: '',
        type: 'text',
      });
    case 'password':
      return ({
        className: '',
        placeholder: '',
        type: 'password',
      });
    case 'dollar':
      return ({
        className: '',
        placeholder: '',
        type: 'text',
        pipe: (value) => {
          const float = parseFloatString(value);
          const formattedVal = float
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          return `$${formattedVal}`;
        },
      });
    default:
      return ({
        className: '',
        placeholder: '',
      });
  }
};
