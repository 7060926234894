import { snake } from 'case';
import { WRAPPABLE_MESHES } from 'lib/constants';

export const getMeshFromBodyPartSlug = (bodyPartSlug, meshes) => {
  // convert slug to snake case to match mesh name format
  const slug = snake(bodyPartSlug);
  // the body mesh is reused accross all trims so we can use the slug as is
  // other meshes will have different slugs according to the active trim, so in that case we need to extract the body part name from the slug
  const bodyPartName = slug === WRAPPABLE_MESHES.BODY ? slug : slug.split('_')[2];

  return meshes.find(mesh => mesh.isEnabled() && mesh.name.includes(bodyPartName));
};

export const getMeshesFromBodyPartSlug = (bodyPartSlug, meshes) => {
  // convert slug to snake case to match mesh name format
  const slug = snake(bodyPartSlug);
  // the body mesh slug is reused accross all trims so we can use the slug as is
  // other meshes will have different slugs according to the active trim, so in that case we need to extract the body part name from the slug
  const bodyPartName = slug === WRAPPABLE_MESHES.BODY ? slug : slug.split('_')[2];

  // return all matching meshes including disabled ones (like a hood from a inactive trim)
  return meshes.filter(mesh => mesh.name.includes(bodyPartName));
};
