import classNames from 'classnames';
import React from 'react';
import { SvgLogoSmall } from '../../assets';

import './sidebar.scss';

interface SidebarProps {
  title: string | JSX.Element;
  content?: JSX.Element;
  footer?: string | JSX.Element;
  className?: string;
}

export const Sidebar: React.FC<SidebarProps> = ({
  className,
  title,
  content,
  footer,
}) => {
  return (
    <div
      className={classNames(
        'sidebar-rct-component',
        className && className,
      )}
    >
      <SvgLogoSmall className="sidebar-rct-component__logo-svg" />
      <div className='sidebar-rct-component__middle-section'>
        <h3 className='sidebar-rct-component__middle-section__title'>
          {title}
        </h3>
        {content}
      </div>
      <footer className='sidebar-rct-component__footer'>
        {footer}
      </footer>
    </div>
  );
};

