import 'intl'; // required to use IntlProvider on android emulator, refer to: https://github.com/oursky/create-react-native-skygear/issues/26
import React from 'react';
import { RawIntlProvider } from 'react-intl';
import { intl } from 'lib/intl/intl';

const ConnectedIntlProviderContainer = (props) => {

  return (
    <RawIntlProvider value={intl}>
      {props.children}
    </RawIntlProvider>
  );
};


const ConnectedIntlProvider = ConnectedIntlProviderContainer;

export { ConnectedIntlProvider };
