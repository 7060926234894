const {
  REACT_APP_ASSETS_URL,
} = process.env;

export const ASSET_TYPES = {
  VEHICLE_3D_MODEL: 'vehicle',
  VEHICLE_3D_MODEL_COLOR: 'vehicle-color',
  VEHICLE_WRAP: 'wrap',
  ENVIRONMENT_TEXTURE: 'environment-texture',
  SCENE: 'scene',
  SKYBOX_TEXTURE: 'skybox-texture',
  INSTANCED_MESHES: 'instanced-meshes',
  WALL_TEXTURE: 'custom-wall-texture',
} as const;

type AssetType = typeof ASSET_TYPES[keyof typeof ASSET_TYPES];

const ASSET_TYPE_TO_PATH: Record<AssetType, string> = {
  'vehicle': 'vehicles',
  'vehicle-color': 'vehicles/toyota',
  'wrap': 'mods/wraps',
  'environment-texture': 'scenes/environment-textures',
  'scene': 'scenes',
  'skybox-texture': 'scenes/skybox-textures',
  'instanced-meshes': 'scenes/instanced-meshes',
  'custom-wall-texture': 'scenes/custom-wall-textures',
};

// Mount CloudFront asset path
export const mountCfAssetPath = (assetName: string, assetType: AssetType) => {
  // iPhone/iPad devices
  const appleTouchDevice = navigator.userAgent.match(/AppleWebKit/i) && navigator.maxTouchPoints > 0;

  if (appleTouchDevice) {
    // For iPhone/iPad use 1k textures version for lower memory usage
    // mobile safari crashes with multiple of 2k/4k textures
    assetName = assetName.replace('.glb', '-1k.glb');
  }

  return `${REACT_APP_ASSETS_URL}/${ASSET_TYPE_TO_PATH[assetType]}/${assetName}`;
};
