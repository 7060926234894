import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { FlashMessages } from 'containers';
import './root-router.scss';
import { history } from 'redux/store';
import { ModLoader } from '3d/mod-loader';
import { AppRoutes } from './routes';
import { useRoutes } from './hooks';

const Loading = (
  <ModLoader />
);

function renderRoute(route: AppRoutes) {
  return (
    <Route {...route} />
  );
}

const RoutesView = ({ routes }) => (
  <Suspense fallback={Loading}>
    <Routes>
      {routes.map(renderRoute)}
    </Routes>
    <FlashMessages />
  </Suspense>
);

const RootRouter: React.FC<{ isTestMode?: boolean }> = ({ isTestMode = false }) => {
  const { routes, initialized, fixedHeader } = useRoutes();

  if (!initialized) {
    return Loading;
  }

  const routesView = (
    <>
      {fixedHeader}
      <RoutesView routes={routes} />
    </>
  );
  return (
    isTestMode
      ? routesView : (
        <Router history={history}>
          {routesView}
        </Router>
      )
  );
};



export { RootRouter };
