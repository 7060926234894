import { Modal, Sidebar } from 'mod-styleguide';
import React from 'react';
import './sidebar-modal.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string | JSX.Element;
  content: JSX.Element;
  className?: string;
}

export const SidebarModal: React.FC<Props> = ({
  title,
  content,
  isOpen,
  onClose,
  className,
}) => {
  return (
    <Modal
      id='floating-sidebar-modal'
      className={className}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Sidebar
        className='floating-sidebar'
        title={title}
        content={content}
      />
    </Modal>
  );
};
