
import React, { useCallback, useEffect, useState } from 'react';
import './authentication-modal.scss';
import { Modal, Tab, TabNavigator } from 'mod-styleguide';
import { LoginForm } from 'components/login/components/login-form';
import { useSelector } from 'react-redux';
import { selectAppState } from 'redux/modules/app/appSlice';
import { USER_ROLES } from 'lib/types';
import { translate } from 'lib/utils/intl-utils';
import { ForgotPasswordForm } from 'components/forgot-password-form';
import { ModalProps } from 'mod-styleguide/src/components/modal/modal';
import { SignUpForm } from 'components/sign-up-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectUserToken } from 'redux/modules/auth/authSlice';
import { ResetPasswordForm } from 'components/reset-password-form';

const getTabs = (userRole): Tab[] => {
  return [
    {
      id: 'login',
      path: '/login',
      label: translate('common.login'),
      element: <LoginForm />,
    },
    userRole === USER_ROLES.CUSTOMER && {
      id: 'sign-up',
      label: translate('common.sign-up'),
      path: '/sign-up',
      element: <SignUpForm />,
    },
  ].filter(Boolean);
};

const additionalRoutes = [
  {
    id: 'forgot-password',
    path: '/forgot-password',
    element: <ForgotPasswordForm />,
  },
  {
    id: 'reset-password',
    path: '/reset-password',
    element: <ResetPasswordForm />,
  },
];

interface AuthenticationModalProps extends ModalProps {
  openModal?: () => void;
}

export const AuthenticationModal: React.FC<Partial<AuthenticationModalProps>> = ({
  openModal,
  ...modalProps
}) => {
  const { userRole } = useSelector(selectAppState);
  const userToken = useSelector(selectUserToken);
  const tabs = getTabs(userRole);
  const [basePath, setBasePath] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onCloseAuthenticationModal = useCallback((newBasePath = basePath) => {
    modalProps.onClose();
    navigate(newBasePath);
  }, [basePath, modalProps, navigate]);

  useEffect(() => {
    const lastPathnameRoute = pathname.split('/').pop();
    const isAtTabPath = [...tabs, ...additionalRoutes].some(({ path }) => path === `/${lastPathnameRoute}`);
    // If component has been mounted at a tab path, open modal
    if (isAtTabPath && openModal) { openModal(); }

    // if the user is already at a tab path, identify the base path for the tabs without the tab sub route
    const newBasePath = isAtTabPath ? pathname.replace(`/${lastPathnameRoute}`, '') : pathname;
    setBasePath(newBasePath);
  }, [pathname, tabs, openModal]);

  useEffect(() => {
    // if user's already logged in, close the modal
    if (userToken && onCloseAuthenticationModal && modalProps.isOpen) {
      onCloseAuthenticationModal();
    }
  }, [modalProps.isOpen, onCloseAuthenticationModal, userToken]);

  // keep it null if modalProps.onClose is falsy in order to not show the close button
  return (
    <Modal
      id="authentication-modal"
      {...modalProps}
      onClose={onCloseAuthenticationModal}
    >
      <TabNavigator
        basePath={basePath}
        defaultTabId={tabs[0]?.id}
        tabs={tabs}
        additionalRoutes={additionalRoutes}
        hideTabsWhenNoMatch
      />
    </Modal>
  );
};

