import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type { RootState } from 'lib/types/redux';

type PrepareHeadersType = (
  headers: Headers,
  { getState }: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>
) => Headers;

export const prepareHeaders: PrepareHeadersType = (
  headers, { getState },
) => {
  const { userToken } = (getState() as RootState).auth;

  if (userToken) {
    headers.set('Authorization', `Bearer ${userToken}`);
  }
  return headers;
};
