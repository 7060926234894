import React from 'react';
import { useIntl } from 'react-intl';
import { TRANSLATION_KEYS } from 'translations/keys';
import './not-found.scss';

export const NotFound = () => {
  const intl = useIntl();

  return (
    <div className="not-found-rct-component">
      {intl.formatMessage(TRANSLATION_KEYS.PAGE_NOT_FOUND)}
    </div>
  );
};
