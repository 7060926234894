import { useClickAway } from 'ahooks';
import { useEffect } from 'react';

export const useFocusAway = (callback, target: HTMLElement, condition = true) => {
  useClickAway(() => {
    if (condition) {
      callback();
    }
  }, target);

  useEffect(() => {

    window.addEventListener('scroll', callback, true);
    window.addEventListener('resize', callback, true);
    return () => {
      window.removeEventListener('scroll', callback, true);
      window.removeEventListener('resize', callback, true);
    };
  }, [callback, condition, target]);
};
