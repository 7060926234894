import React from 'react';
import './label-with-icon.scss';
import classNames from 'classnames';

export interface LabelWithIconProps {
  label?: string;
  onClick?: () => void;
  icon?: React.ReactNode;
}

export const LabelWithIcon: React.FC<LabelWithIconProps> = ({
  label = '',
  onClick,
  icon,
}) => {
  return (
    <div
      className={classNames(
        'label-with-icon-rct-component',
        onClick && 'label-with-icon-rct-component--clickable',
      )}
      onClick={onClick}
    >
      {icon}
      <span>{label}</span>
    </div>
  );
};
