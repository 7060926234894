import React from 'react';

export const Item = ({
  item,
}) => {
  return (
    <div
      key={item.id}
      className='sidebar-item'
      onClick={item.onClick}
    >
      {item.label}
    </div>
  );
};
