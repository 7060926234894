import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import './simple-car-configurator.scss';
import { parseFloatString } from 'lib/utils';
import { Button, Header, SelectInputOptionType, WrapPicker } from 'mod-styleguide';
import { SidebarConfiguratorFooter } from 'mod-styleguide/src/components/sidebar';
import { translate } from 'lib/utils/intl-utils';
import { useSelector } from 'react-redux';
import { carConfiguratorSelector } from 'redux/modules/carConfigurator/carConfiguratorSlice';
import { useMedia } from 'lib/hooks';
import { Visible } from '@codeparticle/react-visible';
import { sentence } from 'case';
import { SelectedVehicleInfo } from 'components/selected-vehicle-info';
import { useBoolean } from 'ahooks';
import classNames from 'classnames';
import { OrderFormModal } from 'components/order-form-modal';
import { CarConfiguratorHeader } from '../components/car-configurator-header';
import { useVehicleScene, useVehicleConfig, useWrapPicker } from '../hooks';
import { getItemsPerSlide } from '../config';
import { useVehicleFromQueryParams } from '../hooks/use-vehicle-from-query-params';
import { SidebarModal } from '../components/sidebar-modal';

export const SimpleCarConfigurator: React.FC = () => {
  const { vehicleModelData } = useSelector(carConfiguratorSelector);
  const [isFloatingSideBarOpen, {
    setFalse: closeFloatingSideBar,
    setTrue: openFloatingSideBar,
  }] = useBoolean(false);
  const [isOrderModalOpen, {
    setFalse: closeOrderModal,
    setTrue: openOrderModal,
  }] = useBoolean(false);
  const { formatMessage } = useIntl();
  const {
    wrapPickerData,
    wrapPickerDefaultStates,
    onApplyWrap,
    setWrapPickerDefaultStates,
    onClearWraps,
  } = useWrapPicker();
  const { isMobile, isDesktopHDOrSmaller, isTabletOrSmaller } = useMedia();
  const { localVehicleConfig, currentConfigPrice, vehicleConfigId, isValidConfigIdSelected } = useVehicleConfig();
  const scene = useVehicleScene(true);
  const { hasEnoughVehicleDataFromParams } = useVehicleFromQueryParams();
  const sidebarTitle = (
    <div className='sidebar-title'>
      {formatMessage({ id: 'car-configurator.sidebar-title' })}
    </div>
  );

  const handleOrderClick = useCallback(() => {
    window.gtag('event', 'begin_checkout', {
      currency: 'USD',
      value: currentConfigPrice,
      config_id: vehicleConfigId,
    });
    openOrderModal();
  }, [currentConfigPrice, vehicleConfigId, openOrderModal]);

  const monthlyPrice = parseFloatString(String(currentConfigPrice / 36));

  const sideBarConfiguratorFooter = (
    <SidebarConfiguratorFooter
      totalLabel={formatMessage({
        id: 'car-configurator.sidebar-total-label',
      })}
      totalValue={formatMessage({
        id: 'car-configurator.monthly-price',
      }, { price: monthlyPrice })}
      totalValueFootnote={formatMessage({
        id: 'car-configurator.full-price',
      }, { price: currentConfigPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) })}
      content={(
        <div className='footer-config-id'>
          <span className='label'>
            {formatMessage({ id: 'car-configurator.vehicle-config-id' })}
          </span>
          <span className='value'>
            {vehicleConfigId}
            <Visible when={isValidConfigIdSelected}>
              <div className='floating-order-button-container'>
                <Button
                  className='floating-order-button-container__button'
                  label='Order'
                  onClick={handleOrderClick}
                />
              </div>
            </Visible>
          </span>
        </div>
      )}
    />
  );

  const selectedVehicleInfo = (
    <SelectedVehicleInfo
      disableAutoInit={hasEnoughVehicleDataFromParams}
    />
  );

  const onMaterialListOpen = useCallback(() => {
    window.gtag('event', 'view_mod_materials_list', {
      interaction_type: 'open',
    });
  }, []);

  const onMaterialChange = useCallback((brand: SelectInputOptionType, material: SelectInputOptionType) => {
    window.gtag('event', 'select_mod_material', {
      'material_id': material.id,
      'material_name': material.label,
      'material_brand': brand.label,
    });
  }, []);

  const onTextureChange = useCallback((material: SelectInputOptionType, texture: SelectInputOptionType) => {
    window.gtag('event', 'select_mod_color', {
      'mod_color_id': texture.id,
      'mod_color_name': texture.label,
      'material_name': material.label,
    });
  }, []);


  const wrapPicker = wrapPickerDefaultStates.defaultMaterial && wrapPickerDefaultStates.defaultBrand && wrapPickerData && (
    <WrapPicker
      bodyParts={vehicleModelData.bodyParts}
      materialsGroupedOptions={wrapPickerData.materialsGroupedOptions}
      textures={wrapPickerData.textures}
      defaultBrand={wrapPickerDefaultStates.defaultBrand}
      defaultMaterial={wrapPickerDefaultStates.defaultMaterial}
      defaultTexture={wrapPickerDefaultStates.defaultTexture}
      setDefaultPickerData={setWrapPickerDefaultStates}
      activeModConfigs={localVehicleConfig.modConfigs}
      labels={{
        materialLabel: translate('common.material'),
        textureLabel: sentence(translate('common.color')),
        headerTitle: translate('car-configurator.vehicle-area'),
        applyButtonLabel: translate('common.apply'),
        // bodyPartLabel: translate('common.location'),
        // selectAllBodyPartsLabel: translate('car-configurator.full-body'),
      }}
      onApply={onApplyWrap}
      carouselSlidesToScroll={getItemsPerSlide(isTabletOrSmaller, isMobile)}
      isMobile={isMobile}
      onClearWraps={onClearWraps}
      onMaterialListOpen={onMaterialListOpen}
      onTextureChange={onTextureChange}
      onMaterialChange={onMaterialChange}
    />
  );

  return (
    <div className="simple-car-configurator-rct-component">
      <div className='simple-car-configurator-rct-component__main-content'>
        <Header
          transparent
          customContent={
            <CarConfiguratorHeader
              wrapPicker={!isMobile && wrapPicker}
              onHamburgerClick={isMobile && openFloatingSideBar}
              disableActions
            />
          }
          hideLogo={isDesktopHDOrSmaller}
        />
        <div
          className='simple-car-configurator-rct-component__scene-container'
        >
          <Visible when={!isMobile}>
            {selectedVehicleInfo}
          </Visible>
          <Visible when={isMobile}>
            <div className='floating-wrap-picker-container'>
              {wrapPicker}
            </div>
          </Visible>
          {scene}
        </div>
        <Visible when={isMobile}>
          <SidebarModal
            // keep it always open and toggle visibility with css
            // reason is to make sure the model can be auto initialized by the selected info component
            isOpen
            className={classNames(
              !isFloatingSideBarOpen && 'display-hidden',
              'simple-car-configurator-floating-sidebar',
            )}
            onClose={closeFloatingSideBar}
            title={sidebarTitle}
            content={selectedVehicleInfo}
          />
        </Visible>
        {sideBarConfiguratorFooter}
        <OrderFormModal
          isOpen={isOrderModalOpen}
          onClose={closeOrderModal}
        />
      </div>
    </div>
  );
};

