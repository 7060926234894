import { FORM_ROW_HALF_WIDTH } from 'lib/constants';
import { translate } from 'lib/utils/intl-utils';
import { FORM_FIELD_INPUT_TYPES, FormFieldDef } from 'mod-styleguide';

export const getFormFieldDefs = ({
  years,
  makes,
  models,
  trims,
  colors,
}): FormFieldDef[] => ([
  {
    name: 'year',
    valueKeyPath: 'year.value',
    label: translate('car-configurator.select-year'),
    inputType: FORM_FIELD_INPUT_TYPES.SELECT,
    width: FORM_ROW_HALF_WIDTH,
    options: years,
    onOpen: () => {
      window.gtag('event', 'view_vehicle_options_list', {
        'option_list_name': 'year',
      });
    },
    onSelect: () => {
      window.gtag('event', 'select_vehicle_option_item', {
        'option_list_name': 'year',
      });
    },
  },
  {
    name: 'make',
    valueKeyPath: 'make.value',
    label: translate('car-configurator.select-make'),
    inputType: FORM_FIELD_INPUT_TYPES.SELECT,
    width: FORM_ROW_HALF_WIDTH,
    options: makes,
    onOpen: () => {
      window.gtag('event', 'view_vehicle_options_list', {
        'option_list_name': 'make',
      });
    },
    onSelect: () => {
      window.gtag('event', 'select_vehicle_option_item', {
        'option_list_name': 'make',
      });
    },
  },
  {
    name: 'model',
    valueKeyPath: 'model.value',
    label: translate('car-configurator.select-model'),
    inputType: FORM_FIELD_INPUT_TYPES.SELECT,
    options: models,
    onOpen: () => {
      window.gtag('event', 'view_vehicle_options_list', {
        'option_list_name': 'model',
      });
    },
    onSelect: () => {
      window.gtag('event', 'select_vehicle_option_item', {
        'option_list_name': 'model',
      });
    },
  },
  {
    name: 'trim',
    valueKeyPath: 'trim.value',
    label: translate('car-configurator.select-trim'),
    inputType: FORM_FIELD_INPUT_TYPES.SELECT,
    options: trims,
    onOpen: () => {
      window.gtag('event', 'view_vehicle_options_list', {
        'option_list_name': 'trim',
      });
    },
    onSelect: () => {
      window.gtag('event', 'select_vehicle_option_item', {
        'option_list_name': 'trim',
      });
    },
  },
  {
    name: 'color',
    valueKeyPath: 'color.value',
    label: translate('car-configurator.select-color'),
    inputType: FORM_FIELD_INPUT_TYPES.SELECT,
    options: colors,
    onOpen: () => {
      window.gtag('event', 'view_vehicle_options_list', {
        'option_list_name': 'color',
      });
    },
    onSelect: () => {
      window.gtag('event', 'select_vehicle_option_item', {
        'option_list_name': 'color',
      });
    },
  },
]);
