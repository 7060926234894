import { useSetState } from 'ahooks';
import { omit, set } from 'lodash';
import { Button, Form, Modal, SvgClose, SvgSuccess } from 'mod-styleguide';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import './create-order-modal.scss';

interface CreateOrderModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onCreate?: (formState) => void;
}

export const CreateOrderModal: React.FC<CreateOrderModalProps> = ({
  isOpen,
  onClose,
  onCreate,
}) => {
  const [formState, setFormState] = useSetState({ touched: false });
  const { formatMessage } = useIntl();

  const onSubmit = useCallback(() => {
    onCreate(omit(formState, ['touched']));
    onClose();
  }, [formState, onCreate, onClose]);

  return (
    <Modal
      id="create-order-modal"
      isOpen={isOpen}
      title="Create order"
    >
      <>
        <Form
          onSubmit={onSubmit}
          formState={formState}
          onInputChange={({ value, field }) => {
            const newState = set(formState, field, value);
            setFormState({
              ...newState,
              touched: true,
            });
          }}
          submitIcon={<SvgSuccess />}
          submitLabel={formatMessage({
            id: 'common.create',
          })}
          formFieldDefs={[
            {
              name: 'customerInfo.firstName',
              label: formatMessage({
                id: 'manage-jobs.customer-first-name',
              }),
              type: 'text',
            },
            {
              name: 'customerInfo.lastName',
              label: formatMessage({
                id: 'manage-jobs.customer-last-name',
              }),
              type: 'text',
            },
            {
              name: 'vehicleConfigId',
              label: formatMessage({
                id: 'car-configurator.vehicle-config-id',
              }),
              type: 'number',
            },
            {
              name: 'stockNumber',
              label: formatMessage({
                id: 'manage-jobs.stock-number',
              }),
              type: 'text',
            },
            {
              name: 'vinNumber',
              label: formatMessage({
                id: 'manage-jobs.vin',
              }),
            },
          ]}
        />
        <Button
          className='cancel-button'
          backgroundColor='#353535'
          onClick={onClose}
          label={formatMessage({
            id: 'common.cancel',
          })}
          width="100%"
          color='white'
          customIcon={<SvgClose />}
        />
      </>
    </Modal>
  );
};
