/* eslint-disable jsx-a11y/label-has-for */
import { useBoolean, useSetState } from 'ahooks';
import classNames from 'classnames';
import { property } from 'lodash';
import React, { Fragment, useCallback, useState } from 'react';
import { AddressInput, FieldDef, INFO_FIELD_WIDTH } from '../../..';
import { SvgClose, SvgSuccess } from '../../assets';
import { Button } from '../button';
import { Modal } from '../modal';
import { TextInput } from '../text-input';
import './info-fields.scss';

export const INFO_FIELD_TYPES = {
  TEXT: 'text',
  ADDRESS: 'address',
};

interface Props {
  fields: FieldDef[],
  data: any,
  labels?: {
    modify: string,
    save: string,
    cancel: string,
  }
  onSaveFields?: (field: FieldDef[]) => void,
  footer?: React.ReactNode,
}


const INITIAL_FIELD_STATE: FieldDef = {
  name: '',
  label: '',
  value: '',
};
export const InfoFields: React.FC<Props> = ({
  fields,
  data,
  labels = {
    modify: 'Modify',
    save: 'Save',
    cancel: 'Cancel',
  },
  onSaveFields,
  footer,
}) => {
  const [isModalOpen, {
    setFalse: closeModal,
    setTrue: openModal,
  }] = useBoolean();
  const [currentField, setCurrentField] = useSetState<FieldDef>(INITIAL_FIELD_STATE);
  const [fieldsToUpdate, setFieldsToUpdate] = useState<FieldDef[]>([]);

  const handleAddressSelection = useCallback((e) => {
    const { address, longitude, latitude, zip } = e;

    setFieldsToUpdate([
      {
        name: 'address',
        value: address,
      },
      {
        name: 'longitude',
        value: longitude,
      },
      {
        name: 'latitude',
        value: latitude,
      },
      {
        name: 'zip',
        value: zip,
      },
    ]);
  }, []);

  const handlelInputChange = useCallback((e) => {
    setCurrentField({
      value: e.value,
    });
  }, [setCurrentField]);

  const handleEditClick = useCallback((field: FieldDef) => {
    setCurrentField({
      ...field,
      value: data[field.name],
    });
    openModal();
  }, [data, openModal, setCurrentField]);

  const handleModalClose = useCallback(() => {
    setFieldsToUpdate([]);
    setCurrentField(INITIAL_FIELD_STATE);
    closeModal();
  }, [closeModal, setCurrentField]);

  const handleSave = useCallback(() => {
    if (fieldsToUpdate.length) {
      onSaveFields(fieldsToUpdate);
    } else {
      onSaveFields([currentField]);
    }

    handleModalClose();
  }, [fieldsToUpdate, handleModalClose, onSaveFields, currentField]);

  const renderFields = useCallback((fieldsToRender: FieldDef[]) => (
    fieldsToRender?.map((field, index) => {
      const { name, label, getValue, editable, type, disableInfoMode, width, ...htmlInputProps } = field;

      const value = getValue ? getValue(data) : property<string, any>(name)(data) || '';
      return (
        <Fragment key={index}>
          {
            field.element ?? (Boolean((value || editable))
              && <TextInput
                {...htmlInputProps}
                infoMode={!disableInfoMode}
                value={value}
                label={label}
                onEditClick={editable ? () => handleEditClick(field) : undefined}
                type={type}
                width={width || INFO_FIELD_WIDTH.FULL}
              />
            )
          }
        </Fragment>
      );
    })
  ), [data, handleEditClick]);

  if (!fields) {
    return null;
  }

  return (
    <div
      className={classNames(
        'info-fields-rct-component',
      )}
    >
      <Modal
        id="info-fields-edit-modal"
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title={`${labels.modify} ${currentField.label}`}
      >
        <div className='info-fields-edit-modal-content'>
          {
            currentField.type === INFO_FIELD_TYPES.ADDRESS
              ? (
                <AddressInput
                  onSelect={handleAddressSelection}
                  onChange={handlelInputChange}
                  name={currentField.name}
                  value={currentField.value}
                  label={currentField.label}
                />
              )
              : <TextInput
                  onChange={handlelInputChange}
                  name={currentField.name}
                  value={currentField.value}
                  label={currentField.label}
              />
}
          <div className='info-fields-edit-modal-content__buttons'>
            <Button
              label={labels.save}
              primary
              width='100%'
              onClick={handleSave}
              customIcon={<SvgSuccess />}
            />
            <Button
              label={labels.cancel}
              width='100%'
              onClick={handleModalClose}
              customIcon={<SvgClose />}
            />
          </div>
        </div>
      </Modal>
      <div className='info-fields-rct-component__content'>
        {renderFields(fields)}
      </div>
      {footer && (
        <div className='info-fields-rct-component__footer'>
          {footer}
        </div>
      )}
    </div>
  );
};
