import { VALIDATION_RULES } from 'lib/utils';
import { Form, BaseFormState } from 'mod-styleguide';
import React from 'react';
import { validateObject } from '@codeparticle/formal';
import { useSelector } from 'react-redux';
import { onLoginFormChange, setLoginFormValidation } from 'redux/modules/forms/login/loginSlice';
import { useAppDispatch } from 'lib/types/redux';
import { login } from 'redux/thunks/auth';
import './login-form.scss';
import { useNavigate } from 'react-router-dom';
import { loginFormState } from 'redux/modules/forms';
import { translate } from 'lib/utils/intl-utils';
import { StartModdingCta } from 'components/start-modding-cta';
import { ROUTE_PATHS } from 'lib/constants';
import { selectAppUserRole } from 'redux/modules/app/appSlice';
import { USER_ROLES } from 'lib/types';

const validateForm = validateObject({
  email: [VALIDATION_RULES.EMAIL],
  password: [VALIDATION_RULES.PASSWORD],
});

type LoginFormState = BaseFormState & {
  email: string,
  password: string,
};

export const LoginForm = () => {
  const loginLabel = translate('common.login');
  const formState: LoginFormState = useSelector(loginFormState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userRole = useSelector(selectAppUserRole);

  const onSubmit = () => {
    const validation = validateForm(formState);
    dispatch(setLoginFormValidation(validation));

    if (!validation?.hasErrors) {
      dispatch(login(formState));
    }
  };

  const onInputChange = (e) => {
    dispatch(onLoginFormChange(e));
  };

  const onForgotPasswordClick = () => {
    navigate(ROUTE_PATHS.FORGOT_PASSWORD);
  };

  return (
    <div className='form-section'>
      <Form
        submitLabel={loginLabel}
        onSubmit={onSubmit}
        formState={formState}
        autoComplete='on'
        formFieldDefs={[
          {
            name: 'email',
            autoComplete: 'email',
            label: translate('common.email-address'),
            placeholder: translate('form.email-placeholder'),
          },
          {
            name: 'password',
            autoComplete: 'current-password',
            label: translate('common.password'),
            placeholder: translate('form.password-placeholder'),
          },
        ]}
        validateForm={validateForm}
        setFormValidation={(v) => dispatch(setLoginFormValidation(v))}
        onInputChange={onInputChange}
      />
      <span
        className='forgot-pw cool-blue'
        onClick={onForgotPasswordClick}
      >
        {translate('common.forgot-password')}
      </span>
      {userRole === USER_ROLES.CUSTOMER && <StartModdingCta />}
    </div>
  );
};
