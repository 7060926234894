import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import { SvgChevronDown } from 'mod-styleguide';

export const CustomDropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator className='dropdown-indicator' {...props}>
      <SvgChevronDown className='custom-dropdown-icon' />
    </components.DropdownIndicator>
  );
};
