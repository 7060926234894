import { defineMessages } from 'react-intl';

export const FORM = defineMessages({
  ERROR_EMAIL_ADDRESS: {
    id: 'form.error-email-address',
    description: 'Error text displayed when an invalid email address is entered',
    defaultMessage: 'Please input a valid email address',
  },
  ERROR_PASSWORD: {
    id: 'form.error-password',
    description: 'Error text displayed when an invalid password is entered',
    defaultMessage: 'Please input a valid password',
  },
  ERROR_PASSWORD_LENGTH: {
    id: 'form.error-password-length',
    description: 'Error text displayed when a password length is invalid',
    defaultMessage: 'Password must be {isMin, select, true {at least} false {less than}} {count} characters',
  },
  ERROR_MAX_CHARACTERS: {
    id: 'form.error-max-characters',
    description: 'Error text displayed when text input has surpassed the maximum amount of characters',
    defaultMessage: 'Maximum allowed characters is {max}.',
  },
  ERROR_VALUE_REQUIRED: {
    id: 'form.error-value-required',
    description: 'Error text displayed when an input value is required',
    defaultMessage: 'Value is required',
  },
  EMAIL_PLACEHOLDER: {
    id: 'form.email-placeholder',
    description: 'Email placeholder text',
    defaultMessage: 'Enter your email address',
  },
  ERROR_ZIP_CODE: {
    id: 'form.error-zip-code',
    description: 'Error text displayed when an invalid zipcode is entered',
    defaultMessage: 'Please enter a 5 character zipcode',
  },
  ERROR_PASSWORDS_DO_NOT_MATCH: {
    id: 'form.error-passwords-do-not-match',
    description: 'Error text displayed when passwords do not match',
    defaultMessage: 'Passwords do not match',
  },
  PASSWORD_PLACEHOLDER: {
    id: 'form.password-placeholder',
    description: 'Placeholder for a password field',
    defaultMessage: 'Enter your password',
  },
  WRONG_PASSWORD: {
    id: 'form.wrong-password',
    description: 'Text displayed to the user when they enter the wrong password',
    defaultMessage: 'Wrong password!',
  },
  FIRST_NAME_PLACEHOLDER: {
    id: 'form.first-name-placeholder',
    description: 'Placeholder for a first name field',
    defaultMessage: 'Enter your first name',
  },
  LAST_NAME_PLACEHOLDER: {
    id: 'form.last-name-placeholder',
    description: 'Placeholder for a last name field',
    defaultMessage: 'Enter your last name',
  },
  NAME_PLACEHOLDER: {
    id: 'form.name-placeholder',
    description: 'Placeholder for a name field',
    defaultMessage: 'Enter your name',
  },
  PHONE_NUMBER_PLACEHOLDER: {
    id: 'form.phone-number-placeholder',
    description: 'Placeholder for a phone number field',
    defaultMessage: 'Enter your phone number',
  },
  ADDRESS_PLACEHOLDER: {
    id: 'form.address-placeholder',
    description: 'Placeholder for an address field',
    defaultMessage: 'Enter your address',
  },
  CONFIRM_PASSWORD_PLACEHOLDER: {
    id: 'form.confirm-password-placeholder',
    description: 'Placeholder for a confirm password field',
    defaultMessage: 'Confirm your password',
  },
});
