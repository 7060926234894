import * as BABYLON from '@babylonjs/core';
import { ASSET_TYPES, mountCfAssetPath } from 'lib/utils';

export const setupOutdoorSkybox = (scene: BABYLON.Scene, sceneData) => {
  const skyboxTexture = new BABYLON.CubeTexture(mountCfAssetPath(`${sceneData.slug}.env`, ASSET_TYPES.SKYBOX_TEXTURE), scene);
  skyboxTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;

  const envHelper = new BABYLON.EnvironmentHelper({
    skyboxTexture,
    createGround: false,
    skyboxColor: new BABYLON.Color3(1, 0.96, 0.76),
  }, scene);

  envHelper.skybox.infiniteDistance = true;

  return envHelper.skybox;
};
