import { Form, BackButton } from 'mod-styleguide';
import React from 'react';
import { translate } from 'lib/utils/intl-utils';
import './forgot-password-form.scss';
import { useAppDispatch, useAppSelector } from 'lib/types/redux';
import { forgotPasswordFormState } from 'redux/modules/forms';
import { onForgotPasswordFormChange, setForgotPasswordFormValidation } from 'redux/modules/forms/forgotPassword/forgotPasswordSlice';
import { validateObject } from '@codeparticle/formal';
import { VALIDATION_RULES } from 'lib/utils';
import { forgotPassword } from 'redux/thunks/auth';

const validateForm = validateObject({
  email: [VALIDATION_RULES.EMAIL],
});
interface ForgotPasswordFormProps {
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = () => {
  const formState = useAppSelector(forgotPasswordFormState);
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    dispatch(forgotPassword(formState));
  };

  const onInputChange = (e) => {
    dispatch(onForgotPasswordFormChange(e));
  };

  return (
    <div className="forgot-password-form-rct-component">
      <BackButton label={translate('forgot-password.back-to-login')?.toUpperCase()} />
      <h1>{translate('common.forgot-password-title')}</h1>
      <span className='description'>{translate('forgot-password.description')}</span>
      <Form
        submitLabel={translate('forgot-password.reset-password')}
        formState={formState}
        autoComplete="on"
        onSubmit={onSubmit}
        validateForm={validateForm}
        setFormValidation={(v) => dispatch(setForgotPasswordFormValidation(v))}
        onInputChange={onInputChange}
        formFieldDefs={[
          {
            name: 'email',
            autoComplete: 'email',
            label: translate('common.email-address'),
            placeholder: translate('form.email-placeholder'),
          },
        ]}
      />
    </div>
  );
};

