import { useClickAway } from 'ahooks';
import React from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
} from 'use-places-autocomplete';
import { TextInput } from '../text-input';
import { TextInputProps } from '../text-input/text-input';
import './address-input.scss';

export type AddressInputProps = TextInputProps & {
  defaultValue?: string;
  onSelect?: (address) => void;
};

const reversePlaceGeocode = async (place) => {
  const { description, place_id: placeId } = place;
  const geocode = (await getGeocode({ placeId }))[0];
  const zipCode = getZipCode(geocode, false);
  const { lat: latitude, lng: longitude } = getLatLng(geocode);

  return {
    address: description || geocode.formatted_address,
    zipCode,
    latitude,
    longitude,
  };
};

export const AddressInput: React.FC<AddressInputProps> = ({
  defaultValue,
  onChange,
  onSelect,
  ...textInputProps
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    defaultValue,
    debounce: 300,
  });

  const ref = React.useRef(null);

  useClickAway(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  }, ref);

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.value);
    onChange?.(e);
  };

  const handleSelect = (place) => async () => {
    const { description } = place;
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    const selectedAddress = await reversePlaceGeocode(place);
    onSelect?.(selectedAddress);
  };

  const renderSuggestions = () => data.map((suggestion) => {
    const {
      place_id: placeId,
      structured_formatting: { main_text: mainText, secondary_text: secondaryText },
    } = suggestion;

    return (
      <li key={placeId} onClick={handleSelect(suggestion)}>
        <strong>{mainText}</strong> <small>{secondaryText}</small>
      </li>
    );
  });

  return (
    <div className='address-input-rct-component' ref={ref}>
      <TextInput
        value={value}
        onChange={handleInput}
        disabled={!ready}
        {...textInputProps}
      />
      <div className='address-input-rct-component__suggestions-container'>
        {status === 'OK' && <ul>{renderSuggestions()}</ul>}
      </div>
    </div>
  );
};

