import React, { useCallback, useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { Visible } from '@codeparticle/react-visible';
import './select-input.scss';
import type { SelectInputOptionType } from 'mod-styleguide';
import classNames from 'classnames';
import { customStyles } from './config';
import { CustomDropdownIndicator, CustomPlaceholder, CustomSingleValue } from './components';
import { CustomMenu } from './components/custom-menu';

export interface SelectInputProps {
  label?: string;
  options?: Array<SelectInputOptionType>;
  placeholder?: string | JSX.Element | number;
  innerLabelPrefix?: string | JSX.Element;
  getOptionLabel?: ([string]: any) => any;
  // When input has grouped options (i.e. options with sub-options),
  // the onChange event value will be an array of selected options: [parentOption, subOption]
  onChange?: (value: SelectInputOptionType | SelectInputOptionType[]) => void;
  // secondary design - check out storybook example
  secondary?: boolean;
  value?: SelectInputOptionType['value'];
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  controlShouldRenderValue?: boolean;
  selectAllLabel?: string;
  onOpen?: () => void;
  onSelect?: (value: SelectInputOptionType) => void;
}

const getSelectedOptionByValue = (options: SelectInputOptionType[], value: SelectInputOptionType['value']) => {
  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    if (option.options) {
      const subOption = option.options.find(currentSubOption => currentSubOption.value === value);
      if (subOption) {
        // try to return a found subOption first
        return subOption;
      }
    }
    // if no subOption is found, try to return a found option
    if (option.value === value) {
      return option;
    }
  }

  // if no option is found, return a custom option from value string
  const customSelectedOption = { id: value, value, label: value };
  return customSelectedOption;
};

export const SelectInput: React.FC<SelectInputProps> = ({
  options,
  placeholder = 'Select...',
  innerLabelPrefix,
  label,
  getOptionLabel,
  onChange,
  secondary,
  value,
  isMulti,
  closeMenuOnSelect,
  controlShouldRenderValue,
  selectAllLabel,
  onOpen,
  onSelect,
}) => {
  const labelPrefix = (
    <Visible when={innerLabelPrefix}>
      <span className='inner-label-prefix'>
        {innerLabelPrefix}
      </span>
    </Visible>
  );
  const [selectedOption, setSelectedOption] = useState(null);

  const onInputChange = useCallback((selection: SelectInputOptionType | SelectInputOptionType[]) => {
    if (Array.isArray(selection)) {
      const singleSelection = selection[selection.length - 1];
      setSelectedOption(singleSelection);
      onSelect?.(singleSelection);
    } else {
      onSelect?.(selection);
    }
    onChange(selection);
  }, [onChange]);

  useEffect(() => {
    if (value && options) {
      setSelectedOption(getSelectedOptionByValue(options, value));
    }
  }, [options, selectAllLabel, value]);

  return (
    <div
      className={classNames(
        'rounded-select-input-rct-component',
        secondary && 'rounded-select-input-rct-component--secondary',
      )}
      data-testid="rounded-select-input"
      onClick={e => e.stopPropagation()}
    >
      {label && <span className='input-label'>{label}</span>}
      <ReactSelect
        value={selectedOption}
        components={{
          DropdownIndicator: CustomDropdownIndicator,
          Placeholder: CustomPlaceholder,
          SingleValue: CustomSingleValue,
          Menu: CustomMenu,
        }}
        styles={customStyles}
        options={options || []}
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        onChange={onInputChange}
        isSearchable={false}
        hideSelectedOptions={false}
        isMulti={isMulti}
        onMenuOpen={onOpen}
        closeMenuOnSelect={closeMenuOnSelect || !isMulti}
        controlShouldRenderValue={controlShouldRenderValue || !isMulti}
        // @ts-ignore - custom props accesible with selectProps
        labelPrefix={labelPrefix}
        selectAllLabel={selectAllLabel}
        menuPlacement="auto"
      />
    </div>
  );
};

