import { useAppSelector } from 'lib/types/redux';
import { FieldDef, InfoFields, INFO_FIELD_WIDTH, SidePanel, SvgExternalLink } from 'mod-styleguide';
import React from 'react';
import { useIntl } from 'react-intl';
import { selectViewerLink } from 'redux/modules/app/appSlice';

interface OrderDetailsPanelProps {
  isOpen: boolean;
  data: any;
  onClose: () => void;
}

const getContentFields = (formatMessage, viewerLink):FieldDef[] => [
  {
    name: 'customer.name',
    label: formatMessage({
      id: 'common.customer',
    }),
    width: INFO_FIELD_WIDTH.HALF,
  },
  {
    name: 'customer.email',
    label: formatMessage({
      id: 'common.email-address',
    }),
    width: INFO_FIELD_WIDTH.HALF,
  },
  {
    name: 'installer.name',
    label: formatMessage({
      id: 'common.installer',
    }),
    width: INFO_FIELD_WIDTH.HALF,
  },
  {
    name: 'installer.email',
    label: formatMessage({
      id: 'common.installer-contact',
    }),
    width: INFO_FIELD_WIDTH.HALF,
  },
  {
    name: 'totalCost',
    label: formatMessage({
      id: 'common.total-cost',
    }),
    width: INFO_FIELD_WIDTH.HALF,
  },
  {
    name: 'status',
    label: formatMessage({
      id: 'common.status',
    }),
    width: INFO_FIELD_WIDTH.HALF,
  },
  {
    name: 'details',
    label: formatMessage({
      id: 'common.vehicle',
    }),
    width: INFO_FIELD_WIDTH.HALF,
  },
  {
    name: 'viewerLink',
    label: formatMessage({
      id: 'common.configurator-link',
    }),
    getValue: (data) => (
      <a
        href={data.vehicleConfigId ? `${viewerLink}/${data.vehicleConfigId}` : undefined}
        style={{
          fontSize: '20px',
          lineHeight: '32px',
          color: '#BEE5FF',
          textDecoration: 'none',
        }}
      >
        {formatMessage({ id: 'common.view-build' })} <SvgExternalLink width="16" height="16" />
      </a>
    ),
    width: INFO_FIELD_WIDTH.HALF,
  },
];

const getFooterFields = (formatMessage):FieldDef[] => [
  {
    name: 'configuration.body',
    label: formatMessage({
      id: 'common.body',
    }),
    width: INFO_FIELD_WIDTH.FULL,
  },
  {
    name: 'configuration.hood',
    label: formatMessage({
      id: 'common.hood',
    }),
    width: INFO_FIELD_WIDTH.FULL,
  },
  {
    name: 'configuration.tailgate',
    label: formatMessage({
      id: 'common.tailgate',
    }),
    width: INFO_FIELD_WIDTH.FULL,
  },
];

export const OrderDetailsPanel: React.FC<OrderDetailsPanelProps> = ({
  isOpen,
  data,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const viewerLink = useAppSelector(selectViewerLink);

  return (
    <SidePanel
      isOpen={isOpen}
      title={formatMessage({
        id: 'manage-jobs.project-details-with-job-id',
      }, {
        jobId: data?.id,
      })}
      content={
        <InfoFields
          data={data}
          fields={getContentFields(formatMessage, viewerLink)}
        />
      }
      footerTitle={formatMessage({
        id: 'manage-jobs.configuration-build',
      })}
      footer={
        <InfoFields
          data={data}
          fields={getFooterFields(formatMessage)}
        />
      }
      onClose={onClose}
    />

  );
};

