import React from 'react';
import { SidebarItem } from '../../../../..';
import { Item } from './item';
import { SubItem } from './sub-item';
import './sidebar-configurator-items.scss';

type Props = {
  items?: SidebarItem[];
};

export const SidebarConfiguratorItems:React.FC<Props> = ({
  items,
}) => {
  return (
    <div className='sidebar-configurator-items'>
      {items?.map(item => {
        return (
          <div key={item.id} className='sidebar-configurator-items__item-container'>
            <Item
              item={item}
            />
            {item.subItems?.map(subItem => (
              <SubItem
                key={subItem.id}
                subItem={subItem}
                onRemove={item.onRemoveSubItem}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};
