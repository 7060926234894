import { createAsyncThunk } from '@reduxjs/toolkit';
import { strapiApi } from 'lib/api';
import { STRAPI_API } from 'lib/constants';
import { storeInLocalStorage } from 'lib/utils';
import { RootState } from '../../../lib/types/redux';

type LoginResponse = {
  jwt: string;
  user: {
    id: number,
    username: string,
    email: string,
    blocked: false,
    createdAt: string,
    updatedAt: string,
  },
  profile: {
    id: number,
    phone: string,
    latitude: number,
    longitude: number,
    firstName: string,
    lastName: string,
    address: string,
    zipcode: number,
    name: string
    active: boolean,
  }
};

export const login = createAsyncThunk<LoginResponse, {
  email: string, password: string
}>(
  'user/login',
  async (
    {
      email, password,
    },
    { getState }) => {

    const { app } = getState() as RootState;
    const { subdomain: scope } = app;

    const { data } = await strapiApi({
      method: 'post',
      url: STRAPI_API.AUTH_LOCAL,
      data: {
        identifier: email,
        password,
        scope,
      },
    });

    if (data.jwt) {
      // store user's token in local storage
      storeInLocalStorage('userToken', data.jwt);
    }

    // Need to decide if it's worth to add this
    //
    // if (!data.profile.hasSignedUpStripe) {
    //
    //   try {
    //     const { data: account } = await strapiApi.post(
    //       STRAPI_API.STRIPE_ACCOUNTS,
    //     );
    //
    //     const linkUrl = `${window.location.href}${ROUTE_PATHS.PROFILE}`;
    //     const { data: accountLink } = await strapiApi.post(
    //       STRAPI_API.STRIPE_ACCOUNT_LINKS,
    //       {
    //         accountId: account.id,
    //         refreshUrl: linkUrl,
    //         returnUrl: `${linkUrl}/account_id=${account.id}`,
    //         type: 'account_onboarding',
    //       },
    //     );
    //     window.open(accountLink.url, '_blank');
    //   } catch (e) {
    //     // Exception is being swallowed for now to prevent user from login.
    //     // TODO add proper handling when decision is made.
    //   }
    // }

    return data;
  },
);
