import React from 'react';
import { createPortal } from 'react-dom';
import { SvgClose } from 'mod-styleguide';
import { Visible } from '@codeparticle/react-visible';
import classNames from 'classnames';
import { useModal } from './hooks';
import './modal.scss';

export interface ModalProps {
  children?: React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  id?: string;
  title?: string;
  disableCloseButton?: boolean;
  className?: string;
  subTitle?: string;
}

export const Modal: React.FC<ModalProps> = ({
  id,
  children,
  isOpen,
  onClose,
  title,
  subTitle,
  disableCloseButton,
  className,
}) => {
  const {
    modalRoot,
  } = useModal(id);

  if (!isOpen || !modalRoot) {
    return null;
  }

  const render = (
    <div className={classNames('modal-rct-component', className)}>
      <div className='modal-rct-component__overlay'>
        <Visible when={!!onClose && !disableCloseButton}>
          <div
            data-testid='close-modal'
            className='close-button'
            onClick={() => onClose()}
          >
            <SvgClose color="white" />
          </div>
        </Visible>
        <h1 className='modal-rct-component__title'>
          {title}
        </h1>
        <p className='modal-rct-component__sub-title'>
          {subTitle}
        </p>
        <div
          className='modal-rct-component__content'
          data-testid='modal-content'
        >
          {children}
        </div>
      </div>
    </div>
  );

  return createPortal(
    render,
    modalRoot,
  );
};
