import { useEffect, useState } from 'react';
import { v1 as uuid } from 'uuid';

const createModalContainer = (id) => {
  const modalContainer = document.createElement('div');
  modalContainer.setAttribute('id', id ?? `modal-root-${uuid()}`);

  return modalContainer;
};

export const useModal = (id) => {
  const [modalRoot, setModalRoot] = useState<HTMLDivElement>(null);

  useEffect(() => {
    const newModalRoot = createModalContainer(id);

    setModalRoot(newModalRoot);
    const webAppContainer = document.getElementById('mod-web-container');
    if (webAppContainer) {
      webAppContainer.appendChild(newModalRoot);
    } else {
      document.body.appendChild(newModalRoot);
    }

    return () => {
      newModalRoot.remove();
    };
  }, [id]);

  return { modalRoot };
};
