/* eslint-disable import/no-named-default */
import { createRule, isNonEmptyString } from '@codeparticle/formal';
import { translate } from './intl-utils';
import isValidEmail from './is-valid-email';
/**
 * Password regex for validation
 * [a-zA-Z0-9!@#$%^&*]{8,} // should contain at least 8 from the mentioned characters
 */
const passwordRegex = /^[a-zA-Z0-9!@#$%^&*]{8,}$/;

export const isValidPasswordRule = () => createRule({
  condition: (str) => str.trim().match(passwordRegex),
  message: () => translate('form.error-password'),
});

export const isValidEmailRule = () => createRule({
  condition: (str) => isValidEmail(str),
  message: translate('form.error-email-address'),
});


export const VALIDATION_RULES = {
  EMAIL: isValidEmailRule,
  PASSWORD: isValidPasswordRule,
  REQUIRED_STRING: isNonEmptyString,
};
