import { defineMessages } from 'react-intl';

export const CAR_CONFIGURATOR = defineMessages({
  SELECT_A_VEHICLE: {
    defaultMessage: 'Select a vehicle to get started...',
    id: 'car-configurator.select-a-vehicle',
  },
  CHOOSE_YOUR_VEHICLE: {
    defaultMessage: 'Choose your vehicle',
    id: 'car-configurator.choose-your-vehicle',
  },
  CONFIGURE_BUILD: {
    defaultMessage: 'Configure build',
    id: 'car-configurator.configure-build',
  },
  BUILD_NAME: {
    defaultMessage: 'Build name',
    id: 'car-configurator.build-name',
  },
  SAVE_BUILD: {
    defaultMessage: 'Save build',
    id: 'car-configurator.save-build',
  },
  SELECT_YEAR: {
    defaultMessage: 'Select a Year',
    id: 'car-configurator.select-year',
  },
  SELECT_MAKE: {
    defaultMessage: 'Select a Make',
    id: 'car-configurator.select-make',
  },
  SELECT_MODEL: {
    defaultMessage: 'Select a Model',
    id: 'car-configurator.select-model',
  },
  SELECT_TRIM: {
    defaultMessage: 'Select a Trim',
    id: 'car-configurator.select-trim',
  },
  SELECT_COLOR: {
    defaultMessage: 'Select a Color',
    id: 'car-configurator.select-color',
  },
  SIDEBAR_TITLE: {
    defaultMessage: 'Configurator',
    id: 'car-configurator.sidebar-title',
  },
  SIDEBAR_TOTAL_LABEL: {
    defaultMessage: 'Starting at',
    id: 'car-configurator.sidebar-total-label',
  },
  SELECT_A_WRAP: {
    defaultMessage: 'Select a Wrap',
    id: 'car-configurator.select-a-wrap',
  },
  VEHICLE_AREA: {
    defaultMessage: 'Vehicle Area',
    id: 'car-configurator.vehicle-area',
  },
  MODIFY_VEHICLE: {
    defaultMessage: 'Modify vehicle',
    id: 'car-configurator.modify-vehicle',
  },
  MODIFY_VEHICLE_MOBILE: {
    defaultMessage: 'Vehicle',
    id: 'car-configurator.modify-vehicle-mobile',
  },
  DRAG_TO_ROTATE: {
    defaultMessage: 'Drag to rotate',
    id: 'car-configurator.drag-to-rotate',
  },
  FULL_BODY: {
    defaultMessage: 'Full Body',
    id: 'car-configurator.full-body',
  },
  VEHICLE_CONFIG_ID: {
    defaultMessage: 'Config ID',
    id: 'car-configurator.vehicle-config-id',
  },
  MONTHLY_PRICE: {
    id: 'car-configurator.monthly-price',
    defaultMessage: '{price}/mo',
  },
  FULL_PRICE: {
    id: 'car-configurator.full-price',
    defaultMessage: 'or {price} cash',
  },
});
