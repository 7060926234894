import React from 'react';
import './sidebar-configurator-footer.scss';

type Props = {
  totalLabel?: string;
  totalValue?: string | number;
  totalValueFootnote?: string;
  content?: React.ReactNode;
};

export const SidebarConfiguratorFooter: React.FC<Props> = ({
  totalLabel,
  totalValue,
  totalValueFootnote,
  content,
}) => {
  return (
    <div className='sidebar-configurator-footer'>
      <div className='sidebar-configurator-footer__total'>
        <span className='sidebar-configurator-footer__total__label label'>
          {totalLabel}
        </span>
        <span className='sidebar-configurator-footer__total__value value'>
          {totalValue}
        </span>
        <span className='sidebar-configurator-footer__total__value footnote'>
          {totalValueFootnote}
        </span>
      </div>
      {content && (
        <div className='sidebar-configurator-footer__content'>
          <hr className='bottom-content-divider' />
          <span>
            {content}
          </span>
        </div>
      )}
    </div>
  );
};
