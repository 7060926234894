import { createSlice } from '@reduxjs/toolkit';
import { forgotPassword } from 'redux/thunks/auth';

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: {
    email: '',
    touched: false,
    loading: false,
    error: '',
    validation: null,
  },
  reducers: {
    onForgotPasswordFormChange: (state, action) => {
      state.touched = true;
      state[action.payload.field] = action.payload.value;
    },
    setForgotPasswordFormValidation: (state, action) => {
      state.validation = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(forgotPassword.pending, (state) => {
      state.error = null;
    });

    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(forgotPassword.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as any;
    });
  },
});

export const { onForgotPasswordFormChange, setForgotPasswordFormValidation } = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
