import * as BABYLON from '@babylonjs/core';
import { ASSET_TYPES, mountCfAssetPath } from 'lib/utils';

export const getVehicleColorMaterial = async (colorSlug: string, scene:BABYLON.Scene) => {
  if (!colorSlug) {
    console.error('Missing vehicle color slug');

    return null;
  }
  const vehicleColorMaterialPath = mountCfAssetPath(`${colorSlug}.json`, ASSET_TYPES.VEHICLE_3D_MODEL_COLOR);
  try {
    const vehicleColorMaterial = await BABYLON.NodeMaterial.ParseFromFileAsync('vehicleColorMaterial', vehicleColorMaterialPath, scene);
    if (vehicleColorMaterial) {
      vehicleColorMaterial.backFaceCulling = false;
    }
    return vehicleColorMaterial;
  } catch (e) {
    console.error('Error while parsing vehicle color material', e);
    return null;
  }
};
