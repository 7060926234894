import { createSlice } from '@reduxjs/toolkit';
import { v1 as uuidv1 } from 'uuid';
import { messageTypes } from 'lib/constants';

export const flashMessagesSlice = createSlice({
  name: 'flashMessages',
  initialState: {
    id: null,
    type: null,
    message: null,
    target: null,
  },
  reducers: {
    emitFlashMessage: (state, action) => {
      const { type, message, target } = action.payload;
      state.id = uuidv1();
      state.type = type;
      state.target = target;
      state.message = message;
    },
    emitFlashError: (state, action) => {
      const { message, target } = action.payload;
      state.message = message;
      state.type = messageTypes.ERROR;
      state.target = target;
      state.id = uuidv1();
    },
  },
});

export const { emitFlashError, emitFlashMessage } = flashMessagesSlice.actions;
export const selectFlashMessageEvent = state => state.flashMessages;
export const selectFlashMessageEventByTarget = (state, target) => (state.flashMessages.target === target ? state.flashMessages : null);
export default flashMessagesSlice.reducer;
