import React from 'react';
import './form-field.scss';
import { Visible } from '@codeparticle/react-visible';
import classNames from 'classnames';
import { FORM_FIELD_INPUT_TYPES, TextInput, SelectInput, FormFieldInputType, AddressInput } from 'mod-styleguide';
import { TextInputProps } from '../text-input/text-input';
import { SelectInputProps } from '../select-input/select-input';
import { AddressInputProps } from '../address-input/address-input';

type FormFieldProps = TextInputProps & SelectInputProps & AddressInputProps & {
  error?: string,
  width?: string,
  inputType?: FormFieldInputType,
};

const getFieldInput = (inputType: FormFieldInputType) => {
  switch (inputType) {
    case FORM_FIELD_INPUT_TYPES.SELECT:
      return SelectInput;
    case FORM_FIELD_INPUT_TYPES.ADDRESS:
      return AddressInput;
    case FORM_FIELD_INPUT_TYPES.TEXT:
    default:
      return TextInput;
  }
};

const FormField = ({
  error,
  width = '100%',
  inputType = FORM_FIELD_INPUT_TYPES.TEXT,
  ...inputProps
}: FormFieldProps) => {
  const FieldInput = getFieldInput(inputType);
  return (
    <div
      className='form-field-rct-component'
      style={{ width }}
    >
      <FieldInput
        {...inputProps}
        hasError={!!error}
        className={classNames('form-input', inputProps.className)}
      />
      <Visible when={error}>
        <div className='input-error'>
          {error}
        </div>
      </Visible>
    </div>
  );
};

export {
  FormField,
};
