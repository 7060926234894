import { createAsyncThunk } from '@reduxjs/toolkit';
import { ROUTE_PATHS, STRAPI_API } from 'lib/constants';
import { translate } from 'lib/utils/intl-utils';
import { MESSAGE_TYPES } from 'mod-styleguide';
import { push } from 'redux-first-history';
import { emitFlashMessage } from 'redux/modules/flashMessages/flashMessagesSlice';
import { strapiApi } from 'lib/api';

type ResetPasswordResponse = {
  ok: boolean;
};

export const resetPassword = createAsyncThunk<ResetPasswordResponse, {
  password: string,
  passwordConfirmation: string,
  code: string,
}>(
  'user/reset-password',
  async (
    {
      password,
      passwordConfirmation,
      code,
    },
    { dispatch }) => {

    const { data } = await strapiApi.post(
      STRAPI_API.AUTH_RESET_PASSWORD,
      {
        password,
        passwordConfirmation,
        code,
      },
    );

    if (data) {
      // send user back to login page
      dispatch(push(ROUTE_PATHS.LOGIN));
      dispatch(emitFlashMessage(
        {
          message: translate('forgot-password.password-changed'),
          type: MESSAGE_TYPES.SUCCESS,
        },
      ));
    }

    return data;

  },
);
