import axios from 'axios';
import { emitFlashError } from 'redux/modules/flashMessages/flashMessagesSlice';
import { store } from 'redux/store';
import { retrieveFromLocalStorage } from '../utils';
import { STRAPI_API } from '../constants';

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

export const strapiApi = axios.create({
  baseURL: process.env.REACT_APP_STRAPI_API,
  headers: {
    'Content-Type': 'application/json',
  },
});

strapiApi.interceptors.request.use(
  (config) => {
    const userToken = retrieveFromLocalStorage('userToken', false);

    /*
    If jwt is not removed from local storage properly, and attaching an auth header to
     auth local related api, it will result in forbidden error.
     */
    if (userToken && !config.url.includes(STRAPI_API.AUTH_LOCAL)) {
      config.headers.Authorization = `Bearer ${userToken}`;
    }

    config.headers = {
      ...DEFAULT_HEADERS,
      ...config.headers,
    };

    return config;
  },
  (error) => {
    console.error('error in request interceptor', { error });
    return Promise.reject(error);
  },
);

strapiApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error('error in response interceptor', { error });

    const errorMessage = error.response.data.error.message || error.message || 'Something went wrong';
    store.dispatch(emitFlashError({
      message: errorMessage,
    }));

    return Promise.reject(error);
  },
);
