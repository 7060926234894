import React from 'react';
import { TableRowProps, UseTableRowProps } from 'react-table';

type Props = {
  rows: UseTableRowProps<TableRowProps>[];
  prepareRow: (row: UseTableRowProps<TableRowProps>) => void;
  onRowClick?: (row: TableRowProps) => void;
};

export const TableRows: React.FC<Props> = ({ rows, prepareRow, onRowClick }) => {
  return (
    <>
      {
        rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} onClick={() => onRowClick?.(row.original as TableRowProps)}>
              {
                row.cells.map(cell => {
                  const subRow = row.subRows[0];
                  if (subRow) prepareRow(subRow);
                  const subRowValue = subRow?.values[cell.column.id];
                  return (
                    // Render row cells with sub row data
                    <td className={`${cell.column.id}-element`} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                      <div className='sub-row-data'>
                        {subRowValue}
                      </div>
                    </td>
                  );
                })
              }
            </tr>
          );
        })
      }
    </>
  );
};

