import { VehiclePickerData } from 'lib/types';
import { useAppDispatch, useAppSelector } from 'lib/types/redux';
import { useEffect } from 'react';
import { useGetModelsByYearAndMakeQuery, useGetVehicleMakesByYearQuery, useGetVehicleModelQuery, useGetVehicleModelWithSupportedBodyPartsQuery, useGetVehicleYearsQuery } from 'redux/modules/api';
import { carConfiguratorSelector, initializeCarConfigurator } from 'redux/modules/carConfigurator/carConfiguratorSlice';
import { VehiclePickerFormState } from 'redux/modules/forms/vehiclePickerSlice/vehiclePickerSlice';

export const useVehiclePickerData = (
  formState: VehiclePickerFormState,
  // setting this to true will prevent the form from being initialized
  // with the first vehicle model in the fetched models list
  disableAutoInit = false,
  disableUnsupportedBodyParts = false,
): VehiclePickerData => {
  const dispatch = useAppDispatch();
  const carConfiguratorState = useAppSelector(carConfiguratorSelector);

  const {
    data: years,
  } = useGetVehicleYearsQuery();
  const year = formState?.year?.value ?? years?.[0].label;

  const {
    data: makes,
  } = useGetVehicleMakesByYearQuery({
    searchParams: {
      year,
    },
  });
  const make = formState?.make?.value ?? makes?.[0].label;

  const {
    data: models,
  } = useGetModelsByYearAndMakeQuery({
    searchParams: {
      year,
      make,
    },
  });

  const model = formState?.model?.value ?? models?.[0].label;

  const useGetVehicleModel = !disableUnsupportedBodyParts ? useGetVehicleModelQuery : useGetVehicleModelWithSupportedBodyPartsQuery;
  const {
    data: currentPickerVehicleModel,
    isFetching,
  } = useGetVehicleModel({
    searchParams: {
      year,
      make,
      model,
    },
  });

  useEffect(() => {
    if (currentPickerVehicleModel && !isFetching && !disableAutoInit && !carConfiguratorState?.initialized) {

      dispatch(initializeCarConfigurator({
        defaultVehicle: currentPickerVehicleModel,
      }));
    }
  }, [
    dispatch,
    currentPickerVehicleModel,
    isFetching,
    carConfiguratorState?.initialized,
    disableAutoInit,
  ]);

  return {
    years,
    makes,
    models,
    trims: currentPickerVehicleModel?.options?.trims,
    colors: currentPickerVehicleModel?.options?.colors,
    bodyParts: currentPickerVehicleModel?.bodyParts,
    selectedVehicleModel: currentPickerVehicleModel,
  };
};
