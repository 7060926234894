import React from 'react';
import { UseTableHeaderGroupProps, TableHeaderGroupProps } from 'react-table';

type Props = {
  headerGroups: UseTableHeaderGroupProps<TableHeaderGroupProps>[];
};

export const TableHeader = ({ headerGroups } : Props) => (
  <thead>
    {// Loop over the header rows
      headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {
            headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))
          }
        </tr>
      ))
    }
  </thead>
);
