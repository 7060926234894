import { ROUTE_PATHS } from 'lib/constants';
import { translate } from 'lib/utils/intl-utils';
import { LabelWithIcon, SvgAccount, SvgHamburgerMenu, SvgLogo, SvgSave } from 'mod-styleguide';
import React, { useCallback } from 'react';
import type { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectAuthUserData } from 'redux/modules/auth/authSlice';
import { useAppSelector } from 'lib/types/redux';
import './car-configurator-header.scss';
import { useMedia } from 'lib/hooks';
import { Visible } from '@codeparticle/react-visible';

type Props = {
  onLoginClick?: () => void;
  onHamburgerClick?: () => void;
  onSaveClick?: () => void;
  wrapPicker: ReactElement;
  disableActions?: boolean;
};

export const CarConfiguratorHeader:React.FC<Props> = ({
  onLoginClick,
  onHamburgerClick,
  onSaveClick,
  wrapPicker,
  disableActions,
}) => {
  const userData = useAppSelector(selectAuthUserData);
  const navigate = useNavigate();
  const goToProfile = useCallback(() => navigate(ROUTE_PATHS.PROFILE), [navigate]);

  const { isDesktopHDOrSmaller, isMobile } = useMedia();

  const showHamburgerHeader = isDesktopHDOrSmaller && onHamburgerClick;
  return (
    <div className='car-configurator-header'>
      <Visible
        when={showHamburgerHeader}
        fallback={!isMobile && wrapPicker}
      >
        <div className='car-configurator-header__hamburger-header'>
          <SvgHamburgerMenu className="hamburger-menu-icon" onClick={onHamburgerClick} />
          <SvgLogo className="header-logo-icon" color="#676767" width="134px" />
        </div>
      </Visible>
      <Visible when={!disableActions}>
        <div className='car-configurator-header__action-icons'>
          <Visible when={onSaveClick}>
            <LabelWithIcon
              label={translate('car-configurator.save-build')}
              icon={<SvgSave />}
              onClick={onSaveClick}
            />
          </Visible>
          <Visible when={userData || onLoginClick}>
            <LabelWithIcon
              label={userData ? userData.username : translate('common.login')}
              icon={<SvgAccount />}
              onClick={userData ? goToProfile : onLoginClick}
            />
          </Visible>
        </div>
      </Visible>
    </div>
  );
};
