import React, { ButtonHTMLAttributes } from 'react';
import './button.scss';
import { Visible } from '@codeparticle/react-visible';
import classNames from 'classnames';
import { SvgRightArrow } from '../../assets';

interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  transparent?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  width?: string;
  showDefaultIcon?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: boolean;
  customIcon?: React.ReactNode;
  color?: string;
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({
  primary = false,
  transparent = false,
  backgroundColor,
  width,
  label,
  showDefaultIcon = false,
  onClick,
  type = 'button',
  disabled,
  customIcon,
  color,
  className,
}) => {
  return (
    <button
      className={classNames([
        'button',
        primary && 'button--primary',
        transparent && 'button--transparent',
        className,
      ])}
      style={{ backgroundColor, width, color }}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {label}
      <Visible
        fallback={showDefaultIcon ? <SvgRightArrow /> : null}
        when={customIcon}
      >
        {customIcon}
      </Visible>
    </button>
  );
};
