import { translate } from 'lib/utils/intl-utils';
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Visible } from '@codeparticle/react-visible';
import './start-modding-cta.scss';
import { ROUTE_PATHS } from 'lib/constants';

interface StartModdingCtaProps {
  inline?: boolean;
}

export const StartModdingCta: React.FC<StartModdingCtaProps> = ({
  inline = false,
}) => {
  const navigate = useNavigate();

  return (
    <span
      className='dive-into-modding'
    >
      {translate('common.dive-into-modding')}
      <Visible
        when={!inline}
        fallback={
          (<>&nbsp;</>)
        }
      >
        <br />
      </Visible>
      <span
        className='start-modding'
        style={{ fontWeight: '700' }}
        onClick={() => navigate(ROUTE_PATHS.CAR_CONFIGURATOR)}
      >
        {translate('common.start-modding')}
      </span>
    </span>
  );
};

