import React from 'react';
import type { ReactNode } from 'react';
import type { IntlFormatters, IntlShape } from 'react-intl';
import type { Props as ReactIntlFormattedMessageProps } from 'react-intl/src/components/message';
import {
  FormattedMessage as ReactIntlFormattedMessage,
  useIntl as useReactIntl,
} from 'react-intl';
import enMessages from 'translations/locales/en.json';
import { intl } from 'lib/intl/intl';

export type PrimitiveType = string | number | boolean | null | undefined | Date;

// Type of all available message IDs.
type IntlMessageKeys = keyof typeof enMessages;

// The arguments to the original formatMessage function.
type FormatMessageArgs = Parameters<IntlFormatters['formatMessage']>;

// Extend the original FormattedMessage props.
type FormattedMessageProps = ReactIntlFormattedMessageProps<
Record<string, ReactNode>
> & {
  id?: IntlMessageKeys;
};

export function FormattedMessage({ id, ...rest }: FormattedMessageProps) {
  return <ReactIntlFormattedMessage id={id} {...rest} />;
}

export function useIntl() {
  // Pull out the original formatMessage function.
  const { formatMessage, ...rest } = useReactIntl();

  // Re-write the formatMessage function but with a strongly-typed id.
  const typedFormatMessage = (
    descriptor: FormatMessageArgs[0] & {
      id?: IntlMessageKeys;
    },
    values?: FormatMessageArgs[1] | any,
    options?: FormatMessageArgs[2],
  ) => {
    return formatMessage(descriptor, values, options);
  };

  return {
    ...rest,
    formatMessage: typedFormatMessage,
  };
}

const translateWithIntl = (_intl: IntlShape) => (id: IntlMessageKeys, values?: Record<string, PrimitiveType>) => _intl.formatMessage({
  id,
}, values);

export const translate = translateWithIntl(intl);

