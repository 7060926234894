
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  customerName: '',
  email: '',
  touched: false,
  loading: false,
  error: '',
  validation: null,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState: INITIAL_STATE,
  reducers: {
    onOrderFormChange: (state, action) => {
      state[action.payload.field] = action.payload.value;
      state.touched = state.customerName?.length > 0;
    },
    setOrderFormValidation: (state, action) => {
      state.validation = action.payload;
    },
  },
});

export const { onOrderFormChange, setOrderFormValidation } = orderSlice.actions;
export default orderSlice.reducer;
