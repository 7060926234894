import * as BABYLON from '@babylonjs/core';

export const optimizeMesh = (mesh: BABYLON.Mesh) => {
  mesh.freezeWorldMatrix();
  mesh.isPickable = false;
  mesh.doNotSyncBoundingInfo = true;
};

export {
  initializeScene,
  setupPostProcessing,
  createCamera,
  loadScene,
  getActiveShadowGenerators,
} from './scene';
export {
  applyVehicleBaseColor,
  getMeshFromBodyPartSlug,
  getMeshesFromBodyPartSlug,
  getVehicleColorMaterial,
  initializeVehicleMeshes,
} from './vehicle';
