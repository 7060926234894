import { createSlice } from '@reduxjs/toolkit';
import { login, signUp } from 'redux/thunks/auth';
import { logout } from 'redux/thunks/auth/logout';

const initialState = {
  userToken: null,
  userData: null,
  userProfile: null,
};


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.userToken = payload.jwt;
      state.userData = payload.user;
      state.userProfile = {
        email: payload.user.email,
        ...payload.profile,
      };
    });
    builder.addCase(signUp.fulfilled, (state, { payload }) => {
      state.userToken = payload.jwt;
      state.userData = payload.user;
      state.userProfile = payload.profile;
    });
    builder.addCase(logout.pending, () => {
      localStorage.removeItem('userToken');
      return initialState;
    });
  },
});

export const selectAuthUserData = state => state.auth.userData;
export const selectUserToken = state => state.auth.userToken;
export const selectUserProfile = state => state.auth.userProfile;
export const selectIsAuthenticated = state => Boolean(state.auth.userToken);
export default authSlice.reducer;
