import React from 'react';
import { SvgClose } from '../../assets';
import { Modal } from '../modal';

import './side-panel.scss';

interface SidePanelProps {
  title: string | React.ReactNode;
  content: React.ReactNode;
  footerTitle?: string | React.ReactNode;
  footer?: React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
}

export const INFO_WIDTH = {
  HALF: '50%',
  FULL: '100%',
};

export const SidePanel: React.FC<SidePanelProps> = ({
  title,
  content,
  footerTitle,
  footer,
  isOpen,
  onClose,
}) => {
  return (
    <Modal
      id="side-panel-modal"
      isOpen={isOpen}
    >
      <div className="side-panel-rct-component">
        <div className="side-panel-rct-component__header">
          <div className="side-panel-rct-component__header__title">
            {title}
          </div>
          <div className="side-panel-rct-component__header__close-button">
            <SvgClose onClick={onClose} />
          </div>
        </div>
        <div className="side-panel-rct-component__content">
          {content}
        </div>
        <div className="side-panel-rct-component__footer">
          {footerTitle && (
            <div className="side-panel-rct-component__footer__title">
              {footerTitle}
            </div>
          )}
          {footer}
        </div>
      </div>
    </Modal>
  );
};
