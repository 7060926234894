import { ILoadingScreen } from '@babylonjs/core';

export class CustomLoadingScreen implements ILoadingScreen {
  public loadingUIText: string;

  public loadingUIBackgroundColor: string;

  public loadingDiv: HTMLElement;

  constructor() {
    this.loadingDiv = document.getElementById('mod-loader');
  }

  public displayLoadingUI() {
    if (!this.loadingDiv || this.loadingDiv.style.display === 'flex') {
      return null;
    }
    window.gtag('event', 'model_render', {
      render_event: 'start',
    });
    this.loadingDiv.style.display = 'flex';
  }

  public hideLoadingUI() {
    if (!this.loadingDiv || this.loadingDiv.style.display === 'none') {
      return null;
    }
    window.gtag('event', 'model_render', {
      render_event: 'completed',
    });
    this.loadingDiv.style.display = 'none';
  }
}
