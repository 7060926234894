import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './app';
import './translations/config';

const rootElement = document.getElementById('mod-web-container') as Element;
const root = createRoot(rootElement);

root.render(<App />);
/**
 * the following line opts us into to PWA behavior.
 * https://facebook.github.io/create-react-app/docs/making-a-progressive-web-app
 *
 * This line only runs in production. To see it in action,
 * build and serve from the build directory first.
 *
 * To take full advantage of what registering the service worker does for us,
 * you will want to change the manifest.json file in /public.
 * This starter kit, by default, is set to use few of these capabilities
 * in order to be unopinionated.
 *
 * https://developers.google.com/web/fundamentals/web-app-manifest/
 *
 */
// registerServiceWorker();
