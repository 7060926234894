import { mountCfAssetPath } from 'lib/utils';
import * as BABYLON from '@babylonjs/core';

export const preloadFile = async (fileName, extension, assetType) => {
  const sceneArrayBuffer = await BABYLON.Tools.LoadFileAsync(mountCfAssetPath(`${fileName}.${extension}`, assetType), true);
  const sceneBlob = new Blob([sceneArrayBuffer]);
  const sceneUrl = URL.createObjectURL(sceneBlob);

  return sceneUrl;
};
