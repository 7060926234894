import * as BABYLON from '@babylonjs/core';
import { initializeDefaultScene } from './default-scene/initialize-default-scene';
import { initializeOutdoorScene } from './outdoor-scene';

export const initializeScene = (scene: BABYLON.Scene, sceneData) => {
  scene.skipPointerMovePicking = true;
  scene.pointerMovePredicate = () => false;
  scene.pointerDownPredicate = () => false;
  scene.pointerUpPredicate = () => false;
  if (sceneData.isOutdoor) {
    return initializeOutdoorScene(scene, sceneData);
  }
  return initializeDefaultScene(scene, sceneData);
};
