import { ROUTE_PATHS } from 'lib/constants';
import { USER_ROLES } from 'lib/types';

export const getHomePagePath = (userRole) => {
  switch (userRole) {
    case USER_ROLES.DEALER:
    case USER_ROLES.INSTALLER:
      return ROUTE_PATHS.MANAGE_JOBS_DASHBOARD;
    case USER_ROLES.CUSTOMER:
      return ROUTE_PATHS.CAR_CONFIGURATOR;
    default:
      return '/';
  }
};
