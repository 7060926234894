import React from 'react';
import { SvgClose, SidebarSubItem, SvgTrash, useSwipe } from 'mod-styleguide';
import { Visible } from '@codeparticle/react-visible';

export const SubItem: React.FC<{
  subItem: SidebarSubItem
  onRemove: (subItem: SidebarSubItem) => void
}> = ({
  subItem,
  onRemove,
}) => {

  const {
    swipedLeft,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  } = useSwipe();

  return (
    <div className='sidebar-sub-item-container'>
      <div
        key={subItem.id}
        className='sidebar-sub-item'
        onClick={subItem.onClick}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <div className='sidebar-sub-item__title'>
          {subItem.title}
          <Visible when={!swipedLeft}>
            <SvgClose
              onClick={() => onRemove(subItem)}
            />
          </Visible>
        </div>
        <div className='sidebar-sub-item__description'>
          {subItem.description}
        </div>
        <div className='sidebar-sub-item__footer'>
          {subItem.footer}
        </div>
      </div>
      <div
        className='sidebar-sub-item__swipe-delete'
        onClick={() => onRemove(subItem)}
        style={{
          width: swipedLeft ? '95px' : 0,
        }}
      >
        <SvgTrash />
      </div>
    </div>
  );
};
