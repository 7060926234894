const API_PREFIX = 'api/';

export const messageTypes = {
  GENERAL: 'GENERAL',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

export const APP_DOMAINS = {
  APP: 'app',
  DEALER: 'dealer',
  INSTALLER: 'installer',
  VIEWER: 'viewer',
};

export const FORM_ROW_HALF_WIDTH = 'calc(50% - 12px)';

export const ROUTE_PATHS = {
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  FORGOT_PASSWORD: '/auth/forgot-password',
  CAR_CONFIGURATOR: '/car-configurator',
  CAR_CONFIGURATOR_LOGIN: '/car-configurator/login',
  AUTHENTICATION: '/auth',
  PROFILE: '/profile',
  MANAGE_JOBS_DASHBOARD: '/manage-jobs',
};

export const STRAPI_API = {
  AUTH_LOCAL: `${API_PREFIX}auth/local`,
  AUTH_LOCAL_REGISTER: `${API_PREFIX}auth/local/register`,
  AUTH_FORGOT_PASSWORD: `${API_PREFIX}auth/forgot-password`,
  AUTH_RESET_PASSWORD: `${API_PREFIX}auth/reset-password`,
  PROFILES_CUSTOMER: `${API_PREFIX}customer-profiles`,
  PROFILES_DEALER: `${API_PREFIX}dealer-profiles`,
  PROFILES_INSTALLER: `${API_PREFIX}installer-profiles`,
  STRIPE_ACCOUNTS: `${API_PREFIX}stripe/accounts`,
  STRIPE_ACCOUNT_LINKS: `${API_PREFIX}stripe/account_links`,
  STRIPE_PAYMENT_INTENTS: `${API_PREFIX}/stripe/payment_intents`,
  STRIPE_TRANSFERS: `${API_PREFIX}stripe/transfers`,
};

export {
  CAMERA_TARGET_Y_OFFSET,
  MAX_CAMERA_DISTANCE,
  WRAPPABLE_MESHES,
} from './3d';
