import React, { useEffect, useMemo } from 'react';
import { USER_ROLES } from 'lib/types';
import { useDispatch, useSelector } from 'react-redux';
import { initializeApp, selectAppState } from 'redux/modules/app/appSlice';
import { Header } from 'mod-styleguide';
import { ROUTE_PATHS } from 'lib/constants';
import { logout } from 'redux/thunks/auth/logout';
import { store } from 'redux/store';
import { selectIsAuthenticated } from 'redux/modules/auth/authSlice';
import { getRoutes } from './routes';

const MANAGER_FIXED_HEADER = (
  <Header
    navItemsDefs={[
      {
        label: 'Dashboard',
        route: ROUTE_PATHS.MANAGE_JOBS_DASHBOARD,
      },
      {
        label: 'Profile',
        route: ROUTE_PATHS.PROFILE,
      },
      {
        label: 'Logout',
        onClick: () => store.dispatch(logout()),
      },
    ]}
  />
);

const getAppFixedHeader = (userRole, isAuthenticated) => {
  switch (userRole) {
    case USER_ROLES.DEALER:
    case USER_ROLES.INSTALLER:
      return isAuthenticated && MANAGER_FIXED_HEADER;
    case USER_ROLES.CUSTOMER:
    default:
      return null;
  }
};

export const useRoutes = () => {
  const { subdomain, initialized, userRole } = useSelector(selectAppState);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeApp());
  }, [dispatch]);

  const routes = getRoutes(subdomain);
  const fixedHeader = useMemo(() => getAppFixedHeader(userRole, isAuthenticated),
    [isAuthenticated, userRole]);

  return {
    initialized,
    routes,
    fixedHeader,
  };
};
