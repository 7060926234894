import React from 'react';
import classNames from 'classnames';
import { v1 as uuidv1 } from 'uuid';
import './text-input.scss';
import { Visible } from '@codeparticle/react-visible';
import { useBoolean } from 'ahooks';
import { SvgAlertCircle, SvgCopy, SvgEdit, SvgSearch, SvgVisibilityOff, SvgVisibilityOn } from 'mod-styleguide';
import { getConfig } from './config';

export type TextInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string,
  label?: string,
  labelClassName?: string,
  usingInnerLabel?: boolean,
  onBlur?: (e?: React.FocusEvent<HTMLInputElement>) => void,
  onChange?: (e?: { value?: string, field?: string }) => void,
  onEditClick?: () => void,
  placeholder?: string,
  type?: string,
  value?: string,
  hasError?: boolean,
  infoMode?: boolean,
  enableCopyButton?: boolean,
};

const TextInput = ({
  className,
  hasError,
  label,
  labelClassName,
  name,
  onBlur = () => {},
  onChange = () => {},
  placeholder,
  type,
  value,
  usingInnerLabel,
  infoMode,
  onEditClick,
  enableCopyButton,
  ...htmlInputProps
}: TextInputProps) => {
  const inputId = uuidv1();
  const config = getConfig(type);
  const [
    visibility,
    {
      toggle: toggleVisibility,
    },
  ] = useBoolean(false);

  const sendChangeEvent = (eventValue) => {
    if (eventValue.length > config.maxLength) return;
    onChange({
      value: eventValue,
      field: name,
    });
  };

  const onInputChange = (e) => {
    sendChangeEvent(e.target.value);
  };

  const onInputBlur = (e) => {
    const { pipe } = config;
    if (pipe) {
      const eventValue = pipe(e.target.value);
      sendChangeEvent(eventValue);
    } else {
      onBlur();
    }
  };

  const hasVisibilityToggle = type === 'password';
  const isSearchInput = type === 'search';

  const copyToClipboard = () => {
    const input = document.getElementById(inputId) as any;

    input.select();
    document.execCommand('copy');
  };
  return (
    <div
      className={classNames(
        'text-input-rct-component',
        className,
        usingInnerLabel && 'text-input-rct-component--inner-label-input',
      )}
      style={{
        width: htmlInputProps.width,
        minWidth: htmlInputProps.width,
      }}

    >
      {label && (
        <label
          htmlFor={inputId}
          className={classNames(
            'input-label',
            labelClassName,
          )}
        >
          {label}
          <Visible when={infoMode && onEditClick}>
            <SvgEdit onClick={onEditClick} />
          </Visible>
        </label>
      )}
      <Visible
        when={!infoMode}
        fallback={(
          <span className="read-only-input-content">
            {value}
          </span>
        )}
      >
        <div className='input-container'>
          <input
            className={classNames(
              config.className,
            )}
            id={inputId}
            placeholder={placeholder || config.placeholder}
            type={visibility ? 'text' : config.type}
            maxLength={config.maxLength}
            onChange={onInputChange}
            value={value}
            onBlur={onInputBlur}
            {...htmlInputProps}
          />
          <div className='input-icons-container'>
            <Visible when={onEditClick}>
              <span className='icon-container edit-icon'>
                <SvgEdit onClick={onEditClick} />
              </span>
            </Visible>
            <Visible when={enableCopyButton}>
              <span className='icon-container copy-icon' onClick={copyToClipboard}>
                <SvgCopy />
              </span>
            </Visible>
            <Visible when={hasError}>
              <SvgAlertCircle />
            </Visible>
            <Visible when={hasVisibilityToggle}>
              <Visible
                when={visibility}
                fallback={<SvgVisibilityOff onClick={toggleVisibility} />}
              >
                <SvgVisibilityOn onClick={toggleVisibility} />
              </Visible>
            </Visible>
            <Visible when={isSearchInput}>
              <SvgSearch />
            </Visible>
          </div>
        </div>
      </Visible>
    </div>
  );
};

export {
  TextInput,
};
