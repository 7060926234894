import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage'; // localStorage
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { reducers } from './reducers';
import { strapiApi } from './modules/api';

const loggerMiddleware = createLogger();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({ history: createBrowserHistory() });

const strapiApiMiddleware = strapiApi.middleware;
const middleware = [
  routerMiddleware,
  strapiApiMiddleware,
];

if (process.env.REACT_APP_ENABLE_LOGGER_MIDDLEWARE) {
  middleware.push(loggerMiddleware);
}

const rootReducer = combineReducers({
  ...reducers,
  router: routerReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(middleware),
});

export const persistor = persistStore(store);

export const history = createReduxHistory(store);
