export const MESSAGE_TYPES = {
  GENERAL: 'GENERAL',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

export const MESSAGE_STATUSES = {
  OPENING: 'opening',
  OPENED: 'opened',
  HOVERING: 'hovering',
  CLOSING: 'closing',
  CLOSE_CLICKED: 'close-clicked',
};

export const BREAKPOINTS = {
  PORTRAIT: '320px',
  LANDSCAPE: '480px',
  TABLET: '768px',
  DESKTOP: '980px',
  WIDESCREEN: '1690px',
};

export const FORM_FIELD_INPUT_TYPES = {
  TEXT: 'text',
  SELECT: 'select',
  ADDRESS: 'address',
} as const;


export const INFO_FIELD_WIDTH = {
  FULL: '100%',
  HALF: '50%',
};
