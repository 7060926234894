import { createAsyncThunk } from '@reduxjs/toolkit';
import { strapiApi } from 'lib/api';
import { ROUTE_PATHS } from 'lib/constants';
import type { RootState } from 'lib/types/redux';
import { push } from 'redux-first-history';

export const saveBuild = createAsyncThunk<Object, { buildName: string; buildId?: string }>(
  'car-configurator/save-build',
  async (
    { buildName, buildId },
    { dispatch, getState }) => {
    const { auth, carConfigurator } = getState() as RootState;

    if (!auth.userToken) {
      dispatch(push(ROUTE_PATHS.CAR_CONFIGURATOR_LOGIN));
      return;
    }

    const { vehicleConfig } = carConfigurator;

    const { data: vehicleConfigData } = await strapiApi({
      method: 'post',
      url: 'api/vehicle-configs',
      data: {
        data: vehicleConfig,
      },
    });

    // If build id is provided, remove current build from garage before creating the new one
    if (buildId) {
      await strapiApi({
        method: 'delete',
        url: `api/customer-vehicles/${buildId}`,
      });
    }

    // Add vehicle to customer garage
    const { data: customerVehicleData } = await strapiApi({
      method: 'post',
      url: 'api/customer-vehicles',
      data: {
        data: {
          vehicleConfig: vehicleConfigData.id,
          customer: auth.userToken.id,
          name: buildName,
        },
      },
    });

    // TODO - MOD-229 Go to garage after saving build
    return customerVehicleData;
  },
);
