import app from 'redux/modules/app/appSlice';
import auth from 'redux/modules/auth/authSlice';
import carConfigurator from 'redux/modules/carConfigurator/carConfiguratorSlice';
import { strapiApi } from './modules/api';
import locale from './modules/locale/localeSlice';
import flashMessages from './modules/flashMessages/flashMessagesSlice';
import forms from './modules/forms';
// IMPORT_PT (for module generator integration script -- do not remove!)

export const reducers = {
  locale,
  flashMessages,
  [strapiApi.reducerPath]: strapiApi.reducer,
  app,
  forms,
  auth,
  carConfigurator,
// INSERTION_PT (for module generator integration script -- do not remove!)
};
