import * as BABYLON from '@babylonjs/core';

export const setupShadowGenerators = (scene: BABYLON.Scene) => scene.lights.map(light => {

  const getShadowGenerator = () => {
    const newShadowGenerator = new BABYLON.ShadowGenerator(1024, light as BABYLON.IShadowLight);
    newShadowGenerator.normalBias = 0;
    newShadowGenerator.filter = BABYLON.ShadowGenerator.FILTER_BLURCLOSEEXPONENTIALSHADOWMAP;

    const spotlight = light as BABYLON.SpotLight;


    // if (light.id.includes('ceiling')) {
    //   spotlight.shadowMaxZ = 2;
    //   spotlight.shadowMinZ = 0.1;
    //   spotlight.innerAngle = 2.7;
    // } else {
    //   spotlight.shadowMaxZ = 6;
    //   spotlight.shadowMinZ = 0.01;
    // }

    // TODO - Replace these settings with commented code above once sangeen is able to export the correct angle values
    if (light.id.includes('ceiling')) {
      // config ceiling light
      spotlight.intensity = 10;
      spotlight.angle = 4.8;
      spotlight.innerAngle = 2.7;
      spotlight.shadowMinZ = 0.1;
      spotlight.shadowMaxZ = 2;
      //light.blurKernel = 12;
    } else if (light.id.includes('spot-1')) {
      // config spot 1
      spotlight.intensity = 15;
      spotlight.angle = 5.2;
      spotlight.shadowMinZ = 0.01;
      spotlight.shadowMaxZ = 6;
    } else if (light.id.includes('spot-2')) {
      // config spot 2
      spotlight.intensity = 15;
      spotlight.angle = 5.3;
      spotlight.shadowMinZ = 0.01;
      spotlight.shadowMaxZ = 6;
    } else if (light.id.includes('spot-3')) {
      // config spot 3
      spotlight.intensity = 15;
      spotlight.angle = 5.33;
      spotlight.shadowMinZ = 0.01;
      spotlight.shadowMaxZ = 6;
    }
    // newShadowGenerator.s
    return newShadowGenerator;

  };
  const shadowGenerator = getShadowGenerator();

  if (shadowGenerator) {
    scene.meshes.forEach(mesh => {
      // Add all meshes to the shadow generator render list
      shadowGenerator.getShadowMap().renderList.push(mesh);
    });
  }

  return shadowGenerator;
}).filter(Boolean);
