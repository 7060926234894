import { createAsyncThunk } from '@reduxjs/toolkit';
import { ROUTE_PATHS, STRAPI_API } from 'lib/constants';
import { push } from 'redux-first-history';
import { strapiApi } from 'lib/api';
import { storeInLocalStorage } from 'lib/utils';
import { emitFlashError } from 'redux/modules/flashMessages/flashMessagesSlice';
import { translate } from 'lib/utils/intl-utils';
import { RootState } from '../../../lib/types/redux';

export const signUp = createAsyncThunk<any, {
  address: any,
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  passwordConfirmation: string,
  phone: string,
  zipCode: string,
  latitude: string,
  longitude: string,
}>(
  'user/sign-up',
  async (
    {
      address,
      email,
      firstName,
      lastName,
      password,
      passwordConfirmation,
      phone,
      zipCode,
      latitude,
      longitude,
    },
    { dispatch, getState }) => {
    if (password !== passwordConfirmation) {
      dispatch(emitFlashError({
        message: translate('form.error-passwords-do-not-match'),
      }));
      return;
    }

    const { app } = getState() as RootState;
    const { subdomain: scope } = app;

    const { data } = await strapiApi.post(
      STRAPI_API.AUTH_LOCAL_REGISTER,
      {
        address,
        email,
        username: email,
        firstName,
        lastName,
        password,
        phone,
        zipCode,
        latitude,
        longitude,
        scope,
      },
    );

    if (data) {
      storeInLocalStorage('userToken', data.jwt);
      dispatch(push(ROUTE_PATHS.CAR_CONFIGURATOR));
    }

    return data;
  },
);
