import * as BABYLON from '@babylonjs/core';
import { optimizeMesh } from '..';

const VEHICLE_COLLISION_BOX_ID = 'vehicle_collision_box';

export const initializeVehicleMeshes = async (meshes: BABYLON.Mesh[], shadowGenerators: BABYLON.ShadowGenerator[]) => {
  for (const mesh of meshes) {
    optimizeMesh(mesh);

    if (mesh.id === VEHICLE_COLLISION_BOX_ID) {
      const material = mesh.material as BABYLON.StandardMaterial;
      material.disableLighting = true;
      mesh.checkCollisions = true;
      mesh.isVisible = false;
      continue;
    }

    if (mesh.id.includes('_disabled')) {
      mesh.setEnabled(false);
    }

    const carShadowGenerator = shadowGenerators.find((shadowGenerator) => shadowGenerator.id.includes('car'));
    if (carShadowGenerator) {
      carShadowGenerator.getShadowMap().renderList.push(mesh);
      const remainingShadowGenerators = shadowGenerators.filter((shadowGenerator) => !shadowGenerator.id.includes('car'));
      remainingShadowGenerators.forEach((shadowGenerator) => {
        shadowGenerator.getLight().excludedMeshes.push(mesh);
      });
    } else {
      shadowGenerators.forEach((shadowGenerator) => {
        shadowGenerator.getShadowMap().renderList.push(mesh);
      });
    }

    if (mesh.material && !mesh.name.includes('glass')) {
      mesh.material.backFaceCulling = false;
    }
    if (mesh.name.includes('glass')) {
      const glassMaterial = mesh.material as BABYLON.PBRMaterial;
      glassMaterial.specularIntensity = 0;
      glassMaterial.environmentIntensity = 2.5;
    }

    mesh.receiveShadows = true;
  }

};
