import { useState } from 'react';

const SWIPE_THRESHOLD = 50;
export const useSwipe = () => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [swipedLeft, setSwipedLeft] = useState(false);

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > SWIPE_THRESHOLD;

    setSwipedLeft(isLeftSwipe);
  };

  return {
    swipedLeft,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};
