import { useSetState } from 'ahooks';
import { useAppDispatch } from 'lib/types/redux';
import { translate } from 'lib/utils/intl-utils';
import { Button, Form, Modal, SvgClose } from 'mod-styleguide';
import React from 'react';
import { saveBuild } from 'redux/thunks/configurator/save-build';

import './save-build-modal.scss';

interface SaveBuildModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const INITIAL_FORM_STATE = {
  buildName: '',
  touched: false,
};

export const SaveBuildModal: React.FC<SaveBuildModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [formState, setFormState] = useSetState(INITIAL_FORM_STATE);
  const dispatch = useAppDispatch();
  const onInputChange = (e) => {
    setFormState({
      [e.field]: e.value,
      touched: true,
    });
  };

  const onSaveBuild = () => {
    dispatch(saveBuild({
      buildName: formState.buildName,
    }));
    setFormState(INITIAL_FORM_STATE);
    onClose();
  };

  return (
    <Modal
      id="save-build-modal"
      isOpen={isOpen}
      title={translate('car-configurator.save-build')}
    >
      <Form
        formState={formState}
        formFieldDefs={[
          {
            name: 'buildName',
            label: translate('car-configurator.build-name'),
            placeholder: translate('car-configurator.build-name'),
          },
        ]}
        submitLabel={translate('car-configurator.save-build')}
        onSubmit={onSaveBuild}
        onInputChange={onInputChange}
      />
      <Button
        label={translate('common.cancel')}
        onClick={onClose}
        customIcon={<SvgClose />}
        backgroundColor="#353535"
        color="white"
      />
    </Modal>
  );
};

