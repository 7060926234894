/**
 * @fileoverview Hook to provide screen breakpoints in presentational files
 * @author Gabriel Womble
 */
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BREAKPOINTS } from 'lib/utils/constants';

const {
  LANDSCAPE,
  TABLET,
  DESKTOP,
  HD,
  WIDESCREEN,
} = BREAKPOINTS;

const addPixel = (currentSize, sizeToAdd) => `${(Number(currentSize.split('px')[0]) + sizeToAdd)}px`;

/*
  Usage examples:
    1: const media = useMedia();
    2: const { isMobile } = useMedia();
    3: const isTablet = useMedia().isTablet;
*/


function useMedia() {
  const isClient = typeof window === 'object';
  const getSize = useCallback(() => {
    return isClient ? window.innerWidth : undefined;
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  const applyBreakpoint = useCallback((fromSize, toSize = null) => {
    const minBreakpoint = parseInt(fromSize, 10) <= windowSize;
    const maxBreakpoint = toSize && parseInt(toSize, 10) > windowSize;
    const breakpointCondition = (
      (minBreakpoint && maxBreakpoint)
      || (minBreakpoint && !toSize)
    );

    return breakpointCondition;
  }, [windowSize]);

  const breakpoints = useMemo(() => ({
    isMobile: applyBreakpoint(0, LANDSCAPE),
    isLandScape: applyBreakpoint(LANDSCAPE, TABLET),
    isTablet: applyBreakpoint(TABLET, DESKTOP),
    isTabletOrSmaller: applyBreakpoint(0, addPixel(TABLET, 1)),
    isHD: applyBreakpoint(HD, WIDESCREEN),
    isDesktopHDOrSmaller: applyBreakpoint(0, HD),
    isDesktop: applyBreakpoint(DESKTOP, WIDESCREEN),
    isWideScreen: applyBreakpoint(WIDESCREEN),
  }), [applyBreakpoint]);

  return breakpoints;
}

export { useMedia };
