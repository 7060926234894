import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SvgChevronLeft } from '../../assets';
import './back-button.scss';

interface Props {
  // use this prop to ensure the back button always navigate
  // to the same route instead of relying on the previous route
  to?: string,
  // optional label to be displayed right next to the back button
  label?: string,
}

export const BackButton: React.FC<Props> = ({
  to,
  label = '',
}) => {
  const navigate = useNavigate();

  const goBack = () => {
    if (to) {
      navigate(to);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className='back-button-rct-component'>
      <SvgChevronLeft color="#BEE5FF" onClick={goBack} />
      <span>{label}</span>
    </div>
  );
};
