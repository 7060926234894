import * as BABYLON from '@babylonjs/core';
import { OutdoorLights } from './setup-outdoor-lights';

export const setupOutdoorShadowGenerators = (scene: BABYLON.Scene, outdoorLights: OutdoorLights) => {
  const { envDirectionalLight, carDirectionalLight } = outdoorLights;

  const envShadowGenerator = new BABYLON.CascadedShadowGenerator(1024, envDirectionalLight);
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  envShadowGenerator._darkness = -0.6;
  envShadowGenerator.usePercentageCloserFiltering = true;
  envShadowGenerator.filteringQuality = BABYLON.ShadowGenerator.QUALITY_HIGH;
  envShadowGenerator.lambda = 0.99;
  envShadowGenerator.penumbraDarkness = 1;
  envShadowGenerator.depthClamp = true;
  envShadowGenerator.stabilizeCascades = true;
  envShadowGenerator.shadowMaxZ = 6300;
  envShadowGenerator.bias = 0.002;

  const carShadowGenerator = new BABYLON.CascadedShadowGenerator(1024, carDirectionalLight);
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  carShadowGenerator._darkness = -1.8;
  carShadowGenerator.usePercentageCloserFiltering = true;
  carShadowGenerator.filteringQuality = BABYLON.ShadowGenerator.QUALITY_HIGH;
  carShadowGenerator.lambda = 0.97;
  carShadowGenerator.penumbraDarkness = 1;
  carShadowGenerator.depthClamp = true;
  carShadowGenerator.stabilizeCascades = true;
  carShadowGenerator.shadowMaxZ = 7;
  carShadowGenerator.bias = 0.0034;
  carShadowGenerator.normalBias = 0;

  scene.meshes.forEach(mesh => {
    if (!mesh.id.includes('ground_')) {
      // The ground should not generate shadows
      // Add all meshes but the ground to the envShadowGenerator render list
      envShadowGenerator.getShadowMap().renderList.push(mesh);
    } else {
      const groundMeshPbrMaterial = mesh.material as any;
      groundMeshPbrMaterial.ambientTexture.uScale = 50;
      groundMeshPbrMaterial.ambientTexture.vScale = 50;
      groundMeshPbrMaterial.ambientTexture.level = 0.5;
    }
  });

  return {
    envShadowGenerator,
    carShadowGenerator,
  };
};
