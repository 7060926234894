import * as BABYLON from '@babylonjs/core';
import { SceneData } from 'lib/types';
import { ASSET_TYPES, mountCfAssetPath } from 'lib/utils';

// Set up the basic scene with common settings used by all scenes
export const setupBaseScene = (scene: BABYLON.Scene, sceneData: SceneData) => {
  scene.collisionsEnabled = true;
  scene.meshes.forEach(mesh => {
    // Enable shadows for all meshes
    mesh.receiveShadows = true;
    mesh.freezeWorldMatrix();
  });

  scene.createDefaultEnvironment({
    environmentTexture: mountCfAssetPath(`${sceneData.slug}.env`, ASSET_TYPES.ENVIRONMENT_TEXTURE),
    createGround: false,
    createSkybox: false,
  });

  const envIntensity = sceneData.isOutdoor ? 1.5 : 0.7;
  scene.environmentIntensity = envIntensity;
};
