import React, { useCallback } from 'react';

import './order-form-modal.scss';
import { Modal, Form, MESSAGE_TYPES } from 'mod-styleguide';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { orderFormState } from 'redux/modules/forms';
import { useAppDispatch, useAppSelector } from 'lib/types/redux';
import { onOrderFormChange, setOrderFormValidation } from 'redux/modules/forms/order/orderSlice';
import { emitFlashMessage } from 'redux/modules/flashMessages/flashMessagesSlice';
import { ModalProps } from 'mod-styleguide/src/components/modal/modal';
import { validateObject } from '@codeparticle/formal';
import { VALIDATION_RULES } from 'lib/utils';
import { selectActiveVehicleConfigId, selectActiveVehicleConfigPrice } from 'redux/modules/carConfigurator/carConfiguratorSlice';

const validateForm = validateObject({
  customerName: [VALIDATION_RULES.REQUIRED_STRING],
});

const buildFormSurveyLink = ({
  configId,
  customerName,
}: {
  configId: string;
  customerName: string;
}) => `https://docs.google.com/forms/d/e/1FAIpQLSe_bbyXzzdrRekBp33GxnMchBHbAvLvejThrxdeKXd__6QXZQ/viewform?usp=pp_url&entry.1207656981=${encodeURIComponent(customerName)}&entry.825784459=${configId}`;

interface OrderFormModalProps extends ModalProps {
}

export const OrderFormModal: React.FC<OrderFormModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const formState = useSelector(orderFormState);
  const dispatch = useAppDispatch();
  const vehicleConfigId = useAppSelector(selectActiveVehicleConfigId);
  const vehicleConfigPrice = useAppSelector(selectActiveVehicleConfigPrice);

  const onSubmit = useCallback(() => {
    window.gtag('event', 'survey', {
      currency: 'USD',
      value: vehicleConfigPrice,
      config_id: vehicleConfigId,
    });

    window.open(buildFormSurveyLink({
      configId: vehicleConfigId,
      customerName: formState.customerName,
    }));

    dispatch(emitFlashMessage({
      message: formatMessage({
        id: 'common.order-submitted',
      }),
      type: MESSAGE_TYPES.SUCCESS,
    }));
    onClose();
  }, [dispatch, onClose, formatMessage, formState, vehicleConfigId, vehicleConfigPrice]);

  const onInputChange = useCallback((e) => {
    dispatch(onOrderFormChange(e));
  }, [dispatch]);

  return (
    <div>
      <Modal
        className='order-form-modal-rct-component'
        isOpen={isOpen}
        onClose={onClose}
        title={formatMessage({
          id: 'order-form.title',
        })}
        subTitle={formatMessage({
          id: 'order-form.sub-title',
        })}
      >
        <Form
          formState={formState}
          onInputChange={onInputChange}
          submitLabel={formatMessage({
            id: 'order-form.submit',
          })}
          onSubmit={onSubmit}
          setFormValidation={(v) => dispatch(setOrderFormValidation(v))}
          validateForm={validateForm}
          formFieldDefs={[
            {
              name: 'customerName',
              autoComplete: 'name',
              label: formatMessage({
                id: 'common.customer-name',
              }),
              placeholder: formatMessage({
                id: 'form.name-placeholder',
              }),
            },
          ]}
        />
      </Modal>
    </div>
  );
};

