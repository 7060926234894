import { Button, Modal, SvgClose, SvgSuccess } from 'mod-styleguide';
import React from 'react';
import { useIntl } from 'react-intl';

import './cancel-order-modal.scss';

interface CancelOrderModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onCancelOrder?: () => void;
}


export const CancelOrderModal: React.FC<CancelOrderModalProps> = ({
  isOpen,
  onClose,
  onCancelOrder,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      id="cancel-order-modal"
      isOpen={isOpen}
      title="Cancel order"
    >
      <div className="cancel-order-modal-content">
        <p>{formatMessage({ id: 'manage-jobs.cancel-order-confirmation' })}</p>
        <div className="cancel-order-modal-buttons">
          <Button
            backgroundColor='#E33F53'
            onClick={onCancelOrder}
            label="Confirm"
            width="100%"
            color='white'
            customIcon={<SvgSuccess />}
          />
          <Button
            className='cancel-button'
            backgroundColor='#353535'
            onClick={onClose}
            label="Cancel"
            width="100%"
            color='white'
            customIcon={<SvgClose />}
          />
        </div>
      </div>
    </Modal>
  );
};
