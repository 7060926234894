import React from 'react';
import './car-configurator.scss';
import { Header, Sidebar, WrapPicker } from 'mod-styleguide';
import { SidebarConfiguratorFooter, SidebarConfiguratorItems } from 'mod-styleguide/src/components/sidebar';
import { translate } from 'lib/utils/intl-utils';
import { useBoolean } from 'ahooks';
import { useSelector } from 'react-redux';
import { carConfiguratorSelector } from 'redux/modules/carConfigurator/carConfiguratorSlice';
import { AuthenticationModal } from 'components/authentication-modal';
import { useMedia } from 'lib/hooks';
import { Visible } from '@codeparticle/react-visible';
import { SaveBuildModal } from 'components/save-build-modal';
import { SelectedVehicleInfo } from 'components/selected-vehicle-info';
import { CarConfiguratorHeader } from './components/car-configurator-header';
import { useVehicleScene, useVehicleConfig, useWrapPicker } from './hooks';
import { SidebarModal } from './components/sidebar-modal';

const CAROUSEL_ITEMS_PER_SLIDE = 10;
const TABLET_ITEMS_PER_SLIDE = 8;
const MOBILE_CAROUSEL_ITEMS_PER_SLIDE = 6;

const getItemsPerSlide = (isTablet, isMobile) => {
  if (isMobile) {
    return MOBILE_CAROUSEL_ITEMS_PER_SLIDE;
  }
  if (isTablet) {
    return TABLET_ITEMS_PER_SLIDE;
  }
  return CAROUSEL_ITEMS_PER_SLIDE;

};

export const CarConfigurator: React.FC = () => {
  const [isAuthenticationModalOpen, {
    setFalse: closeAuthenticationModal,
    setTrue: openAuthenticationModal,
  }] = useBoolean(false);
  const [isSavedBuildModalOpen, {
    setFalse: closeSavedBuildModal,
    setTrue: openSavedBuildModal,
  }] = useBoolean(false);
  const [isFloatingSideBarOpen, {
    setFalse: closeFloatingSideBar,
    setTrue: openFloatingSideBar,
  }] = useBoolean(false);
  const { vehicleModelData } = useSelector(carConfiguratorSelector);
  const {
    wrapPickerData,
    wrapPickerDefaultStates,
    activeWraps,
    onApplyWrap,
    onRemoveWrap,
    setWrapPickerDefaultStates,
    onClearWraps,
  } = useWrapPicker();
  const { localVehicleConfig, currentConfigPrice } = useVehicleConfig();
  const scene = useVehicleScene();

  const { isMobile, isDesktopHDOrSmaller, isTabletOrSmaller } = useMedia();

  const sidebarTitle = (
    <div className='sidebar-title'>
      {translate('car-configurator.sidebar-title')}
      <SelectedVehicleInfo />
    </div>
  );

  const sideBarConfiguratorFooter = (
    <SidebarConfiguratorFooter
      totalLabel={translate('car-configurator.sidebar-total-label')}
      totalValue={`$${currentConfigPrice}`}
    />
  );

  const sideBarConfiguratorItems = (
    <SidebarConfiguratorItems
      items={[
        {
          id: '1',
          label: translate('car-configurator.select-a-wrap'),
          subItems: activeWraps,
          onRemoveSubItem: onRemoveWrap,
        },
      ]}
    />
  );

  const wrapPicker = wrapPickerDefaultStates.defaultMaterial && wrapPickerDefaultStates.defaultBrand && (
    <WrapPicker
      bodyParts={vehicleModelData.bodyParts}
      materialsGroupedOptions={wrapPickerData.materialsGroupedOptions}
      textures={wrapPickerData.textures}
      defaultBrand={wrapPickerDefaultStates.defaultBrand}
      defaultMaterial={wrapPickerDefaultStates.defaultMaterial}
      defaultTexture={wrapPickerDefaultStates.defaultTexture}
      setDefaultPickerData={setWrapPickerDefaultStates}
      activeModConfigs={localVehicleConfig.modConfigs}
      labels={{
        materialLabel: translate('common.material'),
        headerTitle: translate('car-configurator.vehicle-area'),
        applyButtonLabel: translate('common.apply'),
      }}
      onApply={onApplyWrap}
      carouselSlidesToScroll={getItemsPerSlide(isTabletOrSmaller, isMobile)}
      isMobile={isMobile}
      onClearWraps={onClearWraps}
    />
  );

  return (
    <div className="car-configurator-rct-component">
      <Visible when={!isDesktopHDOrSmaller}>
        <Sidebar
          title={sidebarTitle}
          content={sideBarConfiguratorItems}
          footer={sideBarConfiguratorFooter}
        />
      </Visible>
      <div className='car-configurator-rct-component__main-content'>
        <Header
          transparent
          hideLogo
          customContent={
            <CarConfiguratorHeader
              onLoginClick={openAuthenticationModal}
              onHamburgerClick={openFloatingSideBar}
              onSaveClick={openSavedBuildModal}
              wrapPicker={wrapPicker}
            />
            }
        />
        <div
          className='car-configurator-rct-component__scene-container'
        >
          <Visible when={isDesktopHDOrSmaller}>
            <div className='floating-wrap-picker-container'>
              {wrapPicker}
            </div>
          </Visible>
          {scene}
        </div>
        {isDesktopHDOrSmaller && sideBarConfiguratorFooter}
      </div>
      <Visible when={isDesktopHDOrSmaller}>
        <SidebarModal
          isOpen
          className={!isFloatingSideBarOpen && 'display-hidden'}
          onClose={closeFloatingSideBar}
          title={sidebarTitle}
          content={sideBarConfiguratorItems}
        />
      </Visible>
      <AuthenticationModal
        isOpen={isAuthenticationModalOpen}
        onClose={closeAuthenticationModal}
        openModal={openAuthenticationModal}
      />
      <SaveBuildModal
        isOpen={isSavedBuildModalOpen}
        onClose={closeSavedBuildModal}
      />
    </div>
  );
};

