import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { store } from 'redux/store';

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// Typed useDispatch and useSelector hooks
// READ MORE: https://react-redux.js.org/using-react-redux/usage-with-typescript#define-root-state-and-dispatch-types
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
