import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { mapOrdersData, mapVehicleConfigResponseToViewerData } from 'lib/data-mappers';
import { mapVehicleModel, mapVehicleModelsOptions } from 'lib/data-mappers/vehicle-models';
import { RootState } from 'lib/types/redux';
import { prepareHeaders } from 'lib/utils';
import { MESSAGE_TYPES, SelectInputOptionType } from 'mod-styleguide';
import { emitFlashError, emitFlashMessage } from '../flashMessages/flashMessagesSlice';
import { BaseStrapiArrayResponse, mapStrapiArrayResponseToOptionsFromSingleValueKey } from './utils';

const baseUrl = `${process.env.REACT_APP_STRAPI_API}/api/`;
const baseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });
export const strapiApi = createApi({
  reducerPath: 'strapiApi',
  baseQuery: async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error) {
      console.error('error in response interceptor', { error: result.error });

      // @ts-ignore
      const errorMessage = result.error.data.error.message || result.error.message || 'Something went wrong';
      api.dispatch(emitFlashError({
        message: errorMessage,
      }));
    }
    return result;
  },
  endpoints: (builder) => ({
    getVehicleYears: builder.query<SelectInputOptionType[], void>({
      query: () => ({
        url: 'vehicle-models',
        params: {
          'fields': 'year',
        },
      }),
      transformResponse: (response: BaseStrapiArrayResponse) => mapStrapiArrayResponseToOptionsFromSingleValueKey(response, 'year'),
    }),
    getVehicleMakesByYear: builder.query<SelectInputOptionType[], { searchParams: { year: string } }>({
      query: ({
        searchParams: {
          year,
        },
      }) => {
        const filterSearch = year ? `&filters[$and][0][year][$eq]=${year}` : '';
        return ({
          url: `vehicle-models?fields=make${filterSearch}`,
        });
      },
      transformResponse: (response: BaseStrapiArrayResponse) => mapStrapiArrayResponseToOptionsFromSingleValueKey(response, 'make'),
    }),
    getModelsByYearAndMake: builder.query<SelectInputOptionType[], { searchParams: { year: string, make: string } }>({
      query: ({
        searchParams: {
          year,
          make,
        },
      }) => {
        const yearFilter = year ? `&filters[$and][0][year][$eq]=${year}` : '';
        const makeFilter = (make && year) ? `&filters[$and][1][make][$eq]=${make}` : '';
        return ({
          url: `vehicle-models?fields[0]=model&fields[1]=slug${yearFilter}${makeFilter}`,
        });
      },
      transformResponse: (response: BaseStrapiArrayResponse) => mapVehicleModelsOptions(response),
    }),
    getVehicleModels: builder.query<any[], {}>({
      query: () => ({
        url: 'vehicle-models',
      }),
      transformResponse: (response: BaseStrapiArrayResponse) => (response.data.map(({ attributes }) => attributes)),
    }),
    getVehicleModel: builder.query({
      query: ({
        searchParams: {
          year,
          make,
          model,
        },
      }) => {
        const yearFilter = year ? `&filters[$and][0][year][$eq]=${year}` : '';
        const makeFilter = (make && year) ? `&filters[$and][1][make][$eq]=${make}` : '';
        const modelFilter = (model && model && year) ? `&filters[$and][2][model][$eq]=${model}` : '';

        return ({
          url: `vehicle-models?populate=bodyParts&populate=recommendedMods${yearFilter}${makeFilter}${modelFilter}`,
        });
      },
      transformResponse: (response: BaseStrapiArrayResponse) => (mapVehicleModel(response.data[0].attributes, true)),
    }),
    getVehicleModelWithSupportedBodyParts: builder.query({
      query: ({
        searchParams: {
          year,
          make,
          model,
        },
      }) => {
        const yearFilter = year ? `&filters[$and][0][year][$eq]=${year}` : '';
        const makeFilter = (make && year) ? `&filters[$and][1][make][$eq]=${make}` : '';
        const modelFilter = (model && model && year) ? `&filters[$and][2][model][$eq]=${model}` : '';

        return ({
          url: `vehicle-models?populate=bodyParts&populate=recommendedMods${yearFilter}${makeFilter}${modelFilter}`,
        });
      },
      transformResponse: (response: BaseStrapiArrayResponse) => (mapVehicleModel(response.data[0].attributes)),
    }),
    getFlagged: builder.query({
      query: ({
        searchParams: {
          year,
          make,
          model,
        },
      }) => {
        const yearFilter = year ? `&filters[$and][0][year][$eq]=${year}` : '';
        const makeFilter = (make && year) ? `&filters[$and][1][make][$eq]=${make}` : '';
        const modelFilter = (model && model && year) ? `&filters[$and][2][model][$eq]=${model}` : '';

        return ({
          url: `vehicle-models?populate=bodyParts&populate=recommendedMods${yearFilter}${makeFilter}${modelFilter}`,
        });
      },
      transformResponse: (response: BaseStrapiArrayResponse) => (mapVehicleModel(response.data[0].attributes, true)),
    }),
    getVehicleModelBySlug: builder.query({
      query: ({
        slug,
      }) => ({
        url: `vehicle-models/slug/${slug}?populate=bodyParts&populate=recommendedMods`,
      }),
      transformResponse: (response: any) => (mapVehicleModel(response.data.attributes)),
    }),
    getAllMods: builder.query({
      query: () => ({
        url: 'mods?pagination[pageSize]=1000&filters[$and][0][enabled][$eq]=true',
      }),
    }),
    getVehicleConfig: builder.query({
      query: ({
        id }) => ({
        url: `vehicle-configs/${id}?populate[vehicleModel][populate]=%2A&populate[modConfigs][populate]=%2A`,
      }),
      transformResponse: (response: any) => mapVehicleConfigResponseToViewerData(response),
    }),
    postVehicleConfig: builder.mutation({
      query: (payload) => ({
        url: 'vehicle-configs',
        method: 'POST',
        body: {
          data: payload,
        },
      }),
      transformResponse: (response: any) => response.data.attributes,
    }),
    getScenes: builder.query({
      query: () => ({
        url: 'scenes',
        method: 'GET',
      }),
      transformResponse: (response: any) => response.data.map(data => data.attributes),
    }),
    getOrders: builder.query({
      query: () => ({
        url: 'orders',
        method: 'GET',
      }),
      transformResponse: (response: any) => (mapOrdersData(response.data)),
    }),
    updateProfile: builder.mutation({
      query: (payload) => {
        return ({
          url: 'profile',
          method: 'PUT',
          body: payload.data,
        });
      },
    }),
    updateOrder: builder.mutation({
      query: (payload) => {
        return ({
          url: `orders/${payload.id}`,
          method: 'PUT',
          body: payload.data,
        });
      },
      transformResponse: (response: any) => ({
        ...response.data.attributes,
        id: response.data.id,
      }),
    }),
    deleteOrder: builder.mutation({
      query: ({
        id,
      }) => ({
        url: `orders/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (response: any) => ({
        ...response.data.attributes,
        id: response.data.id,
      }),
    }),
    createOrder: builder.mutation({
      query: (payload) => {
        return ({
          url: 'orders',
          method: 'POST',
          body: {
            data: payload,
          },
        });
      },
      async onQueryStarted(_id, { dispatch, queryFulfilled, getState }) {
        const state = getState() as RootState;
        const { messages } = state.locale;
        const { data } = await queryFulfilled;
        if (data) {
          // `onSuccess` side-effect
          dispatch(emitFlashMessage({
            message: messages['manage-jobs.order-created'],
            type: MESSAGE_TYPES.SUCCESS,
          }));
        }
      },
      transformResponse: (response: any) => ({
        ...response.data.attributes,
        id: response.data.id,
      }),
    }),
  }),
});

export const {
  useGetVehicleModelsQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
  useGetVehicleModelWithSupportedBodyPartsQuery,
  useGetOrdersQuery,
  useLazyGetOrdersQuery,
  useUpdateProfileMutation,
  useGetScenesQuery,
  usePostVehicleConfigMutation,
  useGetVehicleConfigQuery,
  useGetAllModsQuery,
  useGetVehicleYearsQuery,
  useGetVehicleMakesByYearQuery,
  useGetModelsByYearAndMakeQuery,
  useGetVehicleModelQuery,
  useGetVehicleModelBySlugQuery,
} = strapiApi;
