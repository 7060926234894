import React from 'react';
import { UserRole, USER_ROLES } from 'lib/types';
import { translate } from 'lib/utils/intl-utils';
import { Button, SvgExternalLink } from 'mod-styleguide';
import { INFO_FIELD_TYPES } from 'mod-styleguide/src/components/info-fields/info-fields';

export const CUSTOMER_FIELD_DEFS = [
  {
    name: 'firstName',
    autoComplete: 'given-name',
    label: translate('common.first-name'),
    placeholder: translate('form.first-name-placeholder'),
    editable: true,
  },
  {
    name: 'lastName',
    autoComplete: 'family-name',
    label: translate('common.last-name'),
    placeholder: translate('form.last-name-placeholder'),
    editable: true,
  },
  {
    name: 'phone',
    autoComplete: 'tel',
    label: translate('common.phone-number'),
    placeholder: translate('form.phone-number-placeholder'),
    editable: true,
  },
  {
    name: 'email',
    autoComplete: 'email',
    label: translate('common.email-address'),
    placeholder: translate('form.email-placeholder'),
  },
  {
    name: 'address',
    autoComplete: 'address-line1',
    label: translate('common.address'),
    placeholder: translate('form.address-placeholder'),
    editable: true,
    type: INFO_FIELD_TYPES.ADDRESS,
  },
];


export const DEALER_FIELD_DEFS = [
  {
    name: 'name',
    autoComplete: 'name',
    label: translate('profile.dealership-name'),
    editable: true,
  },
  {
    name: 'address',
    autoComplete: 'address-line1',
    label: translate('common.address'),
    editable: true,
    type: INFO_FIELD_TYPES.ADDRESS,
  },
  {
    name: 'firstName',
    autoComplete: 'given-name',
    label: translate('profile.contact-first-name'),
    editable: true,
  },
  {
    name: 'lastName',
    autoComplete: 'family-name',
    label: translate('profile.contact-last-name'),
    editable: true,
  },
  {
    name: 'phone',
    autoComplete: 'tel',
    label: translate('profile.contact-phone-number'),
    editable: true,
  },
  {
    name: 'email',
    autoComplete: 'email',
    label: translate('profile.contact-email-address'),
  },
];


export const INSTALLER_FIELD_DEFS = [
  {
    name: 'name',
    autoComplete: 'name',
    label: translate('profile.installer-name'),
    editable: true,
  },
  {
    name: 'address',
    autoComplete: 'address-line1',
    label: translate('common.address'),
    editable: true,
    type: INFO_FIELD_TYPES.ADDRESS,
  },
  {
    name: 'firstName',
    autoComplete: 'given-name',
    label: translate('profile.contact-first-name'),
    editable: true,
  },
  {
    name: 'lastName',
    autoComplete: 'family-name',
    label: translate('profile.contact-last-name'),
    editable: true,
  },
  {
    name: 'phone',
    autoComplete: 'tel',
    label: translate('profile.contact-phone-number'),
    editable: true,
  },
  {
    name: 'email',
    autoComplete: 'email',
    label: translate('profile.contact-email-address'),
  },
];

export const getFieldDefs = (userRole: UserRole) => {
  switch (userRole) {
    case USER_ROLES.CUSTOMER:
      return CUSTOMER_FIELD_DEFS;
    case USER_ROLES.DEALER:
      return DEALER_FIELD_DEFS;
    default:
      return INSTALLER_FIELD_DEFS;
  }
};

export const getSecondColumnFieldDefs = (userRole: UserRole) => {
  switch (userRole) {
    case USER_ROLES.DEALER:
      return [
        {
          name: 'dealerCode',
          label: translate('profile.dealer-discount-code'),
          type: 'code',
          disableInfoMode: true,
          editable: true,
          enableCopyButton: true,
          className: 'dealer-code-input',
        },
        {
          element: (
            <div style={{ width: '100%' }}>
              <div className='alternative-divider'>
                <span>{translate('common.or')}</span>
                <hr />
              </div>
              <Button
                className='email-customer-with-code-button'
                transparent
                label={translate('profile.email-customer-with-dealer-discount-code')}
                customIcon={<SvgExternalLink />}
              />
            </div>
          ),
        },
      ];
    default:
      return [];
  }
};
