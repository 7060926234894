const CAROUSEL_ITEMS_PER_SLIDE = 10;
const TABLET_ITEMS_PER_SLIDE = 8;
const MOBILE_CAROUSEL_ITEMS_PER_SLIDE = 6;

export const getItemsPerSlide = (isTablet, isMobile) => {
  if (isMobile) {
    return MOBILE_CAROUSEL_ITEMS_PER_SLIDE;
  }
  if (isTablet) {
    return TABLET_ITEMS_PER_SLIDE;
  }
  return CAROUSEL_ITEMS_PER_SLIDE;

};
