import React from 'react';
import type { SelectInputOptionType } from 'mod-styleguide';
import { Visible } from '@codeparticle/react-visible';
import './select-menu.scss';
import classNames from 'classnames';
import { MenuOption } from './menu-option';
import { MenuOptionProps } from './menu-option/menu-option';

interface SelectMenuProps {
  options?: Array<SelectInputOptionType>;
  onChange?: (selectedOption: SelectInputOptionType | SelectInputOptionType[]) => void;
  selectedOptions?: Array<SelectInputOptionType>;
  header?: JSX.Element;
  isMulti?: boolean;
  selectAllLabel?: string;
}

export const SelectMenu: React.FC<SelectMenuProps> = ({
  header,
  onChange,
  options = [],
  isMulti,
  selectedOptions,
  selectAllLabel,
}) => {

  const getMenuOptionProps = (option: SelectInputOptionType): MenuOptionProps => {
    const hasSubOptions = option.options?.length > 0;
    const subOptions = option.options?.map(subOption => (
      <MenuOption
        key={subOption.label}
        {...getMenuOptionProps(subOption)}
        onChange={() => onChange([option, subOption])}
      />
    ));

    return ({
      className: classNames(
        hasSubOptions
          ? 'select-menu-rct-component__content__grouped-options-container'
          : 'select-menu-rct-component__content__option-container',
      ),
      option,
      onChange,
      selected: isMulti
        ? selectedOptions?.some((selectedOption) => selectedOption?.value === option.value)
        : false,
      subOptions,
    });
  };

  return (
    <div
      className="select-menu-rct-component"
    >
      <Visible when={header}>
        <div className="select-menu-rct-component__header">
          {header}
        </div>
      </Visible>
      <Visible when={!options?.length}>
        <div className="select-menu-rct-component__no-options">
          No options
        </div>
      </Visible>
      <div className="select-menu-rct-component__content">
        <Visible when={selectAllLabel}>
          <MenuOption
            className='select-menu-rct-component__content__option-container'
            option={{ id: 'all', label: selectAllLabel, value: 'all' }}
            onChange={() => onChange(options)}
            selected={selectedOptions?.length === options?.length}
          />
        </Visible>
        {options?.map((option) => (
          !option.hidden
          && <MenuOption
            key={option.label}
            {...getMenuOptionProps(option)}
          />
        ))}
      </div>
    </div>
  );
};

