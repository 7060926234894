import classNames from 'classnames';
import React, { useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { Tab } from 'mod-styleguide';

interface Props {
  tabs: Tab[],
  defaultTab: Tab,
  basePath?: string,
  hideTabsWhenNoMatch?: boolean,
}
export const Tabs:React.FC<Props> = ({
  tabs,
  defaultTab,
  basePath = '',
  hideTabsWhenNoMatch,
}) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(defaultTab);
  const isActiveTab = tab => tab.id === activeTab.id;
  const activeTabPathMatch = useMatch(basePath + activeTab.path);
  const defaultTabPathMatch = useMatch(basePath);

  if (hideTabsWhenNoMatch && !activeTabPathMatch && !defaultTabPathMatch) {
    return null;
  }

  const onTabClick = (tab) => {
    setActiveTab(tab);
    const navigationPath = basePath ? `${basePath}${tab.path}` : tab.path;
    navigate(navigationPath);
  };

  const renderTabs = tabs.map((tab) => {
    return (
      <div
        className={classNames('tab', { 'tab__active': isActiveTab(tab) })}
        key={`${tab.id}-tab`}
        role="presentation"
        onClick={() => onTabClick(tab)}
      >
        {tab.label}
      </div>
    );
  });

  return (
    <div className="tabs-container">
      {renderTabs}
    </div>
  );
};
