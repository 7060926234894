
import { createSlice } from '@reduxjs/toolkit';
import { FORM_FIELD_INPUT_TYPES } from 'mod-styleguide';
import { signUp } from 'redux/thunks';

const INITIAL_STATE = {
  address: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  password: '',
  passwordConfirmation: '',
  touched: false,
  loading: false,
  error: '',
  validation: null,
  zipCode: '',
  latitude: '',
  longitude: '',
};

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState: INITIAL_STATE,
  reducers: {
    onSignUpFormChange: (state, action) => {
      state.touched = true;
      const fieldName = action.payload.field;

      if (fieldName === FORM_FIELD_INPUT_TYPES.ADDRESS) {
        // these are only set when selecting an address from the dropdown
        state.latitude = null;
        state.longitude = null;
        state.zipCode = null;
      }

      state[action.payload.field] = action.payload.value;
    },
    onAddressSelected: (state, action) => {
      state.address = action.payload.address;
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
      state.zipCode = action.payload.zipCode;
    },
    setSignUpFormValidation: (state, action) => {
      state.validation = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(signUp.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(signUp.fulfilled, () => {
      return INITIAL_STATE;
    });
    builder.addCase(signUp.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as any;
    });
  },
});

export const {
  onSignUpFormChange,
  setSignUpFormValidation,
  onAddressSelected,
} = signUpSlice.actions;
export default signUpSlice.reducer;
