import { VehicleModel } from 'lib/types';
import { ASSET_TYPES, mountCfAssetPath } from 'lib/utils';
import type { SelectInputOptionType } from 'mod-styleguide';
import type { BaseStrapiArrayResponse } from 'redux/modules/api/utils';

const SUPPORTED_BODY_PARTS = [
  // 'body-part-hood',
  // 'body-part-tailgate',
];

export const mapVehicleModel = (vehicleModel, useUnsupportedBodyParts = false): VehicleModel => {
  const mappedTrims = vehicleModel.options?.trims?.map(trim => ({
    label: trim.name,
    value: trim.name,
    id: trim.slug,
  }));

  const mappedColors = vehicleModel.options?.colors?.map(color => ({
    label: color.name,
    value: color.name,
    id: color.slug,
  }));

  const mappedBodyParts = vehicleModel.bodyParts?.data?.map(bodyPart => ({
    label: bodyPart.attributes.name,
    value: bodyPart.attributes.name,
    id: bodyPart.attributes.slug,
    hidden: !useUnsupportedBodyParts && !SUPPORTED_BODY_PARTS.includes(bodyPart.attributes.slug),
  }));

  const defaultTrim = mappedTrims?.[0];
  const defaultColor = mappedColors?.[0];

  return {
    ...vehicleModel,
    vehicle3dModelUrl: mountCfAssetPath(`${vehicleModel.slug}.glb`, ASSET_TYPES.VEHICLE_3D_MODEL),
    selectedOptions: {
      year: {
        id: vehicleModel.year,
        label: vehicleModel.year,
        value: vehicleModel.year,
      },
      make: {
        id: vehicleModel.make,
        label: vehicleModel.make,
        value: vehicleModel.make,
      },
      model: {
        id: vehicleModel.slug,
        label: vehicleModel.model,
        value: vehicleModel.model,
      },
      trim: {
        id: defaultTrim.id,
        label: defaultTrim.label,
        value: defaultTrim.value,
      },
      color: {
        id: defaultColor.id,
        label: defaultColor.label,
        value: defaultColor.value,
      },
    },
    options: {
      trims: mappedTrims,
      colors: mappedColors,
    },
    bodyParts: mappedBodyParts,
    recommendedMods: vehicleModel.recommendedMods?.data,
  };
};

export const mapVehicleModelsOptions = (response: BaseStrapiArrayResponse): SelectInputOptionType[] => {
  const options = response.data.map(({ attributes }) => {
    const { slug, model } = attributes;
    return {
      id: slug,
      value: model,
      label: model,
    };
  });
  return options;
};
