
import { createSlice } from '@reduxjs/toolkit';
import { login } from 'redux/thunks/auth';

const INITIAL_STATE = {
  email: '',
  password: '',
  touched: false,
  loading: false,
  error: '',
  validation: null,
};
export const loginSlice = createSlice({
  name: 'login',
  initialState: INITIAL_STATE,
  reducers: {
    onLoginFormChange: (state, action) => {
      state.touched = true;
      state[action.payload.field] = action.payload.value;
    },
    setLoginFormValidation: (state, action) => {
      state.validation = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(login.fulfilled, () => {
      return INITIAL_STATE;
    });

    builder.addCase(login.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as any;
    });
  },
});

export const { onLoginFormChange, setLoginFormValidation } = loginSlice.actions;
export default loginSlice.reducer;
