import * as BABYLON from '@babylonjs/core';

export const getActiveShadowGenerators = (scene: BABYLON.Scene) => {
  const shadowGenerators = [];
  scene.lights.forEach(light => {
    const lightShadowGenerator = light.getShadowGenerator();

    if (lightShadowGenerator) {
      shadowGenerators.push(lightShadowGenerator as BABYLON.ShadowGenerator);
    }
  });
  return shadowGenerators;
};
