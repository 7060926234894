import { useDebounceFn } from 'ahooks';
import { getBodyPartFromActiveWrapId, mapModConfigsToWrapSidebarSubItems, mapVehicleModsToPickerData } from 'lib/data-mappers';
import { useAppSelector } from 'lib/types/redux';
import { debounce } from 'lodash';
import { SelectedWrapData, SelectInputOptionType, SidebarSubItem, WrapPickerData } from 'mod-styleguide';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetAllModsQuery } from 'redux/modules/api';
import { applyWrapMod, carConfiguratorSelector, removeWrapMod, selectActiveModConfigs } from 'redux/modules/carConfigurator/carConfiguratorSlice';

const sendSelectConfigEvent = debounce((activeVehicleConfigId) => window.gtag('event', 'select_config', {
  current_config_id: activeVehicleConfigId,
}), 2000);

const useWrapPickerData = (recommendedMods, useRecommendedMods = false): WrapPickerData => {
  const [wrapPickerData, setWrapPickerData] = useState<WrapPickerData>({
    materialsGroupedOptions: [],
    textures: {},
  });
  const result = useGetAllModsQuery(null, {
    skip: useRecommendedMods,
  });

  useEffect(() => {
    if (recommendedMods?.length && useRecommendedMods) {
      setWrapPickerData(mapVehicleModsToPickerData(recommendedMods));
    } else if (result.data) {
      setWrapPickerData(mapVehicleModsToPickerData(result.currentData.data));
    }
  }, [recommendedMods, result, useRecommendedMods]);

  return wrapPickerData;
};

export const useWrapPicker = (
  singleWrap = false,
  useRecommendedMods = false,
): {
    wrapPickerData: WrapPickerData;
    activeWraps: SidebarSubItem[];
    onApplyWrap: ({ texture, selectedBodyParts }: {
      texture: SelectInputOptionType;
      selectedBodyParts: SelectInputOptionType[];
    }) => void;
    onClearWraps: () => void;
    onRemoveWrap: (wrap: SidebarSubItem) => void;
    wrapPickerDefaultStates: Partial<WrapPickerData>;
    setWrapPickerDefaultStates: (newDefaultStates: Partial<WrapPickerData>) => void;
  } => {
  const { vehicleModelData, activeVehicleConfigId } = useAppSelector(carConfiguratorSelector);

  const modConfigs = useAppSelector(selectActiveModConfigs);
  const wrapPickerData = useWrapPickerData(vehicleModelData?.recommendedMods, useRecommendedMods);
  const dispatch = useDispatch();
  const [activeWraps, setActiveWraps] = useState<SidebarSubItem[]>([]);
  // This is needed to ensure we keep the same selection state
  // when wrap picker umounts/mounts on different places according to current resolution
  const [wrapPickerDefaultStates, setWrapPickerDefaultStates] = useState<Partial<WrapPickerData>>({
    defaultBrand: wrapPickerData.defaultBrand,
    defaultMaterial: wrapPickerData.defaultMaterial,
    defaultTexture: wrapPickerData.defaultTexture,
  });

  useEffect(() => {
    if (vehicleModelData?.bodyParts && wrapPickerData.allMaterialsTextures) {
      setActiveWraps(mapModConfigsToWrapSidebarSubItems(
        modConfigs,
        vehicleModelData.bodyParts,
        wrapPickerData.allMaterialsTextures,
      ));
    }
  }, [modConfigs, vehicleModelData.bodyParts, wrapPickerData.allMaterialsTextures]);

  useEffect(() => {
    setWrapPickerDefaultStates({
      defaultBrand: wrapPickerData.defaultBrand,
      defaultMaterial: wrapPickerData.defaultMaterial,
      defaultTexture: wrapPickerData.defaultTexture,
    });
  }, [wrapPickerData]);

  const onRemoveWrap = useCallback(({ id }) => {
    dispatch(removeWrapMod({
      bodyPart: getBodyPartFromActiveWrapId(id),
    }));
  }, [dispatch]);

  const onClearWraps = useCallback(() => {
    activeWraps.forEach(onRemoveWrap);
  }, [activeWraps, onRemoveWrap]);

  useEffect(() => {
    if(!activeVehicleConfigId) return;
    sendSelectConfigEvent(activeVehicleConfigId);
  }, [activeVehicleConfigId])

  const {
    run: onApplyWrap,
  } = useDebounceFn((newWrapData: SelectedWrapData) => {
    dispatch(applyWrapMod({
      wrapData: newWrapData,
      singleWrap,
    }));
  }, {
    wait: 250,
  });

  return {
    wrapPickerData,
    activeWraps,
    onApplyWrap,
    onRemoveWrap,
    onClearWraps,
    wrapPickerDefaultStates,
    setWrapPickerDefaultStates,
  };
};
