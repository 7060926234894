import React from 'react';
import { components, PlaceholderProps } from 'react-select';


export const CustomPlaceholder: React.FC<PlaceholderProps> = (props) => {
  const { labelPrefix, placeholder } = props.selectProps as any || {};

  return (
    <components.Placeholder className='placeholder-container' {...props}>
      {labelPrefix}
      <span className='placeholder-label'>
        {placeholder}
      </span>
    </components.Placeholder>
  );
};
