import React from 'react';
import {
  FormField,
  BaseFormState,
  FormFieldDef,
} from 'mod-styleguide';
import { get } from 'lodash';

export const FormFieldFromDef = ({
  formState,
  fieldDef,
  onInputChange,
}: {
  formState: BaseFormState;
  fieldDef: FormFieldDef;
  onInputChange?: ({ field, value, rawValue }: { field: string, value: string, rawValue: any }) => void;
}) => {
  const {
    label,
    name,
    placeholder,
    autoComplete: fieldAutoComplete,
    width,
    type,
    valueKeyPath,
    ...inputProps
  } = fieldDef;
  const value = get(formState, valueKeyPath) || get(formState, name) || '';

  const onChange = (e) => {
    onInputChange?.({
      field: name,
      value: e.value || e.target?.value,
      rawValue: e,
    });
  };

  return (
    <FormField
      autoComplete={fieldAutoComplete}
      label={label}
      name={name}
      error={formState.validation?.errors?.[name]}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      type={type ?? name}
      width={width}
      {...inputProps}
    />
  );
};
