import { createSlice } from '@reduxjs/toolkit';
import { login, signUp } from 'redux/thunks/auth';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    id: null,
    validation: null,
    touched: true,
    latitude: null,
    longitude: null,
  },
  reducers: {
    hydrateProfileForm: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    onProfileFormChange: (state, action) => {
      state.touched = true;
      state[action.payload.field] = action.payload.value;
    },
    setProfileFormValidation: (state, action) => {
      state.validation = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      return {
        ...state,
        email: payload.user.email,
        ...payload.profile,
      };
    });
    builder.addCase(signUp.fulfilled, (state, { payload }) => {
      return {
        ...state,
        email: payload.user.email,
        ...payload.profile };
    });
  },
});

export const { onProfileFormChange, setProfileFormValidation, hydrateProfileForm } = profileSlice.actions;
export default profileSlice.reducer;
