import { StylesConfig } from 'react-select';

export const customStyles: StylesConfig = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: () => ({
    background: 'transparent',
    display: 'flex',
    height: 'fit-content',
    width: '100%',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    padding: '8px 6px',
    display: 'flex',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
  menu: (provided) => ({
    ...provided,
    background: '#393939',
    borderTop: '1px solid #535353',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
    color: '#C6C6C6',
    minWidth: '160px',
    borderRadius: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0px',
    paddingRight: '8px',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  }),
};

