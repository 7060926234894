import React from 'react';
import './header.scss';
import { SvgLogoSmall } from 'mod-styleguide';
import { Visible } from '@codeparticle/react-visible';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

interface HeaderProps {
  // For instance, select inputs for the car configurator
  customContent?: JSX.Element | JSX.Element[],
  transparent?: boolean,
  hideLogo?: boolean,
  navItemsDefs?: {
    label: string,
    route?: string,
    icon?: JSX.Element,
    onClick?: () => void,
  }[],
}

export const Header: React.FC<HeaderProps> = ({
  customContent = null,
  transparent,
  hideLogo,
  navItemsDefs,
}: HeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <header className={
      classNames(
        'header-nav-rct-component',
        transparent && 'header-nav-rct-component--transparent',
      )
    }
    >
      <Visible when={!hideLogo}>
        <div className='logo'>
          <SvgLogoSmall color="white" />
        </div>
      </Visible>
      <Visible when={navItemsDefs}>
        <div className='header-nav-rct-component__navigation'>
          {
              navItemsDefs?.map((navItem) => (
                <span
                  className={location.pathname === navItem.route ? 'header-nav-rct-component__navigation__active' : ''}
                  key={navItem.label}
                  onClick={() => {
                    if (navItem.onClick) {
                      navItem.onClick();
                    }
                    navigate(navItem.route);
                  }}
                >
                  {navItem.label}
                </span>
              ))
            }
        </div>
      </Visible>
      {customContent}
    </header>
  );
};

